import React from 'react';
import styles from './discounts.module.scss';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import ReportFilterPanel from '../components/dynamic-filters';
import DiscountReportBody from './report-body';
import DiscountMetricsHeader from './discount-metrics-header';

function Discounts() {
  const downloadPdf = () => {};
  const downloadCsv = () => {};

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Discounts'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody)}>
        <ReportFilterPanel />
      </div>
      <DiscountMetricsHeader />
      <div className="row">
        <DiscountReportBody />
      </div>
    </div>
  );
}

export default Discounts;
