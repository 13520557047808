import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getOptionSets = (token) => {
  return handleApiResponse(() =>
    api.get('option_sets', {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getOptionSet = (id, token) =>
  handleApiResponse(() =>
    api.get(`option_sets/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addOptionSet = (newOptionDetails, token) =>
  handleApiResponse(() =>
    api.post('option_sets', newOptionDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateOptionSet = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`option_sets/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteOptionSet = (id, token) =>
  handleApiResponse(() =>
    api.delete(`option_sets/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
