import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLocations, updateCustomerDisplaySettings } from '../../../../redux/locationSlice';
import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../utilities/page-title/page-title';
import Checkbox from '../../../utilities/checkbox/checkbox';
import Button from '../../../utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';
import { isEqual, isObjectEmpty } from '../../../utilities/utils';

import styles from './customer-display-settings.module.scss';

function CustomerDisplaySettings() {
  // Global State
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const locations = useSelector((state) => state.locations.locations);
  const locationsStatus = useSelector((state) => state.locations.status);

  const isLoading = locationsStatus === 'loading';

  // Local State
  const [currentLocation, setCurrentLocation] = useState({});
  const [initialSettings, setInitialSettings] = useState({});
  const [settings, setSettings] = useState({});

  const isSettingsStateChanged = useMemo(() => !isEqual(initialSettings, settings), [initialSettings, settings]);

  const getSettingsFromLocation = (location) => ({
    isSignatureRequired: location.customer_sign_required_on_cd || false,
    showCashBifurcation: location.show_cash_credit_price_on_cd || false,
    showTipScreenBeforePayment: location.show_tip_screen_before_payment || false,
  });

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations) {
      const location = locations.find((location) => location.id === parseInt(locationId));
      if (location && !isEqual(location, currentLocation)) {
        setCurrentLocation(location);
      }
    }
  }, [locations, locationId, currentLocation]);

  useEffect(() => {
    if (!isObjectEmpty(currentLocation)) {
      const newSettings = getSettingsFromLocation(currentLocation);
      setSettings(newSettings);
      setInitialSettings(newSettings);
    }
  }, [currentLocation]);

  // Handlers
  const handleCheckboxChange = useCallback((name, value) => {
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSave = () => {
    if (!isSettingsStateChanged) return;

    const payload = {
      isCustomerSignatureRequired: settings.isSignatureRequired,
      isShowCashBifurcation: settings.showCashBifurcation,
      isShowTipScreenBeforePayment: settings.showTipScreenBeforePayment,
      token,
      locationId,
    };

    dispatch(updateCustomerDisplaySettings(payload));
  };

  // Setting checkbox component
  const SettingCheckbox = React.memo(({ settingName, title }) => (
    <div className={styles.menu_item}>
      <Checkbox
        item={{ id: settingName, name: title }}
        handleOnChange={handleCheckboxChange}
        isChecked={settings[settingName]}
      />
      <span className={styles.menu_item_name} onClick={() => handleCheckboxChange(settingName, !settings[settingName])}>
        {title}
      </span>
    </div>
  ));

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Customer Display Settings'} />
      </div>
      <div className={styles.main_content}>
        {isLoading && <BackdropLoading />}
        <SettingCheckbox settingName="isSignatureRequired" title="Customer signature required on Customer Display?" />
        <SettingCheckbox settingName="showCashBifurcation" title="Show cash/credit bifurcation on Customer Display?" />
        <SettingCheckbox settingName="showTipScreenBeforePayment" title="Show tip screen before payment?" />
        <Button className={styles.save_button} title={'Save'} onClick={handleSave} disabled={!isSettingsStateChanged} />
      </div>
    </div>
  );
}

export default CustomerDisplaySettings;
