import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import DateRangeFilter from '../components/date-range/date-range';
import NoRecords from '../components/NoRecords/no-records';
import { ReportFilterPanel, DropdownTypes } from '../components/report-filter-panel';
import OrderItem from './OrderItem';

import styles from './dine-in-orders.module.scss';

function DineInOrders() {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { dineInOrders } = {
    dineInOrders: [
      {
        id: 1,
        created_at: '2021-08-01T10:00:00Z',
        offline_id: '123456',
        dine_in_order_detail: {
          total_guest_count: 2,
          table_name: 'Table 1',
        },
        employee: { full_name: 'John Doe' },
        terminal: { name: 'Terminal 1' },
        total_discount: 0,
        payments: [
          {
            id: 1,
            display_payment_type: 'Cash',
            amount: 100,
            tips: 0,
            Card: null,
            refunds: [],
          },
        ],
        total_tax_amount: 0,
        total_tips: 0,
        total_service_charges: 0,
      },
    ],
    timezone: 'America/New_York',
    currentLocation: { id: 1 },
  };

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const handleOrderDetails = (order) => {
    navigate(`/order_details/${order.id}`);
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Dine In Orders'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel dropdown={[DropdownTypes.employee, DropdownTypes.payment_type, DropdownTypes.terminal_id]} />
      </div>
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
      </div>
      <div className="row">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Order #</th>
              <th># of Guests</th>
              <th>Employee</th>
              <th>Table</th>
              <th>Terminal</th>
              <th>Discount Amount</th>
              <th>Amount</th>
              <th>Tax</th>
              <th>Tip</th>
              <th>Service Charge</th>
              <th>Details</th>
            </tr>
          </thead>
          {dineInOrders && dineInOrders.length > 0 ? (
            dineInOrders.map((order) => <OrderItem order={order} openOrderDetails={() => handleOrderDetails(order)} />)
          ) : (
            <NoRecords />
          )}
        </table>
      </div>
    </div>
  );
}

export default DineInOrders;
