import React, { useState, useEffect, useCallback } from 'react';
import { RxCross2 } from 'react-icons/rx';

import Modal from '../../../../../components/utilities/modal/modal';
import Checkbox from '../../../../../components/utilities/checkbox/checkbox';

import styles from './add-taxes-modal.module.scss';

function AddTaxesModal({ modal, setModalVisibility, items, selectedTaxesIds, setSelectedTaxesIds, onUpdate }) {
  const handleAssignModalCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedTaxesIds((prevSelected) => {
        if (!prevSelected.includes(id)) {
          return [...prevSelected, id];
        }
        return prevSelected;
      });
    } else {
      setSelectedTaxesIds((prevSelected) => {
        return prevSelected.filter((taxId) => taxId !== id);
      });
    }
  };

  return (
    <Modal isOpen={modal} onClose={() => setModalVisibility(false)} className={styles.assign_items_modal}>
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Taxes</h3>
          <div className={styles.cross_icon} onClick={() => setModalVisibility(false)}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.menu_items}>
            <div className={styles.menu_list_of_items}>
              {items.map((item) => (
                <div key={item.id} className={styles.menu_item}>
                  <Checkbox
                    item={item}
                    handleOnChange={handleAssignModalCheckboxChange}
                    isChecked={selectedTaxesIds && selectedTaxesIds.includes(item.id)}
                  />
                  <div className={styles.menu_item_value_wrapper}>
                    <span className={styles.menu_item_name}>{item.name}</span>
                    <span className={styles.menu_item_rate}>{item.rate}%</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <button type="button" className={styles.update_button} onClick={onUpdate}>
            Save
          </button>
          <button type="button" className={styles.cancel_button} onClick={() => setModalVisibility(false)}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddTaxesModal;
