import React from 'react';
import styles from './invoices.module.scss';

function Invoices() {
  return (
    <>
      <div className={styles.dashboard} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
        Invoices Content
      </div>
      <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quod, suscipit. Aliquid aliquam rerum omnis optio
        dolor nemo itaque ab modi quidem nam. A aperiam quisquam, nesciunt molestiae incidunt velit, dolor excepturi
        illo minus, quia ipsum quidem. At, perferendis ea, dolores minima quo repellat repellendus voluptas architecto
        qui dignissimos amet? Suscipit voluptas voluptatum odit eveniet sapiente, esse voluptatem vero sit accusamus
        aliquam nesciunt illo repellat non officia asperiores eos aliquid quibusdam praesentium adipisci fugiat
        temporibus beatae. Perferendis consequatur labore sunt, voluptate esse nihil cumque facere dolore sequi aliquam
        quos inventore aspernatur dignissimos id ratione minima repellat eum ullam, in placeat doloribus maiores minus
        libero! Tempora doloribus in quae. Iure, ullam. Eum iusto repellendus dolore doloremque ipsum adipisci molestiae
        odit quam saepe? Repellat, explicabo incidunt nesciunt assumenda nulla voluptatum nobis. Possimus a accusamus,
        voluptatem aliquam dolorem nihil, unde tenetur esse totam illo, necessitatibus cupiditate voluptate? Facilis
        possimus repudiandae nihil cum incidunt voluptas voluptate magnam ipsum blanditiis odio vitae dolorem veritatis
        eveniet corporis in, maiores minus delectus aliquam eum temporibus quibusdam est quam nisi consectetur.
        Voluptates debitis distinctio nam. Dolor odit soluta cum voluptatum esse, quae accusamus veniam dolorem
        blanditiis tempore rem laudantium et totam repellat nihil porro minus architecto alias doloribus deserunt! Sed
        sunt dicta unde quo facere, vel commodi in quisquam repellat totam veritatis accusantium libero ratione at
        distinctio deleniti corporis delectus omnis pariatur dolor eos. Eos, iste fuga dolorum expedita tempore iure
        aliquam molestiae debitis, illo repudiandae dolorem aliquid assumenda earum saepe animi laudantium corporis
        tempora. Quae dolorem sunt, sapiente deleniti quas pariatur accusamus reiciendis molestiae atque incidunt vitae
        accusantium iste laudantium facilis ipsam deserunt quidem omnis perspiciatis eveniet. Itaque quos accusamus
        alias quis quasi vero consequuntur provident totam, aut reiciendis quisquam similique sit nostrum aliquid odit
        praesentium ea facilis asperiores reprehenderit. Deserunt, assumenda ullam enim blanditiis autem dicta cumque.
      </p>
    </>
  );
}

export default Invoices;
