import React from 'react';

import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import DynamicFilters from '../components/dynamic-filters';

import styles from './sales-overview.module.scss';
import ReportTable from './report-table';

function SalesOverview() {
  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const records = {
    gross_sale: 10000.0,
    discount: 500.0,
    refund: 200.0,
    wastage: 100.0,
    net_sales: 9200.0,
    total_tax: 800.0,
    tax_refunded: 50.0,
    total_tips: 300.0,
    tips_refund: 20.0,
    total_service_charge: 150.0,
    service_charge_refunded: 10.0,
    loyalty_rewards: 30.0,
    total_cash_discount: 40.0,
    total_surcharge: 60.0,
    convenience_fee: 25.0,
    delivery_fee: 15.0,
    cash_adjustment: 5.0,
    total_amount_collected: 10500.0,
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Sales Overview'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody)}>
        <DynamicFilters />
      </div>
      <div className={clsx('mb-4', styles.boxBody)}>
        <div className="col-12 card">
          <h3 className="fs-16 fw-400 mb-3">Sales Summary</h3>
          <div className="sales-summary">
            <ReportTable records={records} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesOverview;
