import React, { useState } from 'react';
import styles from './labor-summary.module.scss';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import { DropdownTypes, ReportFilterPanel } from '../components/report-filter-panel';
import { DateRangeFilter } from '../components/date-range';
import ReportTable from './ReportTable';

function LaborSummary() {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const fakeData = [
    [
      'Employee 1',
      {
        job_title: 'Developer',
        regular_hours: 40,
        overtime_hours: 5,
        regular_pay: 1000,
        overtime_pay: 200,
        total_pay: 1200,
        sales: 5000,
        orders: 50,
        guests: 100,
        tips: 150,
        gratuity: 50,
        declared_tips: 100,
        total_pay_per_sales: 0.24,
        total_sales_per_employee_hour: 125,
      },
    ],
    [
      'Employee 2',
      {
        job_title: 'Designer',
        regular_hours: 35,
        overtime_hours: 10,
        regular_pay: 900,
        overtime_pay: 300,
        total_pay: 1200,
        sales: 4000,
        orders: 40,
        guests: 80,
        tips: 100,
        gratuity: 30,
        declared_tips: 70,
        total_pay_per_sales: 0.3,
        total_sales_per_employee_hour: 114.29,
      },
    ],
  ];

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Labour Summary'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel searchShow={false} dropdown={[DropdownTypes.teamId, DropdownTypes.roleId]} />
      </div>
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter endDate={endDate} startDate={startDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      </div>
      <div className="bg-white table-responsive mt-4">
        <ReportTable laborSummary={fakeData} />
      </div>
    </div>
  );
}

export default LaborSummary;
