import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import 'chart.js';

import { useAppContext } from '../../../context/context';
import { fetchDashboardData } from '../../../redux/dashboardSlice';

import Button from '../../utilities/button/button';
import PageTitle from '../../utilities/page-title/page-title';
import DatePicker from '../../utilities/date-picker/date-picker';
import Metric from './statistics/metric';
import NetSalesBarChart from './graphs/net-sales/net-sales';
import GrossSalesBarChart from './graphs/gross-sales/gross-sales';
import HourlyRevenueLineChart from './graphs/hourly-new-revenue/hourly-new-revenue';
import PaymentMethodsChart from './graphs/payment-methods/payment-methods';
//import DiscountLineChart from './graphs/discounts/discounts'; TODO: There will be adding this graph after the MVP (Do not delete)
//import GuestsLineChart from './graphs/guests/guests'; TODO: There will be adding this graph after the MVP (Do not delete)
import ClockedInEmployeesWidget from './widgets/clocked-in-employees/clocked-in-employees';
import TopSellingItemsWidget from './widgets/top-selling-items/top-selling-items';

import qrCode from '../../../assets/img/qr_code.png';

import clsx from 'clsx';
import styles from './dashboard.module.scss';

const DATE_FORMAT = 'MM/dd/yyyy p';
const TIME_FORMAT = 'p';

const adjustForTimeZone = (date) => {
  const offsetInMinutes = date.getTimezoneOffset();
  return new Date(date.getTime() - offsetInMinutes * 60000);
};

function Dashboard() {
  // Hooks
  const dispatch = useDispatch();
  const { token } = useAppContext();

  // Store
  const dashboard_data = useSelector((state) => state.dashboard.dashboard_data);
  const status = useSelector((state) => state.dashboard.status);

  // Local state
  const today = new Date(); // Date
  const [fromDate, setFromDate] = useState(today); // Date
  const [fromDateISO, setFromDateISO] = useState(null); // Date
  const [toDate, setToDate] = useState(today); // Date
  const [toDateISO, setToDateISO] = useState(null); // Date
  const [metrics, setMetrics] = useState([]); // Metrics
  const [netSalesGraphData, setNetSalesGraphData] = useState({}); // Graphs
  const [grossSalesGraphData, setGrossSalesGraphData] = useState({}); // Graphs
  const [hourlyNewRevenueGraphData, setHourlyNewRevenueGraphData] = useState({}); // Graphs
  const [paymentMethodsGraphData, setPaymentMethodsGraphData] = useState({}); // Graphs
  //const [discountsGraphData, setDiscountsGraphData] = useState({}); // Graphs TODO: There will be adding this graph after the MVP (Do not delete)
  //const [guestsGraphData, setGuestsGraphData] = useState({}); // Graphs TODO: There will be adding this graph after the MVP (Do not delete)
  const [clockedInEmployeesData, setClockedInEmployeesData] = useState({}); // Info block
  const [topSellingItemsData, setTopSellingItemsData] = useState({}); // Info block

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchDashboardData({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (
      dashboard_data &&
      dashboard_data.metrics &&
      dashboard_data.graphs &&
      dashboard_data.metrics.length > 0 &&
      dashboard_data.graphs.length > 0
    ) {
      setMetrics(dashboard_data.metrics);
      setNetSalesGraphData(dashboard_data.graphs[0]);
      setGrossSalesGraphData(dashboard_data.graphs[1]);
      setHourlyNewRevenueGraphData(dashboard_data.graphs[2].values);
      setPaymentMethodsGraphData(dashboard_data.graphs[3].values);
      //setDiscountsGraphData(dashboard_data.graphs[4]); TODO: There will be adding this graph after the MVP (Do not delete)
      //setGuestsGraphData(dashboard_data.graphs[5]); TODO: There will be adding this graph after the MVP (Do not delete)
      setClockedInEmployeesData(dashboard_data.graphs[6]);
      setTopSellingItemsData(dashboard_data.graphs[7]);
    }
  }, [dashboard_data]);

  useEffect(() => {
    if (!fromDate || !toDate) return;

    const adjustedClockIn = adjustForTimeZone(fromDate);
    const adjustedClockOut = adjustForTimeZone(toDate);

    setFromDateISO(adjustedClockIn.toISOString());
    setToDateISO(adjustedClockOut.toISOString());
  }, [fromDate, toDate]);

  // Handlers
  const handleSelectFromDate = (date) => {
    setFromDate(new Date(date));
  };

  const handleSelectToDate = (date) => {
    setToDate(new Date(date));
  };

  const handleAddNewItem = () => {
    dispatch(fetchDashboardData({ start_date: fromDateISO, end_date: toDateISO, token }));
  };

  return (
    <div className={styles.dashboard}>
      <PageTitle name={'Dashboard'} />
      <div className={styles.filters}>
        <DatePicker
          selected={fromDate}
          onChange={(date) => handleSelectFromDate(date)}
          showTimeSelect
          dateFormat={DATE_FORMAT}
          timeFormat={TIME_FORMAT}
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => handleSelectToDate(date)}
          showTimeSelect
          dateFormat={DATE_FORMAT}
          timeFormat={TIME_FORMAT}
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <Button className={styles.search_button} title={'Search'} onClick={handleAddNewItem} />
      </div>

      <div className={styles.metrics}>
        <div className={styles.row}>
          <div className={styles.graph_item}>
            {netSalesGraphData.values && (
              <NetSalesBarChart
                data={netSalesGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )}
          </div>
          <div className={styles.graph_item}>
            {grossSalesGraphData.values && (
              <GrossSalesBarChart
                data={grossSalesGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )}
          </div>
          <div className={styles.statistics}>
            <PageTitle name={'Today`s Statistics'} className={styles.subtitle} />
            <div className={styles.metrics}>
              {metrics && metrics.map((metric) => <Metric item={metric} key={metric.id} />)}
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.graph_item_full}>
            <PageTitle name={'Hourly New Revenue'} className={styles.subtitle} />
            <div className={styles.graph_content} style={{ marginBottom: '20px' }}>
              <HourlyRevenueLineChart data={hourlyNewRevenueGraphData} isLoading={status === 'loading'} />
            </div>
          </div>
        </div>

        <div className={clsx(styles.row, styles.row_align_left)}>
          <div className={styles.graph_item}>
            <PageTitle name={'Payment Methods'} className={styles.subtitle} />
            <div className={styles.graph_content}>
              <PaymentMethodsChart data={paymentMethodsGraphData} isLoading={status === 'loading'} />
            </div>
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            {clockedInEmployeesData.employees && (
              <ClockedInEmployeesWidget
                data={clockedInEmployeesData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )}
            {/* TODO: There will be adding this graph after the MVP (Do not delete) */}
            {/*             {discountsGraphData.values && (
              <DiscountLineChart
                data={discountsGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )} */}
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            {topSellingItemsData.items && (
              <TopSellingItemsWidget data={topSellingItemsData} isLoading={status === 'loading'} />
            )}
            {/* TODO: There will be adding this graph after the MVP (Do not delete) */}
            {/*             {guestsGraphData.values && (
              <GuestsLineChart
                data={guestsGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )} */}
          </div>
        </div>

        {/* TODO: There will be adding this graph after the MVP (Do not delete) */}
        {/*         <div className={clsx(styles.row, styles.row_align_left)}>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            <div className={styles.graph_content}>
              {clockedInEmployeesData.employees && (
                <ClockedInEmployeesWidget
                  data={clockedInEmployeesData}
                  startDate={fromDateISO}
                  endDate={toDateISO}
                  isLoading={status === 'loading'}
                />
              )}
            </div>
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            <div className={styles.graph_content}>
              {topSellingItemsData.items && (
                <TopSellingItemsWidget
                  data={topSellingItemsData}
                  startDate={fromDateISO}
                  endDate={toDateISO}
                  isLoading={status === 'loading'}
                />
              )}
            </div>
          </div>
        </div> */}
      </div>

      {/* TODO: There will be adding this footer after the MVP (Do not delete) */}
      {/* <div className={styles.footer}>
        <div className={styles.links}>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Home Page</span>
            <Link to="/business/information" className={styles.link}>
              https://somewebsite.com
            </Link>
          </div>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Menu Page</span>
            <Link to="/menu/items" className={styles.link}>
              https://anotherwebsite.com
            </Link>
          </div>
        </div>
        <div className={styles.qr_code_wrapper}>
          <div className={styles.qr_code}>
            <img src={qrCode} alt="QR Code" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
