import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openModals: {},
};

/**
 * State example
 *
 * @example
 * {
 *    modalId: 'delete-order-modal',
 *    data: {
 *       type: 'confirmation',
 *       title: 'Delete Confirmation',
 *       message: 'Are you sure you want to delete this item?',
 *       actions: [
 *          { title: 'Delete', onAction: () => handleRemoveItem(id) },
 *          { title: 'Cancel', button_variant: 'secondary', onAction: handleCloseModal },
 *       ],
 *       isMandatory: true, // the modal doesn't close when clicked outside
 *    },
 * }
 */
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      const { modalId, data } = action.payload;
      state.openModals[modalId] = {
        isOpen: true,
        data,
      };
    },
    hideModal: (state, action) => {
      const modalId = action.payload;
      delete state.openModals[modalId];
    },
    hideAllModals: (state) => {
      state.openModals = {};
    },
  },
});

export const { showModal, hideModal, hideAllModals } = modalSlice.actions;
export default modalSlice.reducer;

export const selectModalState = (state, modalId) => state.modal.openModals[modalId];
export const selectIsModalOpen = (state, modalId) => !!state.modal.openModals[modalId]?.isOpen;
