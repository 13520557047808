import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../../../context/context';

import { addNewEmployee, removeEmployee, updateExistingEmployee } from '../../../../../redux/employeesSlice';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import useFormValidation from '../../../../utilities/useFormValidation';
import { registrationSchema } from '../validation';

const getEmployee = (employees, id) => {
  return employees.find((item) => item.id === parseFloat(id));
};

const useEmployee = (employees) => {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const initialState = {
    isEditModalOpen: false,
    isInfoModalOpen: false,
    isEditMode: false,
    currentEmployeeId: null,
    employee: {},
  };

  const [state, setState] = useState(initialState);
  const { errors, validate, resetErrors } = useFormValidation(registrationSchema);

  useEffect(() => {
    if (state.currentEmployeeId) {
      const foundEmployee = getEmployee(employees, state.currentEmployeeId);
      setState((prev) => ({ ...prev, employee: foundEmployee }));
    }
  }, [employees, state.currentEmployeeId]);

  const handleOpenEmployeeInfo = (id) => {
    setState({
      ...initialState,
      isInfoModalOpen: true,
      isEditModalOpen: false,
      employee: getEmployee(employees, id),
    });
  };

  const handleAddNewEmployee = () => {
    setState({
      ...initialState,
      isEditModalOpen: true,
      isInfoModalOpen: false,
      employee: {},
    });
  };

  const handleEditEmployee = (id) => {
    setState({
      isInfoModalOpen: false,
      isEditModalOpen: true,
      isEditMode: true,
      currentEmployeeId: id,
      employee: getEmployee(employees, id),
    });
  };

  const handleSaveChanges = async () => {
    if (validate(state.employee)) {
      try {
        await dispatch(
          state.isEditMode
            ? updateExistingEmployee({ id: state.currentEmployeeId, updatedDetails: state.employee, token })
            : addNewEmployee({ newEmployeeDetails: state.employee, token }),
        ).unwrap();
        handleModalClose();
      } catch (error) {
        console.error('Failed to save employee:', error);
      }
    }
  };

  const handleChangeStatus = useCallback(
    (id, value) => {
      const updatedDetails = {
        ...employees.find((employee) => employee.id === id),
        is_active: value,
      };
      dispatch(updateExistingEmployee({ id, updatedDetails, token }));
    },
    [dispatch, token, employees],
  );

  const handleDeleteEmployee = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-employee',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this employee?',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeEmployee({ id, token }));
                dispatch(hideModal('modal-delete-employee'));
              },
            },
            {
              title: 'Cancel',
              button_variant: 'secondary',
              onAction: () => dispatch(hideModal('modal-delete-employee')),
            },
          ],
        },
      }),
    );
  };

  const handleModalClose = () => {
    setState(initialState);
    resetErrors();
  };

  return {
    ...state,
    errors,
    setEmployee: setState,
    handleChangeStatus,
    handleAddNewEmployee,
    handleEditEmployee,
    handleSaveChanges,
    handleDeleteEmployee,
    handleOpenEmployeeInfo,
    handleModalClose,
  };
};

export default useEmployee;
