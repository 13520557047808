import React, { useRef } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';

import { nanoid } from 'nanoid';
import clsx from 'clsx';

import styles from './google-autocomplete.module.scss';

const libraries = ['places'];

const GoogleAutocomplete = React.memo(
  ({ value, onChange, id, label, required, name = nanoid(), className, inputClassName, errors = [] }) => {
    const countryAutocompleteRef = useRef(null);

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyC0mWJ-DJCIYIjFYghXTBOQLK6pqIWF9T8',
      libraries,
    });

    const handlePlaceSelect = (autocompleteRef, setState) => {
      if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        if (place) {
          setState(place.formatted_address || place.name);
        }
      }
    };

    const handleOnChange = (e) => {
      onChange(e.target.value);
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
      <div className={clsx(styles.container, className)}>
        {label && (
          <label className={clsx(styles.label, required && styles.asterisk)} htmlFor={name}>
            {label}
          </label>
        )}

        <Autocomplete
          onLoad={(autocomplete) => (countryAutocompleteRef.current = autocomplete)}
          onPlaceChanged={() => handlePlaceSelect(countryAutocompleteRef, onChange)}
          className={styles.autocomplete}
        >
          <input
            name={name}
            id={String(id ?? name)}
            type="text"
            placeholder={!isLoaded ? 'Country' : 'Loading Google Maps...'}
            value={value}
            className={inputClassName}
            disabled={!isLoaded}
            onChange={handleOnChange}
          />
        </Autocomplete>

        {!!errors.length && (
          <div className={styles.error_container}>
            {errors.map((error, index) => (
              <div key={index} className={styles.error}>
                {error}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);

export default GoogleAutocomplete;
