import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAppContext } from '../../../../../context/context';
import { fetchTeamRoles } from '../../../../../redux/rolesSlice';

import { addNotification } from '../../../../../redux/tooltipSlice';

import PageTitle from '../../../../utilities/page-title/page-title';
import Button from '../../../../utilities/button/button';
import BackdropLoading from '../../../../utilities/backdrop-loading/backdrop-loading';
import Modal from '../../../../utilities/modal/modal';

import EmployeeInfoForm from '../form/employee-info-form';

import { convertValueByType } from '../../../../utilities/utils';

import styles from '../employees.module.scss';

const EmployeeInfoModal = ({ employee, isLoading, isOpen, onCloseModal, onDelete, onEdit }) => {
  const { token } = useAppContext();
  const dispatch = useDispatch();

  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const rolesOptionsStatus = useSelector((state) => state.roles.status);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rolesOptionsStatus === 'idle') {
          await dispatch(fetchTeamRoles(token)).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, rolesOptionsStatus]);

  if (!employee) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} className={styles.modal}>
      <section className={styles.employee_info_container}>
        {isLoading && <BackdropLoading />}
        <EmployeeInfoForm employee={employee} roles={rolesOptions} />
        <div className={styles.action_buttons}>
          <Button
            className={styles.delete_btn}
            variant={'secondary'}
            disabled={isLoading}
            title={'Delete'}
            onClick={() => onDelete(employee.id)}
          />
          <Button
            className={styles.close_btn}
            variant={'secondary'}
            disabled={isLoading}
            title={'Close'}
            onClick={onCloseModal}
          />
          <Button
            className={styles.edit_btn}
            disabled={isLoading}
            title="Edit Employee"
            onClick={() => onEdit(employee.id)}
          />
        </div>
      </section>
    </Modal>
  );
};

export default EmployeeInfoModal;
