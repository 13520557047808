import React from 'react';
import ViewDate from '../components/view-date';
import DisplayTwoDigitWithDelimiter from '../components/display-two-digit-with-delimiter';

export default function OrderItem({ order, openOrderDetails }) {
  function calcPayment(payment) {
    return payment.amount + payment.tips - payment.refunds.reduce((refundSum, refund) => refundSum + refund.amount, 0);
  }

  function calculateTotalPayments(order) {
    return order.payments.reduce(
      (sum, payment) =>
        sum +
        payment.amount +
        payment.tips -
        payment.refunds.reduce((refundSum, refund) => refundSum + refund.amount, 0),
      0,
    );
  }

  const getLast4DigitOfCard = (payment) => payment.Card.last4;

  return (
    <tr key={order.id}>
      <td>
        <p>{order.id}</p>
      </td>
      <td>
        <div className="connected-printer text-left">
          <h6 className="text-gray2 fw-600 mb-1">
            <ViewDate date={order.created_at} />
          </h6>
          <p>ID: {order.offline_id}</p>
        </div>
      </td>
      <td>{order.employee?.full_name}</td>
      <td>{order.dine_in_order_detail?.total_guest_count}</td>
      <td>{order.dine_in_order_detail?.table_name}</td>
      <td>
        <DisplayTwoDigitWithDelimiter amount={order.total_discount} />
      </td>
      <td>
        <p className="fs-14">
          <DisplayTwoDigitWithDelimiter amount={calculateTotalPayments(order)} />
        </p>
        <strong className="d-block fs-14">Payments:</strong>
        {order.payments.map((payment) => (
          <React.Fragment key={payment.id}>
            {payment.display_payment_type} {payment.Card ? `(${getLast4DigitOfCard(payment)})` : ''}:
            <DisplayTwoDigitWithDelimiter amount={calcPayment(payment)} />
            <br />
          </React.Fragment>
        ))}
      </td>
      <td>
        <DisplayTwoDigitWithDelimiter amount={order.total_tax_amount} />
      </td>
      <td>
        <DisplayTwoDigitWithDelimiter amount={order.total_tips} />
      </td>
      <td>
        <DisplayTwoDigitWithDelimiter amount={order.total_service_charges} />
      </td>
      <td>
        <a onClick={openOrderDetails} className="text-blue1 text-underline">
          Details
        </a>
      </td>
    </tr>
  );
}
