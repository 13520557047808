import { useState } from 'react';
import SearchComponent from '../../../../utilities/search/search';
import Button from '../../../../utilities/button/button';

const Search = ({ searchTitle, onSearch, onCleanSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchByButton = () => {
    onSearch(searchValue);
  };

  return (
    <>
      <SearchComponent
        inputPlaceholder={searchTitle}
        onSearchResults={onSearch}
        onChange={setSearchValue}
        onClear={onCleanSearch}
        autoFilter={false}
      />
      <Button title={'Search'} style={{ margin: 0 }} onClick={handleSearchByButton} />
    </>
  );
};

export default Search;
