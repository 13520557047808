import { api, handleApiResponse } from './base';

export const getSalesReport = ({ token, startDate, endDate, terminalId, page = 0 }) =>
  handleApiResponse(() =>
    api.get('/reports/report_summary', {
      headers: {
        Authorization: token,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        terminal_id: terminalId,
        page: page,
      },
    }),
  );

export const getEODReport = ({ token, startDate, endDate }) =>
  handleApiResponse(() =>
    api.get('/reports/employee_eod_report', {
      headers: {
        Authorization: token,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }),
  );

export const getBatchCloseReport = ({ token, startDate, endDate, page, perPage }) =>
  handleApiResponse(() =>
    api.get('/reports/batch_close_report', {
      headers: {
        Authorization: token,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        page: page,
        per_page: perPage,
      },
    }),
  );

export const getCancelOrdersReport = ({ token, startDate, endDate, page, perPage }) =>
  handleApiResponse(() =>
    api.get('/reports/cancelled_orders_report', {
      headers: {
        Authorization: token,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        page: page,
        per_page: perPage,
      },
    }),
  );

export const getCashDiscountReport = ({
  token,
  startDate,
  endDate,
  search,
  orderTypeId,
  openOrderTypeId,
  paymentAmountMin,
  paymentAmountMax,
  page,
  perPage,
}) =>
  handleApiResponse(() =>
    api.get('/reports/cash_discount_report', {
      headers: {
        Authorization: token,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        search: search,
        order_type_id: orderTypeId,
        open_order_type_id: openOrderTypeId,
        payment_amount_min: paymentAmountMin,
        payment_amount_max: paymentAmountMax,
        page: page,
        per_page: perPage,
      },
    }),
  );

export const getCategorySalesReport = ({ token, startDate, endDate, page, perPage }) =>
  handleApiResponse(() =>
    api.get('/reports/category_sales_report', {
      headers: {
        Authorization: token,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        page: page,
        per_page: perPage,
      },
    }),
  );

export const getTransactionsReport = ({
  token,
  start_date,
  end_date,
  search,
  order_type_id,
  employee_id,
  employee_role_id,
  open_order_type_id,
  payment_amount_min,
  payment_amount_max,
  terminal_id,
  card_type,
  page,
  per_page,
}) => {
  const params = {
    start_date,
    end_date,
    search: search,
    employee_id,
    employee_role_id,
    order_type_id,
    open_order_type_id,
    payment_amount_min,
    payment_amount_max,
    terminal_id,
    card_type,
    page,
    per_page,
  };

  // Remove properties with empty or null values
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  });

  return handleApiResponse(() =>
    api.get('/reports/transactions_report', {
      headers: {
        Authorization: token,
      },
      params: params,
    }),
  );
};

export const getDeletedTransactionsReport = ({
  token,
  start_date,
  end_date,
  search,
  order_type_id,
  employee_id,
  employee_role_id,
  open_order_type_id,
  payment_amount_min,
  payment_amount_max,
  terminal_id,
  card_type,
  page,
  per_page,
}) => {
  const params = {
    start_date,
    end_date,
    search,
    order_type_id,
    employee_id,
    employee_role_id,
    open_order_type_id,
    payment_amount_min,
    payment_amount_max,
    terminal_id,
    card_type,
    page,
    per_page,
  };

  // Remove properties with empty or null values
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  });

  return handleApiResponse(() =>
    api.get('/reports/deleted_transactions_report', {
      headers: {
        Authorization: token,
      },
      params: params,
    }),
  );
};

export const getDiscountsReport = ({ token, dateFilter, startDate, endDate, orderTypeId, employeeId, terminalId }) =>
  handleApiResponse(() =>
    api.get('/reports/discounts_report', {
      headers: {
        Authorization: token,
      },
      params: {
        date_filter: dateFilter,
        start_date: startDate,
        end_date: endDate,
        order_type_id: orderTypeId,
        employee_id: employeeId,
        terminal_id: terminalId,
      },
    }),
  );

export const getEmployeeTipSummaryReport = ({ token, dateFilter, startDate, endDate, employeeId, page, perPage }) =>
  handleApiResponse(() =>
    api.get('/reports/employee_tip_summary_report', {
      headers: {
        Authorization: token,
      },
      params: {
        date_filter: dateFilter,
        start_date: startDate,
        end_date: endDate,
        employee_id: employeeId,
        page: page,
        per_page: perPage,
      },
    }),
  );

export const getDetailBatchCloseReport = ({
  token,
  dateFilter,
  search,
  startDate,
  endDate,
  orderTypeId,
  page,
  perPage,
}) =>
  handleApiResponse(() =>
    api.get('/reports/detail_batch_close_report', {
      headers: {
        Authorization: token,
      },
      params: {
        date_filter: dateFilter,
        search: search,
        start_date: startDate,
        end_date: endDate,
        order_type_id: orderTypeId,
        page: page,
        per_page: perPage,
      },
    }),
  );

export const getHourlySalesReport = ({ token, dateFilter, startDate, endDate, employeeId }) =>
  handleApiResponse(() =>
    api.get('/reports/hourly_sales_report', {
      headers: {
        Authorization: token,
      },
      params: {
        date_filter: dateFilter,
        start_date: startDate,
        end_date: endDate,
        employee_id: employeeId,
      },
    }),
  );

export const getOrdersReport = ({
  token,
  start_date,
  end_date,
  search,
  order_type_id,
  employee_id,
  employee_role_id,
  open_order_type_id,
  payment_amount_min,
  payment_amount_max,
  page,
  per_page,
}) => {
  const params = {
    start_date,
    end_date,
    search: search,
    employee_id,
    employee_role_id,
    order_type_id,
    open_order_type_id,
    payment_amount_min,
    payment_amount_max,
    page,
    per_page,
  };

  // Remove properties with empty or null values
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  });

  return handleApiResponse(() =>
    api.get('/reports/orders_report', {
      headers: {
        Authorization: token,
      },
      params: params,
    }),
  );
};

export const getOrderItemsReport = ({
  token,
  dateFilter,
  startDate,
  endDate,
  search,
  employeeId,
  categoryId,
  itemId,
  modifierId,
  taxId,
  page,
  perPage,
}) => {
  const params = {
    date_filter: dateFilter,
    start_date: startDate,
    end_date: endDate,
    search: search,
    employee_id: employeeId,
    category_id: categoryId,
    item_id: itemId,
    modifier_id: modifierId,
    tax_id: taxId,
    page: page,
    per_page: perPage,
  };

  // Remove properties with empty or null values
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  });

  return handleApiResponse(() =>
    api.get('/reports/order_items_report', {
      headers: {
        Authorization: token,
      },
      params: params,
    }),
  );
};

/**
 * Get order details report
 * @param {string} token
 * @param {number} orderId
 * @returns {Promise<*|undefined>}
 */
export const getOrderDetailsReport = ({ token, orderId }) =>
  handleApiResponse(() =>
    api.get(`/reports/order_details_report?order_id=${orderId}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

function removeEmptyProperties(params) {
  return Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  });
}
