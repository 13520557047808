import { nanoid } from 'nanoid';

import { MenuItem } from './menu-item';

import clsx from 'clsx';
import styles from '../sidebar.module.scss';

export const SubMenu = ({ items, parentLink, lastUrlSegment, handleLinkClick, isSidebarOpen }) => (
  <ul className={styles.sub_item_section}>
    {items.map((item) => {
      const isSubitemActive = lastUrlSegment === item.link;

      if (item.link) {
        return (
          <li
            key={item.link}
            className={clsx(styles.menu_item, styles.sub_item, {
              [styles.active]: isSubitemActive,
            })}
          >
            <MenuItem
              item={item}
              link={`${parentLink}${item.link}`}
              callback={() => handleLinkClick(item.link)}
              activeStyle={isSubitemActive}
              isOpen={isSidebarOpen}
            />
          </li>
        );
      }

      return (
        <span key={nanoid()} className={styles.label}>
          {item.name}
        </span>
      );
    })}
  </ul>
);

export default SubMenu;
