import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import List from '../../utilities/list/list';

import { format } from 'date-fns';

import styles from './customer-list.module.scss';

const DATE_FORMAT = "MMMM d, yyyy 'at' hh:mm a";

const CustomerList = ({ customers, onEdit, onDelete, isLoading }) => {
  const renderFullName = (item) => (
    <span className={styles.full_name}>
      {item.last_name !== null ? `${item.first_name} ${item.last_name}` : item.first_name}
    </span>
  );
  const renderPhone = (item) => <span>{item.phones_attributes[0]?.phone_number ?? null}</span>;
  const renderAddress = (item) => (
    <span>{(item.addresses_attributes[0]?.address1 || item.addresses_attributes[0]?.address2) ?? null}</span>
  );
  const renderCountry = (item) => <span>{item.addresses_attributes[0]?.country ?? null}</span>;
  const renderTime = (item) => <span>{format(item.created_at, DATE_FORMAT)}</span>;

  const columnConfig = [
    {
      key: 'full_name',
      header: 'Customer Name',
      render: renderFullName,
    },
    {
      key: 'email',
      header: 'Email Address',
    },
    { key: 'phone_number', header: 'Mobile Number', render: renderPhone },
    { key: 'location_id', header: 'Country', render: renderCountry },
    {
      key: 'adress',
      header: 'Address',
      render: renderAddress,
    },
    { key: 'created_at', header: 'Created At', render: renderTime },
  ];

  const rowActions = [
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit employee', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return <List data={customers} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default CustomerList;
