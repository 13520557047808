import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getCustomers = (token, queryDetails = {}) => {
  const { search, page, perPage } = queryDetails;
  const params = new URLSearchParams();

  if (search) params.append('search', search);
  if (page) params.append('page', page.toString());
  if (perPage) params.append('per_page', perPage.toString());

  const queryString = params.toString();
  const url = `customers${queryString ? `?${queryString}` : ''}`;

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getCustomer = (id, token) =>
  handleApiResponse(() =>
    api.get(`customers/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addCustomer = (newEmployeeDetails, token) =>
  handleApiResponse(() =>
    api.post('customers', newEmployeeDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateCustomer = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`customers/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteCustomer = (id, token) =>
  handleApiResponse(() =>
    api.delete(`customers/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
