export function displayTwoDigit(val) {
  if (val === null || val === undefined) return '0';
  val = parseFloat(val);
  val = val < 0 ? 0 : val;
  return val.toFixed(2);
}

export function displayOneDigit(val) {
  val = parseFloat(val);
  val = val < 0 ? 0 : val;
  return val.toFixed(1);
}

export function displayTwoDigitWithDlr(val) {
  val = parseFloat(val);
  val = val < 0 ? 0 : val;
  return `$${val.toFixed(2)}`;
}

export function displayTwoDigitWithDelimiter(val) {
  val = parseFloat(val);
  val = val < 0 ? 0 : val;
  return numberWithDelimiter(val.toFixed(2));
}

export function numberWithDelimiter(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function orderTypeName(order) {
  if (order && order.order_type) {
    return order.open_order_type
      ? `${order.order_type.name} (${order.open_order_type.name})`
      : order.order_type.name.toString();
  } else {
    return '-';
  }
}

export function displayCustomerName(payment) {
  return payment.card_name;
  // switch (payment.payable_type) {
  //     case "Order":
  //     case "AmountTab":
  //         return payment.order?.customer?.full_name ?? null;
  //     case "Invoice":
  //         const invoice = await Invoice.find(payment.payable_id);
  //         return invoice?.customer?.full_name ?? null;
  //     case "GiftCard":
  //         const giftCard = await GiftCard.find(payment.payable_id);
  //         return giftCard?.customer?.full_name ?? null;
  //     default:
  //         return null;
  // }
}

export function showDollarSign(value) {
  const formattedValue = displayTwoDigitWithDelimiter(value);
  return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
}

export function preetyDate(date) {
  return new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

export function preetyDateWithoutTime(date) {
  return new Date(date).toLocaleString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
}

export function displayOnlyDateFromString(date) {
  return new Date(date).toLocaleString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
}

export function displayOnlyTimeFromString(date) {
  return new Date(new Date()).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
}

export const displayPreparationTime = (order) => {
  if (!order.kitchen_completion_time) return '-';
  const start = new Date(order.created_at);
  const end = new Date(order.kitchen_completion_time);
  const diff = Math.abs(end - start) / 1000 / 60; // difference in minutes
  return `${diff} mins`;
};

export function formatDateTime(dateString) {
  const date = new Date(dateString);
  const pad = (num) => String(num).padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}`;
}

export const PAGE_SIZE_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

export const DEFAULT_PAGINATION = {
  currentPage: 1,
  itemsPerPage: 5,
};

export const PAGINATION = {
  search: '',
  page: 1,
  perPage: 5,
};
