import React from 'react';
import styles from './top-items.module.scss';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import { ReportFilterPanel } from '../components/report-filter-panel';
import DateRangeFilter from '../components/date-range/date-range';
import ReportTable from './report-table';

function TopItems() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const fakeTopItems = [
    ['Item 1', 100.0, 'Description 1', 10.0, 5.0, 115.0],
    ['Item 2', 200.0, 'Description 2', 20.0, 10.0, 230.0],
    // Add more fake top items as needed
  ];

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Top Items'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel dropdown={[]} />
      </div>
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
      </div>
      <div className="bg-white table-responsive mt-4">
        <ReportTable topItems={fakeTopItems} />
      </div>
    </div>
  );
}

export default TopItems;
