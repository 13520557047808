import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import modalReducer from './modalSlice';
import notificationsReducer from './tooltipSlice';
import locationReducer from './locationSlice';

import DashboardReducer from './dashboardSlice';

import EmployeesReducer from './employeesSlice';
import TeamRolesReducer from './rolesSlice';
import TimesheetReducer from './timesheetSlice';

import CustomersReducer from './customersSlice';

import MenuItemsReducer from './menuItemsSlice';
import CategoriesReducer from './categoriesSlice';
import OptionSetsReducer from './optionSetsSlice';
import ModifierSetsReducer from './modifierSetsSlice';

import businessInformationReducer from './informationSlice';
import WorkingHoursReducer from './workingHoursSlice';
import BusinessReportingReducer from './reportingSlice';
import tipsReducer from './tipsSlice';
import ServiceChargesReducer from './serviceChargesSlice';
import taxesReducer from './taxesSlice';
import DiscountsReducer from './discountsSlice';
import DynamicNotesReducer from './dynamicNotesSlice';
import OrderTypesReducer from './orderTypesSlice';
import WastageReasonsReducer from './wastageReasonsSlice';
import TerminalSettingsReducer from './terminalSettingsSlice';
import PrintersReducer from './printersSlice';
import PrintersQueueReducer from './printerQueuesSlice';
import LoyaltyProgramsReducer from './loyaltyProgramsSlice';
import CancelOrderReasonsReducer from './cancelOrderReasonsSlice';
import KitchenReceiptSettingsReducer from './kitchenReceiptSettingsSlice';
import EndOfDayReportSettingsReducer from './endOfDayReportSettingsSlice';
import CustomerReceiptSettingsReducer from './customerReceiptSettingsSlice';
import reportsReducer from './reportsSetSlice';
import itemsSetSlice from './itemsSetSlice';
import inventoriesReducer from './inventoriesSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    notifications: notificationsReducer,
    locations: locationReducer,

    dashboard: DashboardReducer,

    employees: EmployeesReducer,
    roles: TeamRolesReducer,
    timesheet: TimesheetReducer,

    customers: CustomersReducer,

    menu_items: MenuItemsReducer,
    categories: CategoriesReducer,
    optionSets: OptionSetsReducer,
    modifierSets: ModifierSetsReducer,

    business_information: businessInformationReducer,
    hours: WorkingHoursReducer,
    business_reporting: BusinessReportingReducer,
    tips: tipsReducer,
    charges: ServiceChargesReducer,
    taxes: taxesReducer,
    discounts: DiscountsReducer,
    notes: DynamicNotesReducer,
    wastageReason: WastageReasonsReducer,
    loyaltyPrograms: LoyaltyProgramsReducer,
    cancelOrderReasons: CancelOrderReasonsReducer,
    orderTypes: OrderTypesReducer,
    printers: PrintersReducer,
    printerQueues: PrintersQueueReducer,
    terminalSettings: TerminalSettingsReducer,
    kitchenReceiptSettings: KitchenReceiptSettingsReducer,
    endOfDayReportSettings: EndOfDayReportSettingsReducer,
    customerReceiptSettings: CustomerReceiptSettingsReducer,
    reports: reportsReducer,
    itemsSet: itemsSetSlice,
    inventories: inventoriesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/showModal'],
        ignoredPaths: ['modal.openModals', 'modal.openModals.*.data.actions'],
      },
    }),
});

export default store;
