import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getModifierSets = (token) => {
  return handleApiResponse(() =>
    api.get('modifier_sets', {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getModifierSet = (id, token) =>
  handleApiResponse(() =>
    api.get(`modifier_sets/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addModifierSet = (newModifierDetails, token) =>
  handleApiResponse(() =>
    api.post('modifier_sets', newModifierDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateModifierSet = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`modifier_sets/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteModifierSet = (id, token) =>
  handleApiResponse(() =>
    api.delete(`modifier_sets/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const reorderModifierSet = (id, updatedDetails, token) => {
  return handleApiResponse(() =>
    api.put(
      `modifier_sets/${id}/reorder?old_position=${updatedDetails.old_position}&new_position=${updatedDetails.new_position}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
};
