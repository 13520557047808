import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../utilities/page-title/page-title';

import { fetchTransactionsReport } from '../../../../redux/reportsSetSlice';

import {
  DropdownTypes,
  HeaderReport,
  ReportFilterPanel,
  DateRangeFilter,
  TotalRangeFilter,
  PAGINATION,
} from '../components';

import TransactionsSection from './transactions-section';

import styles from './transactions.module.scss';

function Transactions() {
  const { token } = useAppContext();
  const dispatch = useDispatch();

  const paramsInit = {
    start_date: new Date(),
    end_date: new Date(),
    page: PAGINATION.page,
    per_page: PAGINATION.perPage,
  };

  const [filterParams, setFilterParams] = useState(paramsInit);

  // const downloadPdf = () => {};
  // const downloadCsv = () => {};

  useEffect(() => {
    dispatch(
      fetchTransactionsReport({
        token,
        ...filterParams,
      }),
    );
  }, [dispatch, filterParams, token]);

  const handleSetFilterParams = (params) => {
    setFilterParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const handleClearFilters = () => {
    setFilterParams(paramsInit);
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name="Transactions" />
      </div>
      <div className={styles.main_content}>
        <div className={clsx(styles.boxBody, styles.borderBox)}>
          <ReportFilterPanel
            filterParams={filterParams}
            dropdowns={[
              DropdownTypes.employee,
              DropdownTypes.payment_type,
              DropdownTypes.card_type,
              DropdownTypes.terminal_id,
              DropdownTypes.order_type_id,
            ]}
            onClearFilterParams={handleClearFilters}
            onUpdateParams={handleSetFilterParams}
          />
          <TotalRangeFilter
            rangeFrom={filterParams?.from}
            rangeTo={filterParams?.to}
            onUpdate={handleSetFilterParams}
          />
        </div>
        <div className={clsx(styles.boxBody, styles.borderBox)}>
          <DateRangeFilter
            showPreset={true}
            startDate={filterParams.start_date}
            endDate={filterParams.end_date}
            onUpdateParams={handleSetFilterParams}
          />
        </div>
        <TransactionsSection filterParams={filterParams} onUpdateParams={handleSetFilterParams} />
      </div>
    </div>
  );
}

export default Transactions;
