import React from 'react';

import clsx from 'clsx';
import styles from './label.module.scss';

const Label = ({ text, required = false, className }) => {
  return (
    <label className={clsx(styles.label, className)}>
      {text}
      {required && <span className={styles.starlet}>*</span>}
    </label>
  );
};

export default Label;
