export const DropdownTypes = {
  employee: 'employee_id',
  payment_type: 'payment_type',
  order_type_id: 'order_type_id',
  terminal_id: 'terminal_id',
  tax_id: 'tax_id',
  modifier_id: 'modifier_id',
  category_id: 'category_id',
  item_id: 'item_id',
  captured_id: 'captured_id',
  card_type: 'card_type',
  date_range: 'date_range',
  teamId: 'teamId',
  roleId: 'roleId',
  cashDiscountSurCharge: 'cashDiscountSurCharge',
};
