import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getInventoriesCount } from '../api/menu_items';

// Thunks
export const fetchInventoriesCount = createAsyncThunk('inventories/fetchInventoriesCount', async (token) => {
  const response = await getInventoriesCount(token);
  return response.data;
});

const initialState = {
  inventories: [],
  status: 'idle',
  error: null,
};

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchInventoriesCount.pending, setLoading)
      .addCase(fetchInventoriesCount.fulfilled, (state, action) => {
        setSucceeded(state);
        state.inventories = action.payload;
      })
      .addCase(fetchInventoriesCount.rejected, setFailed);
  },
});

export default inventoriesSlice.reducer;
