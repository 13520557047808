import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchDiscounts,
  createDiscount,
  updateExistingDiscount,
  removeDiscount,
  updateExistingDiscountStatus,
} from '../../../../redux/discountsSlice';

import { showModal, hideModal } from '../../../../redux/modalSlice';
import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import PageTitle from '../../../utilities/page-title/page-title';
import Button from '../../../utilities/button/button';
import Input from '../../../utilities/input/input';

import DiscountsList from './discounts-list';
import EditSalesTaxesModal from './modals/discounts-edit-modal';

import clsx from 'clsx';
import styles from './discounts.module.scss';

function Discounts() {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const discounts = useSelector((state) => state.discounts.discounts);
  const discountsStatus = useSelector((state) => state.discounts.status);

  const [taxType, setTaxType] = useState('Percentage');
  const [newDiscountName, setNewDiscountName] = useState('');
  const [newDiscountRate, setNewDiscountRate] = useState('');
  const [refetchDiscounts, setRefetchDiscounts] = useState(false);
  const [editedDiscounts, setEditedDiscounts] = useState({});

  // EDIT MODAL WINDOW
  const [isEditSalesTaxesModal, setIsEditSalesTaxesModal] = useState(false);
  const [editableItem, setEditableItem] = useState('');
  const [editableName, setEditableName] = useState('');
  const [editableRate, setEditableRate] = useState('');

  // Validation hook
  const { errors, validate } = useFormValidation(registrationSchema(taxType));

  const inputRef = useRef(null);

  useEffect(() => {
    if (discountsStatus === 'idle') {
      dispatch(fetchDiscounts({ token }));
    }
  }, [dispatch, discountsStatus, token]);

  useEffect(() => {
    dispatch(fetchDiscounts({ token }));
  }, [dispatch, refetchDiscounts, token]);

  const handleAdd = () => {
    const newDiscountDetails = {
      name: newDiscountName,
      location_id: locationId,
      percentage: Number(newDiscountRate),
      discount_type: taxType,
    };

    if (validate(newDiscountDetails)) {
      dispatch(createDiscount({ newDiscountDetails, token }));
    }
  };

  const handleEdit = useCallback(
    (itemId) => {
      const Tax = discounts.find((tip) => tip.id === itemId);
      setEditableItem(Tax);
      setEditableName(Tax.name);
      setEditableRate(Tax.percentage);
      setIsEditSalesTaxesModal(true);
    },
    [discounts],
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-discount',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this discount?',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeDiscount({ id, token }));
                  dispatch(hideModal('modal-delete-discount'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'secondary',
                onAction: () => dispatch(hideModal('modal-delete-discount')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingDiscountStatus({ id, is_active: value, token }));
    },
    [dispatch, token],
  );

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Discounts'} />
      </div>
      <div className={styles.list_container}>
        <div className={styles.add_discount}>
          <div className={styles.section}>
            <Input
              label={'Discount Name'}
              value={newDiscountName}
              onChange={(e) => setNewDiscountName(e.target.value)}
              placeholder={'Discount Name'}
              required={true}
              className={styles.discount_name_input}
              errors={errors.name?._errors}
            />
          </div>
          <div className={clsx(styles.section, styles.discount_rate_wrapper)}>
            <Input
              ref={inputRef}
              label={'Discount Rate'}
              type="number"
              value={newDiscountRate}
              onChange={(e) => setNewDiscountRate(e.target.value)}
              placeholder={`0.00${taxType === 'Amount' ? '$' : '%'}`}
              required={true}
              className={styles.discount_percent_input}
              errors={errors.percentage?._errors}
            />

            <div
              className={styles.discount_type_buttons}
              style={{
                top: inputRef.current ? `${inputRef.current.offsetTop + inputRef.current.clientHeight / 2}px` : '0',
              }}
            >
              <button
                className={`${styles.discount_type_button} ${taxType === 'Percentage' ? styles.active : ''}`}
                onClick={() => setTaxType('Percentage')}
              >
                %
              </button>
              <button
                className={`${styles.discount_type_button} ${taxType === 'Amount' ? styles.active : ''}`}
                onClick={() => setTaxType('Amount')}
              >
                $
              </button>
            </div>
          </div>
          <Button className={styles.add_button} title={'Add Discount'} onClick={handleAdd} />
        </div>
        <DiscountsList
          items={discounts}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onToggleActive={handleToggleActive}
          isLoading={discountsStatus === 'loading'}
        />

        <EditSalesTaxesModal
          isModalOpen={isEditSalesTaxesModal}
          setIsModalOpen={setIsEditSalesTaxesModal}
          editableItem={editableItem}
          setEditableItem={setEditableItem}
          editableName={editableName}
          setEditableName={setEditableName}
          editableRate={editableRate}
          setEditableRate={setEditableRate}
        />
      </div>
    </div>
  );
}

export default Discounts;
