import React from 'react';

import { useSelector } from 'react-redux';

import Pagination from '../../../utilities/pagination/pagination';
import { PAGE_SIZE_OPTIONS } from '../components/helper';

import OrdersList from './orders-list';

function OrdersSection({ filterParams, onUpdateParams }) {
  const orders = useSelector((state) => state.reports.ordersReport);
  const ordersStatus = useSelector((state) => state.reports.status);

  const handleDeleteOrder = () => {
    console.log('Delete Order');
  };

  //----Pagination Controls---//
  const handleSelectPageCount = (value) => {
    onUpdateParams({ per_page: value });
  };

  const handleClickPrev = () => {
    if (filterParams.page > 1) {
      onUpdateParams({ page: filterParams.page - 1 });
    }
  };

  const handleClickNext = () => {
    onUpdateParams({ page: filterParams.page + 1 });
  };

  return (
    <>
      <div>
        <OrdersList orders={orders} isLoading={ordersStatus === 'loading'} onDelete={handleDeleteOrder} />
        <Pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          numberOfItems={orders.length > 1 ? 400 : 0} // Temporary value. Need to get this value from the API
          currentPage={filterParams.page}
          currentPageSize={filterParams.per_page}
          onPageSizeChange={handleSelectPageCount}
          onPrev={handleClickPrev}
          onNext={handleClickNext}
        />
      </div>
    </>
  );
}

export default OrdersSection;
