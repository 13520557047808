import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { fetchOrderType, updateExistingOrderType } from '../../../../redux/orderTypesSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import { IoIosArrowDown } from 'react-icons/io';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import { convertValueByType, isObjectEmpty } from '../../../utilities/utils';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Selector from '../../../utilities/selector/selector';
import Button from '../../../utilities/button/button';
import Switch from '../../../utilities/switch/switch';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import clsx from 'clsx';
import styles from './order-types.module.scss';

const OPEN_ORDER_TYPES = [
  { value: 'TakeOut', label: 'Take Out' },
  { value: 'OpenOrder', label: 'Open Order' },
];

const getOrder = (orders, id) => {
  return orders.find((order) => order.id === parseFloat(id));
};

const EditOrderType = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderTypesStatus = useSelector((state) => state.orderTypes.status);
  const orders = useSelector((state) => state.orderTypes.orderTypes);
  const currentOrderType = useSelector((state) => state.orderTypes.currentOrderType);
  const [order, setOrder] = useState(getOrder(orders, id) || currentOrderType);

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = orderTypesStatus === 'loading';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (orderTypesStatus === 'idle') {
          await dispatch(fetchOrderType({ id, token })).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, id, orderTypesStatus]);

  useEffect(() => {
    if (isObjectEmpty(order) && !isObjectEmpty(currentOrderType)) {
      setOrder(currentOrderType);
    }
  }, [currentOrderType, order]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setOrder((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/business/order_types');
  };

  const handleSaveChanges = async () => {
    if (validate(order)) {
      try {
        await dispatch(updateExistingOrderType({ id, updatedDetails: order, token })).unwrap();
        navigate('/business/order_types');
      } catch {}
    }
  };

  if (!order) {
    return null;
  }

  return (
    <section className={styles.order_section}>
      <div className={styles.head_container}>
        <PageTitle name={'Edit Order Type'} />
      </div>
      <div className={styles.form_container}>
        <div className={styles.fields}>
          {statusLoading ? <BackdropLoading /> : null}
          <div className={styles.row}>
            <div className={styles.field}>
              <Input
                label={'Order Type Name'}
                value={order.name}
                onChange={handleInputChange}
                name={'name'}
                required={true}
                errors={errors.name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Selector
                label={'Order Type'}
                icon={IoIosArrowDown}
                items={OPEN_ORDER_TYPES}
                selectedItem={
                  OPEN_ORDER_TYPES.find((item) => (item.value === order.order_type ? item.value : null))?.value ??
                  order.order_type
                }
                onSelectItem={(value) => handleInputChange({ target: { name: 'order_type', value } }, 'text')}
                initialPlaceholderVisibility={false}
                name={'order_type'}
                required={true}
                errors={errors.order_type?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Kitchen Color Code'}
                value={order.kitchen_color_code || '#000000'}
                type="color"
                onChange={handleInputChange}
                name={'kitchen_color_code'}
                required={true}
                errors={errors.kitchen_color_code?._errors}
              />
            </div>
          </div>
          <div className={clsx(styles.field, styles.switcher)}>
            <Switch
              id={'is_active'}
              isActive={order.is_active}
              onSwitch={(name, value) => handleInputChange({ target: { name, value } }, 'boolean')}
            />
            <span>Active</span>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            disabled={statusLoading}
            title={'Save Order Type'}
            onClick={handleSaveChanges}
          />
          <Button
            className={styles.button}
            variant={'secondary'}
            disabled={statusLoading}
            title={'Cancel'}
            onClick={handleCancel}
          />
        </div>
      </div>
    </section>
  );
};

export default EditOrderType;
