import { api, handleApiResponse } from './base';

export const getCategories = (token) =>
  handleApiResponse(() =>
    api.get('categories', {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addCategory = (newDetails, token) =>
  handleApiResponse(() =>
    api.post('categories', newDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const getCategory = (id, token) =>
  handleApiResponse(() =>
    api.put(`categories/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateCategory = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`categories/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteCategory = (id, token) =>
  handleApiResponse(() =>
    api.delete(`categories/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateCategoryOrder = (id, updatedDetails, token) => {
  return handleApiResponse(() =>
    api.put(
      `categories/${id}/reorder?old_position=${updatedDetails.old_position}&new_position=${updatedDetails.new_position}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
};

export const updateCategoryStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(
      `categories/${id}/active?is_active=${is_active}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
