import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getOptionSets, getOptionSet, addOptionSet, updateOptionSet, deleteOptionSet } from '../api/option_sets';
import { fetchInventoriesCount } from './inventoriesSlice';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchOptions = createAsyncThunk('optionSets/fetchOptions', async ({ token }) => {
  const response = await getOptionSets(token);
  return response.data;
});

export const fetchOption = createAsyncThunk('optionSets/fetchOption', async ({ id, token }) => {
  const response = await getOptionSet(id, token);
  return response.data;
});

export const addOption = createAsyncThunk('optionSets/addOption', async ({ newOptionDetails, token }, { dispatch }) => {
  try {
    const response = await addOptionSet(newOptionDetails, token);
    dispatch(
      addNotification({
        message: 'Option set successfully created',
        status: 'succeeded',
      }),
    );
    dispatch(fetchInventoriesCount(token));
    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to create Option Set, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

export const updateExistingOption = createAsyncThunk(
  'optionSets/updateExistingOption',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateOptionSet(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Option Set successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update Option Set, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeOption = createAsyncThunk('optionSets/removeOption', async ({ id, token }, { dispatch }) => {
  try {
    await deleteOptionSet(id, token);
    dispatch(
      addNotification({
        message: 'Option Set successfully deleted',
        status: 'succeeded',
      }),
    );
    dispatch(fetchInventoriesCount(token));
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to delete Option Set, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

const initialState = {
  optionSets: [],
  currentOptionSet: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const optionSetsSlice = createSlice({
  name: 'optionSets',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchOptions.pending, setLoading)
      .addCase(fetchOptions.fulfilled, (state, action) => {
        setSucceeded(state);
        state.optionSets = action.payload;
      })
      .addCase(fetchOptions.rejected, setFailed)

      .addCase(fetchOption.pending, setLoading)
      .addCase(fetchOption.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentOptionSet = action.payload;
      })
      .addCase(fetchOption.rejected, setFailed)

      .addCase(addOption.pending, setLoading)
      .addCase(addOption.fulfilled, (state, action) => {
        setSucceeded(state);
        state.optionSets.push(action.payload);
      })
      .addCase(addOption.rejected, setFailed)

      .addCase(updateExistingOption.pending, setLoading)
      .addCase(updateExistingOption.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.optionSets.findIndex((option) => option.id === updatedDetails.id);

        if (index !== -1) {
          state.optionSets[index] = updatedDetails;
        }
      })
      .addCase(updateExistingOption.rejected, setFailed)

      .addCase(removeOption.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.optionSets = state.optionSets.filter((option) => option.id !== id);
      })
      .addCase(removeOption.pending, setLoading)
      .addCase(removeOption.rejected, setFailed);
  },
});

export const { setQueryDetails } = optionSetsSlice.actions;
export default optionSetsSlice.reducer;
