import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppContext } from '../../../../context/context';

import { updateExistingTeamRole, removeTeamRole } from '../../../../redux/rolesSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import Modal from '../../../utilities/modal/modal';

import EditModal from './modals-templates/edit-modal';
import AssignModal from './modals-templates/assign-modal';

import { registrationSchema } from './validation';

import useFormValidation from '../../../utilities/useFormValidation';
import TeamRolesList from './team-roles-list';

const transformStateToIds = (roleItems, id) => {
  const role = roleItems.find((role) => role.id === id);

  if (!role) {
    return null;
  }

  const employee_ids = role.employees.filter((employee) => employee.selected).map((employee) => employee.id);
  const module_ids = role.module_permission.filter((module) => module.selected).map((module) => module.id);

  return {
    id: role.id,
    name: role.name,
    employee_ids,
    module_ids,
  };
};

const getPossibleItems = (available_employees, available_modules, all_roles) => {
  return all_roles.map((role) => {
    return {
      id: role.id,
      name: role.name,
      employees: available_employees.map((item) => ({
        id: item.id,
        name: item.name,
        selected: role.employees.some((employee) => employee.id === item.id),
      })),
      module_permission: available_modules.map((item) => ({
        id: item.id,
        name: item.name,
        selected: role.module_permission.some((module) => module.id === item.id),
      })),
    };
  });
};

const AssignRolesList = ({ roles, all_employees, all_modules }) => {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const { errors, validate } = useFormValidation(registrationSchema);

  const possible_items = useMemo(
    () => getPossibleItems(all_employees, all_modules, roles),
    [all_employees, all_modules, roles],
  );

  const [currentRoleId, setCurrentRoleId] = useState(undefined);
  const [currentAssignModel, setCurrentAssignModel] = useState({});
  const [currentRoleName, setCurrentRoleName] = useState(undefined);
  const [modalsState, setModalsState] = useState({
    assignModal: false,
    editModal: false,
  });

  const [initialState, setInitialState] = useState(possible_items);
  const [roleItems, setRoleItems] = useState(possible_items);

  useEffect(() => {
    setRoleItems(possible_items);
  }, [possible_items]);

  const handleSaveChanges = async (id, method) => {
    const state = transformStateToIds(roleItems, id);

    const newDetails = {
      ...state,
      name: currentRoleName,
      location_id: locationId,
    };

    if (method === 'assign' || validate(newDetails)) {
      try {
        await dispatch(
          updateExistingTeamRole({
            id,
            updatedDetails: newDetails,
            token,
          }),
        ).unwrap();
        setModalsState({ assignModal: false, editModal: false });
      } catch {}
    }
  };

  const handleAssign = useCallback((id, type) => {
    setCurrentAssignModel({
      name: type === 'employees' ? 'Employee' : 'Module',
      type,
    });
    setCurrentRoleId(id);
    setModalsState((prev) => ({ ...prev, assignModal: true }));
  }, []);

  const handleItemClick = (roleId, itemId, category) => {
    setRoleItems((prev) =>
      prev.map((role) =>
        role.id === roleId
          ? {
              ...role,
              [category]: role[category].map((item) =>
                item.id === itemId ? { ...item, selected: !item.selected } : item,
              ),
            }
          : role,
      ),
    );
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-roles',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this role?',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeTeamRole({ id, token }));
                  dispatch(hideModal('modal-delete-roles'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'secondary',
                onAction: () => dispatch(hideModal('modal-delete-roles')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleEdit = (id) => {
    setCurrentRoleId(id);
    setCurrentRoleName(roleItems.find((role) => role.id === id)?.name);
    setModalsState((prev) => ({ ...prev, editModal: true }));
  };

  return (
    <>
      <TeamRolesList roles={roles} onEdit={handleEdit} onDelete={handleDelete} onAssign={handleAssign} />
      <Modal
        isOpen={modalsState.editModal && currentRoleId}
        onClose={() => setModalsState((prev) => ({ ...prev, editModal: false }))}
      >
        <EditModal
          id={currentRoleId}
          roleItems={roleItems}
          roleName={currentRoleName}
          onItemClick={handleItemClick}
          onNameChange={setCurrentRoleName}
          onSaveChanges={handleSaveChanges}
          errors={errors}
        />
      </Modal>
      <Modal
        isOpen={modalsState.assignModal && currentRoleId}
        onClose={() => setModalsState((prev) => ({ ...prev, assignModal: false }))}
      >
        <AssignModal
          id={currentRoleId}
          roleItems={roleItems}
          onItemClick={handleItemClick}
          onSaveChanges={handleSaveChanges}
          assignName={currentAssignModel.name}
          assignType={currentAssignModel.type}
        />
      </Modal>
    </>
  );
};

export default AssignRolesList;
