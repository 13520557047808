import styles from './toggler.module.scss';
import clsx from 'clsx';

const Toggler = ({ toggle, onToggle }) => {
  const handleOnToggle = (value) => {
    onToggle(value);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.slider, { [styles.active]: toggle })}></div>
      <span onClick={() => handleOnToggle(true)}>Active</span>
      <span onClick={() => handleOnToggle(false)}>Inactive</span>
    </div>
  );
};

export default Toggler;
