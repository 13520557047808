import { api, handleApiResponse } from './base';

export const getBusinessInformation = (token) =>
  handleApiResponse(() =>
    api.get('web/locations', {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateBusinessInformation = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`web/locations/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );
