import React, { useState, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import Lens from '../../../assets/icons/lens.svg';
import { IoSearch } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';

import styles from './search.module.scss';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Search = ({
  data = [],
  placeholder,
  onSearchResults,
  onChange,
  onClear,
  autoFilter = true,
  style,
  className,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedValue = useDebounce(searchQuery, 500);

  const getFilteredData = useCallback(
    (query) => {
      return data.filter((item) => item.toLowerCase().includes(query.toLowerCase()));
    },
    [data],
  );

  const filteredItems = useMemo(() => {
    return autoFilter ? getFilteredData(debouncedValue) : data;
  }, [autoFilter, getFilteredData, debouncedValue, data]);

  useEffect(() => {
    if (autoFilter && !!data.length) {
      onSearchResults(filteredItems);
    }
  }, [autoFilter, filteredItems, onSearchResults, data]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    onChange?.(event.target.value);
  };

  const handleSearch = () => {
    if (!autoFilter) {
      const results = data.length ? getFilteredData(searchQuery) : searchQuery;
      onSearchResults(results);
    }
  };

  const handleClear = () => {
    if (!data.length) {
      onClear();
    }

    setSearchQuery('');
  };

  return (
    <div className={clsx(styles.container, className)} style={style}>
      <div className={styles.button} onClick={handleSearch}>
        {/* <IoSearch className={styles.icon} /> */}
        <img src={Lens} className={styles.icon} alt="Lens_icon" />
      </div>
      <input
        type="text"
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') handleSearch();
        }}
        placeholder={placeholder}
      />
      {!!searchQuery.length && (
        <div className={clsx(styles.button, styles.clear)} onClick={handleClear}>
          <MdClear className={clsx(styles.icon)} />
        </div>
      )}
    </div>
  );
};

export default Search;
