import React from 'react';
import { displayTwoDigitWithDelimiter } from '../components/helper';

export default function ReportTable({ topItems }) {
  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th>Menu Items</th>
          <th>Avg Price</th>
          <th>Item Quantity</th>
          <th>Gross Amount</th>
          <th>Discount Amount</th>
          <th>Net Amount</th>
        </tr>
      </thead>
      <tbody id="top_items_body">
        {topItems.length > 0 ? (
          topItems.map((topItem, index) => (
            <tr key={index}>
              <td>{topItem[0]}</td>
              <td>${displayTwoDigitWithDelimiter(topItem[1])}</td>
              <td className="text-left">{topItem[2]}</td>
              <td>${displayTwoDigitWithDelimiter(topItem[3])}</td>
              <td>${displayTwoDigitWithDelimiter(topItem[4])}</td>
              <td>${displayTwoDigitWithDelimiter(topItem[5])}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">
              <center>No Records available.</center>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
