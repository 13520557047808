import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLocations, updateOtherSettings } from '../../../../redux/locationSlice';
import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../utilities/page-title/page-title';
import Checkbox from '../../../utilities/checkbox/checkbox';
import Button from '../../../utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';
import { isEqual, isObjectEmpty } from '../../../utilities/utils';

import styles from './other-settings.module.scss';

function OtherSettings() {
  // Global State
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const locations = useSelector((state) => state.locations.locations);
  const locationsStatus = useSelector((state) => state.locations.status);

  const isLoading = locationsStatus === 'loading';

  // Local State
  const [currentLocation, setCurrentLocation] = useState({});
  const [initialSettings, setInitialSettings] = useState({});
  const [settings, setSettings] = useState({});

  const isSettingsStateChanged = useMemo(() => !isEqual(initialSettings, settings), [initialSettings, settings]);

  const getSettingsFromLocation = (location) => ({
    isPrinterQueueEnabled: location.is_printer_queue_enable || false,
    isOrderNumberStartingFromOne: location.order_number_starting_from_one || false,
    isOnlyShowPriceGreaterThanZero: location.only_show_price_greater_than_zero || false,
  });

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations) {
      const location = locations.find((location) => location.id === parseInt(locationId));
      if (location && !isEqual(location, currentLocation)) {
        setCurrentLocation(location);
      }
    }
  }, [locations, locationId, currentLocation]);

  useEffect(() => {
    if (!isObjectEmpty(currentLocation)) {
      const newSettings = getSettingsFromLocation(currentLocation);

      setSettings(newSettings);
      setInitialSettings(newSettings);
    }
  }, [currentLocation]);

  // Handlers
  const handleCheckboxChange = useCallback((name, value) => {
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSave = () => {
    if (!isSettingsStateChanged) return;

    const payload = {
      isPrinterQueueEnabled: settings.isPrinterQueueEnabled,
      isOrderNumberStartingFromOne: settings.isOrderNumberStartingFromOne,
      isOnlyShowPriceGreaterThanZero: settings.isOnlyShowPriceGreaterThanZero,
      token,
      locationId,
    };

    dispatch(updateOtherSettings(payload));
  };

  // Setting checkbox component
  const SettingCheckbox = React.memo(({ settingName, title }) => (
    <div className={styles.menu_item}>
      <Checkbox
        item={{ id: settingName, name: title }}
        handleOnChange={handleCheckboxChange}
        isChecked={settings[settingName]}
      />
      <span className={styles.menu_item_name} onClick={() => handleCheckboxChange(settingName, !settings[settingName])}>
        {title}
      </span>
    </div>
  ));

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Other Settings'} />
      </div>
      <div className={styles.main_content}>
        {isLoading && <BackdropLoading />}
        <SettingCheckbox settingName="isPrinterQueueEnabled" title="Printer Queue Enable" />
        <SettingCheckbox settingName="isOrderNumberStartingFromOne" title="Order number starting from 1 everyday" />
        <SettingCheckbox settingName="isOnlyShowPriceGreaterThanZero" title="Only show price greater than zero?" />
        <Button className={styles.save_button} title={'Save'} onClick={handleSave} disabled={!isSettingsStateChanged} />
      </div>
    </div>
  );
}

export default OtherSettings;
