import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { IoTrash } from 'react-icons/io5';
import List from '../../../utilities/list/list';
import { orderTypeName, displayTwoDigit, displayCustomerName } from '../components/helper';

import styles from './deleted-transactions.module.scss';

const DATE_FORMAT = "MMMM d, yyyy 'at' hh:mm a";

const DeletedTransactionsList = ({ transactions, employees, isLoading, onDelete }) => {
  const renderEmployeeName = (id) => {
    const employee = employees.find((e) => e.id === id);

    return employee?.full_name;
  };

  const renderCreatedAt = (item) => (
    <span>
      <span className={styles.created_at}>{format(item.created_at, DATE_FORMAT)}</span>
      {item.offline_id && <p>ID:{item.offline_id}</p>}
    </span>
  );
  const renderTenderType = (item) => (
    <span>
      {item.payment_type} {item.Card ? `(${item.Card.last4})` : ''}
    </span>
  );
  const renderOrderType = (item) => <span>{orderTypeName(item.order)}</span>;
  const renderAmount = (item) => <span>${displayTwoDigit(item.amount + item.tips)}</span>;
  const renderTaxAmount = (item) => <span>${displayTwoDigit(item.tax_amount)}</span>;
  const renderServiceCharge = (item) => <span>${displayTwoDigit(item.service_charge_amount)}</span>;
  const renderTips = (item) => <span>${displayTwoDigit(item.tips)}</span>;
  const renderTotalDiscount = (item) => <span>${displayTwoDigit(item.total_discount)}</span>;
  const renderEmployee = (item) => <span>{renderEmployeeName(item.employee_id) || item.employee_id || 'N/A'}</span>;
  const renderCustomer = (item) => <span>{displayCustomerName(item)}</span>;
  const renderDetails = (item) => (
    <span>
      <Link to={`/reporting/deleted_transaction_details/${item.id}`}>Details</Link>
    </span>
  );

  const columnConfig = [
    {
      key: 'created_at',
      header: 'Created',
      render: renderCreatedAt,
    },
    {
      key: 'id',
      header: 'Trans#',
    },
    { key: 'card_number', header: 'Tender Type', render: renderTenderType },
    { key: 'order', header: 'Order Type', render: renderOrderType },
    {
      key: 'amount',
      header: 'Amount',
      render: renderAmount,
    },
    { key: 'tax_amount', header: 'Taxes and Fees', render: renderTaxAmount },
    { key: 'service_charge_amount', header: 'Service Charge', render: renderServiceCharge },
    { key: 'tips', header: 'Tip Amount', render: renderTips },
    { key: 'total_discount', header: 'Discount', render: renderTotalDiscount },
    { key: 'employee_id', header: 'Employee', render: renderEmployee },
    { key: 'card_name', header: 'Customer', render: renderCustomer },
    { key: 'details', header: 'Details', render: renderDetails, cellClassName: styles.details },
  ];

  const rowActions = [
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete order', className: styles.row_actions_button },
  ];

  return <List data={transactions} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default DeletedTransactionsList;
