import { useState, useMemo, useCallback, useEffect } from 'react';
import { isObjectEmpty } from '../../../../utilities/utils';

export const usePrinterSettings = (orderTypes, initialPrinter = {}) => {
  const predefinedSettings = useMemo(() => {
    return orderTypes.flatMap((order) => [
      {
        order_type_id: order.id,
        order_types_name: order.name,
        print_type: 'Customer',
        manual_printing: false,
        auto_printing: false,
        status: false,
      },
      {
        order_type_id: order.id,
        order_types_name: order.name,
        print_type: 'Kitchen',
        manual_printing: false,
        auto_printing: false,
        status: false,
      },
    ]);
  }, [orderTypes]);

  const [printer, setPrinter] = useState({
    ...initialPrinter,
  });

  const [printerSettings, setPrinterSettings] = useState(predefinedSettings);

  useEffect(() => {
    const { printer_settings_attribute, category_ids, ...rest } = printer;
    if (isObjectEmpty(rest) && !isObjectEmpty(initialPrinter)) {
      setPrinter((prev) => ({
        ...prev,
        ...initialPrinter,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPrinter]);

  useEffect(() => {
    setPrinterSettings(() =>
      predefinedSettings.filter(
        (setting) =>
          printer?.receipt_print_type === 'KitchenAndCustomer' || setting.print_type === printer?.receipt_print_type,
      ),
    );
  }, [predefinedSettings, printer?.receipt_print_type]);

  useEffect(() => {
    setPrinter((prev) => ({
      ...prev,
      printer_settings_attribute: printerSettings
        .filter((setting) => setting.status)
        .map(({ status, order_types_name, ...rest }) => rest),
    }));
  }, [printer.receipt_print_type, printerSettings]);

  const handleOrderTypes = useCallback((data, value) => {
    const { id, print_method, print_type, statusChanged } = JSON.parse(data);

    setPrinterSettings((prev) => {
      const newSettings = prev.slice();
      const index = newSettings.findIndex((s) => s.order_type_id === id && s.print_type === print_type);

      if (index !== -1) {
        newSettings[index] = {
          ...newSettings[index],
          [statusChanged ? 'status' : print_method]: value,
        };
      }

      return newSettings;
    });
  }, []);

  const handleInputChange = useCallback(
    (e, type) => {
      const { name, value } = e.target;

      if (name === 'receipt_print_type') {
        const oppositeValue =
          value === 'Kitchen' ? 'Customer' : value === 'Customer' ? 'Kitchen' : 'KitchenAndCustomer';

        setPrinterSettings(
          value === 'KitchenAndCustomer'
            ? predefinedSettings
            : [...predefinedSettings.filter((s) => s.print_type !== oppositeValue)],
        );
      }

      setPrinter((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [predefinedSettings],
  );

  return {
    printer,
    printerSettings,
    handleOrderTypes,
    handleInputChange,
    setPrinter,
  };
};
