import { parse, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
export const convertValueByType = (value, type) => {
  if (value === null || value === undefined) return value;

  switch (type) {
    case 'number':
      const numberValue = parseFloat(value);
      return isNaN(numberValue) ? 0 : numberValue;
    case 'boolean':
      return Boolean(value);
    case 'string':
      return value.toString();
    default:
      return value;
  }
};

export const isObject = (value) => {
  return value != null && (value.constructor === Object || (!value.constructor && typeof value === 'object'));
};

export function isAnArray(value) {
  return value != null && Array.isArray(value) && value instanceof Array;
}

export function isFunction(value) {
  return value instanceof Function;
}

export const isObjectEmpty = (objectName) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

export const isArrayEmpty = (array) => {
  return Array.isArray(array) && array.length === 0;
};

export const checkIfArrayOfObjects = (value) => value.every((item) => isObject(item));

export const mergeToArray = (stateArray = [], newArray = [], compareKeys = ['id']) => {
  if (!Array.isArray(stateArray)) stateArray = [];
  if (!Array.isArray(newArray)) newArray = [];
  if (!Array.isArray(compareKeys)) compareKeys = [compareKeys];

  if (newArray.length === 0) {
    return [];
  }

  const areItemsEqual = (item1, item2) => {
    return compareKeys.every((key) => item1 && item2 && item1[key] === item2[key]);
  };

  const updatedArray = stateArray.map((item) => {
    if (!item || compareKeys.some((key) => typeof item[key] === 'undefined')) return item;

    const newItem = newArray.find((newItem) => areItemsEqual(item, newItem));
    return newItem ? { ...item, ...newItem } : item;
  });

  const newUniqueItems = newArray.filter(
    (newItem) => newItem && !stateArray.some((item) => areItemsEqual(item, newItem)),
  );

  return [...updatedArray, ...newUniqueItems];
};

export const getMaxValueFromArray = (arr, key) => Math.max(0, ...arr.map((item) => item?.[key]));

export const compareArraysOfObjectsById = (arr1, arr2) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((item) => {
      const compareItem = arr2.find(
        (comparedItem) => isObject(comparedItem) && isObject(item) && comparedItem.id === item.id,
      );
      return compareItem && Object.keys(item).every((key) => item[key] === compareItem[key]);
    })
  );
};

export function sortItems(items, key, order = 'asc') {
  const modifier = order === 'asc' ? 1 : -1;
  return [...items].sort((a, b) => modifier * String(a[key]).localeCompare(String(b[key])));
}

export function isEqual(a, b) {
  if (typeof a !== typeof b) return false;

  if (typeof a === 'object' && typeof b === 'object') {
    const aType = isAnArray(a) ? 'array' : isObject(a) ? 'object' : isFunction(a) ? 'function' : undefined;
    const bType = isAnArray(b) ? 'array' : isObject(b) ? 'object' : isFunction(b) ? 'function' : undefined;

    if (aType !== bType) return false;

    if (aType === 'array') {
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; i++) {
        if (!isEqual(a[i], b[i])) return false;
      }
      return true;
    }

    if (aType === 'object') {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) return false;
      for (const key of aKeys) {
        if (!isEqual(a[key], b[key])) return false;
      }
      return true;
    }

    if (aType === 'function') {
      return a.toString() === b.toString();
    }
  }

  return a === b;
}

export function transformToISOFormat(date, withoutTime) {
  if (!date) return null;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedDate = formatInTimeZone(date, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");

  if (withoutTime) {
    return formattedDate.split('T')[0];
  } else {
    return formattedDate;
  }
}

export function transformToISOFormatWithoutTimeZoneCorrection(date) {
  if (!date) return null;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const receiveTimeWithoutTimeZoneCorrection = (time) => {
  const date = new Date(time);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  return utcDate;
};

export const formatISOToUSDate = (isoDate) => {
  const date = new Date(isoDate);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
};

export const parseTimeToISO = (timeString, dateFormat) => {
  if (!!timeString && timeString.length > 0) {
    return parse(timeString, dateFormat, new Date());
  }
  return null;
};

export const parseTimeToHours = (timeString, dateFormat) => {
  const dateObject = new Date(timeString);
  return format(dateObject, dateFormat);
};

export const ObjectKeysFromCamelCaseToSpaces = (obj) => {
  const transformedEntries = Object.entries(obj).map(([key, value]) => {
    const transformedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
    return [transformedKey, value];
  });

  return Object.fromEntries(transformedEntries);
};
