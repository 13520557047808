import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

import Calendar from '../../../assets/icons/Calendar';

import styles from './date-picker.module.scss';

const DatePicker = (props) => {
  const {
    errors = [],
    containerClassName,
    required,
    prefixIcon,
    prefixButtonCallback,
    suffixButtonCallback,
    suffixIcon,
    showCalendarIcon,
    ...rest
  } = props;

  const renderIcon = (icon, callback, styleClass) => {
    return (
      icon && (
        <div className={styles[styleClass]} onClick={callback}>
          {icon}
        </div>
      )
    );
  };

  return (
    <div className={clsx(styles.container, containerClassName)}>
      {props.label && (
        <label className={clsx(styles.label, required && styles.asterisk)} htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <div className={styles.input_container}>
        {renderIcon(prefixIcon, prefixButtonCallback, 'prefix_icon')}
        {showCalendarIcon
          ? renderIcon(<Calendar />, suffixButtonCallback, 'calendar_icon')
          : renderIcon(suffixIcon, suffixButtonCallback, 'suffix_icon')}
        <div className={styles.date_picker}>
          <ReactDatePicker showMonthDropdown={true} showYearDropdown={true} dropdownMode="select" {...rest} />
        </div>
      </div>
      {!!errors.length && (
        <div className={styles.error_container}>
          {errors.map((error, index) => (
            <div key={index} className={styles.error}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
