import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  fetchMenuItems,
  createMenuItem,
  removeMenuItem,
  updateExistingMenuItemStatusPOS,
  updateExistingMenuItemStatusWebsite,
} from '../../../../redux/menuItemsSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { fetchCategories } from '../../../../redux/categoriesSlice';
import { fetchTaxes } from '../../../../redux/taxesSlice';
import { fetchModifiers } from '../../../../redux/modifierSetsSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import { IoIosArrowDown } from 'react-icons/io';

import { useAppContext } from '../../../../context/context';
import usePagination from '../../business/order-types/usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Selector from '../../../../components//utilities/selector/selector';
import Search from '../../../../components//utilities/search/search';
import Button from '../../../../components/utilities/button/button';
import Pagination from '../../../../components/utilities/pagination/pagination';

import MenuItemsList from './lists/items-list';

import styles from './items.module.scss';

const PAGE_SIZE_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const DEFAULT_PAGINATION = {
  currentPage: 1,
  itemsPerPage: 5,
};

function Items() {
  const status = useSelector((state) => state.menu_items.status);
  const menu_items = useSelector((state) => state.menu_items.menu_items);
  const categories = useSelector((state) => state.categories.categories);
  const taxes = useSelector((state) => state.taxes.taxes);
  const modifierSets = useSelector((state) => state.modifierSets.modifierSets);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAppContext();

  const [filteredMenuItems, setFilteredMenuItems] = useState(menu_items);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const selectorOptions = useMemo(() => {
    return categories.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [categories]);

  // For delete all checked items
  const [selectedItemsIds, setSelectedItemsIds] = useState([]);

  const { page, perPage, isPrevActive, isNextActive, handleSelectPageCount, handleClickPrev, handleClickNext } =
    usePagination(filteredMenuItems, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

  const applyFilters = useCallback(() => {
    // Here should be array sorted by field "sort" but right now on database the value of field "sort is 0 for all items, so I use sort by field "id" to have the stable order of the items in the array
    //let filtered = [...menu_items].sort((a, b) => a.sort - b.sort);
    let filtered = [...menu_items].sort((a, b) => a.id - b.id);

    if (selectedCategory !== 0) {
      filtered = filtered.filter((item) => item.category_id === Number(selectedCategory));
    }

    if (searchTerm !== '') {
      filtered = filtered.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    setFilteredMenuItems(filtered);
  }, [menu_items, selectedCategory, searchTerm]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMenuItems(token));
      dispatch(fetchCategories(token));
      dispatch(fetchTaxes(token));
      dispatch(fetchModifiers({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    const sortedMenuItems = [...menu_items].sort((a, b) => a.id - b.id);
    setFilteredMenuItems(sortedMenuItems);
  }, [menu_items]);

  useEffect(() => {
    applyFilters();
  }, [menu_items, selectedCategory, searchTerm, applyFilters]);

  const handleAction = (message, status) => {
    dispatch(addNotification({ message, status }));
  };

  const handleSetResult = (term) => {
    setSearchTerm(term);
  };

  const handleFilterByCategory = (selectedValue) => {
    setSelectedCategory(Number(selectedValue));
  };

  const handleToggleActive = useCallback(
    (id, value, type) => {
      const status = value ? 'HideForIndefinitely' : 'UnHide';
      const status_website = value ? 'HideForIndefinitelyOnWebsite' : 'UnHideOnWebsite';

      if (type === 'pos') {
        dispatch(updateExistingMenuItemStatusPOS({ id: Number(id), status, token }));
      } else if (type === 'website') {
        dispatch(updateExistingMenuItemStatusWebsite({ id: Number(id), status: status_website, token }));
      }
    },
    [dispatch, token],
  );

  const handleAddNewItem = () => {
    navigate('/menu/items/new');
  };

  const handleCopyItem = (id) => {
    if (!id || !Array.isArray(filteredMenuItems) || !token) {
      handleAction(!id ? 'Invalid ID' : 'Missing data or token', 'failed');
      return;
    }

    const duplicatedItem = filteredMenuItems.find((item) => item.id === id);

    if (!duplicatedItem) {
      handleAction('No menu item found', 'failed');
      return;
    }

    const {
      active = true,
      category_id = 0,
      category_name = '',
      cost = 0,
      desc = null,
      kitchen_name = '',
      name,
      price = 0,
      image = null,
      price_type = 'Variable',
      product_code = '',
      quantity = 0,
      sku = '',
      tax_ids = [],
      variations_attributes = [],
      modifier_set_ids = [],
      hide_status = 'UnHide',
    } = duplicatedItem;

    const newDetails = {
      active,
      category_id,
      category_name,
      cost,
      desc,
      kitchen_name,
      name: `${name}1`,
      price,
      image,
      price_type,
      product_code,
      quantity,
      sku,
      tax_ids,
      modifier_set_ids,
      variations_attributes,
      hide_status,
    };

    dispatch(createMenuItem({ newDetails, token }));
    handleAction('Item was cloned successfully', 'succeeded');
  };

  const handleEditItem = (id) => {
    navigate(`/menu/items/${id}/edit`);
  };

  const handleDeleteItem = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-item',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this item?',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeMenuItem({ id, token }));
                dispatch(hideModal('modal-delete-item'));
              },
            },
            { title: 'Cancel', button_variant: 'secondary', onAction: () => dispatch(hideModal('modal-delete-item')) },
          ],
        },
      }),
    );
  };

  const handleDeleteAllSelected = () => {
    if (selectedItemsIds.length > 0) {
      selectedItemsIds.forEach((element) => {
        dispatch(removeMenuItem({ id: element, token }));
      });
      setSelectedItemsIds([]);
    }
  };

  const handleSearchClean = () => {
    setSearchTerm('');
  };

  return (
    <div className={styles.items}>
      <PageTitle name={'Items'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.role_selector}>
            <Selector
              icon={IoIosArrowDown}
              items={selectorOptions}
              additionalItems={[{ label: 'All', value: 0 }]}
              selectedItem={selectedCategory}
              onSelectItem={handleFilterByCategory}
            />
          </div>
          <div className={styles.search}>
            <Search
              placeholder="Search Item Name or SKU"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <Button className={styles.delete_button} title={'Delete Selected'} onClick={handleDeleteAllSelected} />
          <Button className={styles.add_button} title={'Add New Item'} onClick={handleAddNewItem} />
        </div>
      </div>
      <MenuItemsList
        items={filteredMenuItems}
        categories={categories}
        taxes={taxes}
        modifiers={modifierSets}
        onCopy={handleCopyItem}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        onToggleActive={handleToggleActive}
        selectedItemsIds={selectedItemsIds}
        setSelectedItemsIds={setSelectedItemsIds}
        isLoading={status === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        numberOfItems={menu_items.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
        style={{ marginTop: '14px' }}
      />
    </div>
  );
}

export default Items;
