import React, { useState } from 'react';
import styles from './kitchen-report.module.scss';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import { ReportFilterPanel, DropdownTypes } from '../components/report-filter-panel';
import { DateRangeFilter } from '../components/date-range';
import ReportTable from './report-table';

function KitchenReport() {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const orders = [
    {
      id: 1,
      employee: { full_name: 'John Doe' },
      terminal: { name: 'Terminal 1' },
      created_at: '2023-10-01T12:00:00Z',
      kitchen_completion_time: '2023-10-01T12:00:00Z',
    },
    {
      id: 2,
      employee: { full_name: 'Jane Doe' },
      terminal: { name: 'Terminal 2' },
      created_at: '2023-10-02T12:00:00Z',
      kitchen_completion_time: '2023-10-02T12:00:00Z',
    },
  ];

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Kitchen Report'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel dropdown={[DropdownTypes.employee, DropdownTypes.terminal_id]} />
      </div>
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter endDate={endDate} startDate={startDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      </div>
      <div className="bg-white table-responsive mt-4">
        <ReportTable orders={orders} />
      </div>
    </div>
  );
}

export default KitchenReport;
