import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchTips,
  addTip,
  updateExistingTip,
  removeTip,
  updateExistingTipStatus,
  reorderTips,
} from '../../../../redux/tipsSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import { sortItems } from '../../../utilities/utils';

import TipsList from './tips-list';
import PageTitle from '../../../utilities/page-title/page-title';
import Button from '../../../utilities/button/button';
import Input from '../../../utilities/input/input';

import styles from './tips.module.scss';

function Tips() {
  const dispatch = useDispatch();

  const tips = useSelector((state) => state.tips.tips);
  const tipsStatus = useSelector((state) => state.tips.status);

  const { token, locationId } = useAppContext();

  const [newTip, setNewTip] = useState('');
  const [newTipPercent, setNewTipPercent] = useState('');
  const [editedTips, setEditedTips] = useState({});

  const { errors, validate } = useFormValidation(registrationSchema);

  useEffect(() => {
    if (token) {
      dispatch(fetchTips(token));
    }
  }, [dispatch, token]);

  const handleInputChange = useCallback(
    (id, field, value) => {
      const existingTip = tips.find((tip) => tip.id === id);

      if (existingTip) {
        setEditedTips((prev) => ({
          ...prev,
          [id]: {
            ...existingTip,
            ...prev[id],
            [field]: value,
          },
        }));
      }
    },
    [tips],
  );

  const handleAdd = async () => {
    const largestId = tips.reduce((max, tip) => (tip.id > max ? tip.id : max), 0);
    const newDetails = {
      id: largestId + 1,
      is_active: true,
      location_id: locationId,
      name: newTip,
      rate: newTipPercent,
    };

    if (validate(newDetails)) {
      try {
        await dispatch(addTip({ newDetails, token })).unwrap();
        setNewTip('');
        setNewTipPercent('');
      } catch {}
    }
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-tip',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this tip?',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeTip({ id, token }));
                  dispatch(hideModal('modal-delete-tip'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'secondary',
                onAction: () => dispatch(hideModal('modal-delete-tip')),
              },
            ],
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const handleUpdate = async () => {
    try {
      await Promise.all(
        Object.entries(editedTips).map(([id, updatedDetails]) =>
          dispatch(updateExistingTip({ id, updatedDetails, token })),
        ),
      );
      setEditedTips({});
    } catch (error) {
      console.error('Error updating tips:', error);
    }
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingTipStatus({ id, is_active: value, token }));
    },
    [dispatch, token],
  );

  const handleReorder = (item) => {
    const { sourceId, index } = item;

    const old_position = tips.find((reason) => reason.id === sourceId)?.sort;
    const new_position = sortItems(tips, 'sort')[index]?.sort;

    if (old_position !== new_position) {
      dispatch(reorderTips({ id: sourceId, updatedDetails: { old_position, new_position }, token }));
    }
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Tips'} />
      </div>
      <div className={styles.content}>
        <div className={styles.add_tip}>
          <div className={styles.section}>
            <Input
              label={'Tip Name'}
              value={newTip}
              onChange={(e) => setNewTip(e.target.value)}
              placeholder={'Tip Name'}
              required={true}
              className={styles.tip_name_input}
              errors={errors.name?._errors}
            />
          </div>
          <div className={styles.section}>
            <Input
              label={'Tip %'}
              type="number"
              value={newTipPercent}
              onChange={(e) => setNewTipPercent(e.target.value)}
              placeholder={'0.00%'}
              required={true}
              className={styles.tip_percent_input}
              errors={errors.rate?._errors}
            />
          </div>
          <Button className={styles.add_button} title={'Add Tips'} onClick={handleAdd} />
        </div>

        <TipsList
          items={tips}
          onEdit={handleInputChange}
          onDelete={handleDelete}
          onDrag={handleReorder}
          onToggleActive={handleToggleActive}
          isLoading={tipsStatus === 'loading'}
          className={styles.tips_list}
        />
        <Button className={styles.update_button} title={'Update'} onClick={handleUpdate} />
      </div>
    </div>
  );
}

export default Tips;
