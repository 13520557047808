import React from 'react';
import { displayTwoDigitWithDelimiter } from '../components/helper';

const DiscountMetricsHeader = () => {
  const discountRecords = {
    discount_on_orders: 12,
    discount_on_orders_count: 10,
    discount_on_items: 150,
    discount_on_items_count: 10,
    discount_on_total: 140,
    discount_on_total_count: 10,
  };

  return (
    <div className="row bg-light">
      <div className="col-lg-4 col-md-6 text-center">
        <p className="text-gray fs-12">Discount on Orders</p>
        <h3 className="fs-18 my-0">${displayTwoDigitWithDelimiter(discountRecords.discount_on_orders)}</h3>
        <p className="text-gray fs-12">{discountRecords.discount_on_orders_count} discounts applied</p>
      </div>
      <div className="col-lg-4 col-md-6 text-center mt-3 mt-md-0">
        <p className="text-gray fs-12">Discount on Items</p>
        <h3 className="fs-18 my-0">${displayTwoDigitWithDelimiter(discountRecords.discount_on_items)}</h3>
        <p className="text-gray fs-12">{discountRecords.discount_on_items_count} discounts applied</p>
      </div>
      <div className="col-lg-4 col-md-6 text-center mt-3 mt-lg-0">
        <p className="text-gray fs-12">Total Discounts</p>
        <h3 className="fs-18 my-0">${displayTwoDigitWithDelimiter(discountRecords.discount_on_total)}</h3>
        <p className="text-gray fs-12">{discountRecords.discount_on_total_count} discounts applied</p>
      </div>
    </div>
  );
};

export default DiscountMetricsHeader;
