import React from 'react';

const OtherDetails = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    other_details: [
      {
        key: 'Key 1',
        value: 100,
      },
      {
        key: 'Key 2',
        value: 200,
      },
      {
        key: 'Key 3',
        value: 300,
      },
    ],
  };

  const displayTwoDigitWithDelimiter = (value) => {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  return (
    <div>
      <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        OTHER DETAILS
      </h4>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th width="25%">Detail</th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%">Amount</th>
            </tr>
          </thead>
          <tbody className="eod_shift_report">
            {shiftReportData.other_details && shiftReportData.other_details.length > 0 ? (
              shiftReportData.other_details.map((data, index) => (
                <tr key={index}>
                  <td>{data.key}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>${displayTwoDigitWithDelimiter(data.value)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="4">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OtherDetails;
