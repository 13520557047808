import { format } from 'date-fns';

import styles from '../employees.module.scss';

const DATE_FROMAT = "MMMM d, yyyy 'at' hh:mm a";

const formSchema = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email Address',
  phone_number: 'Mobile Number',
  passcode: 'Passcode',
  hourly_wages: 'Hourly Rate',
  team_role_id: 'Role',
};

const EmployeeInfoForm = ({ employee, roles }) => {
  const dataTranform = (obj, key) => {
    if (key === 'hourly_wages') {
      return `$ ${obj[key]}`;
    }

    if (key === 'team_role_id') {
      return roles?.find((role) => role.value === obj[key]).label || key;
    }

    return obj[key];
  };

  return (
    <div className={styles.form_section}>
      <div className={styles.primary_info}>
        <h5>{employee.full_name}</h5>
        <p>Created: {format(employee.created_at, DATE_FROMAT)}</p>
        <p>Last Edited: {format(employee.updated_at, DATE_FROMAT)}</p>
      </div>
      <div className={styles.additional_info}>
        {Object.entries(formSchema).map(([key, value]) => {
          if (employee[key]) {
            return (
              <div className={styles.info_row} key={key}>
                <p className={styles.type}>{value}</p>
                <p className={styles.info_value}>{dataTranform(employee, key)}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default EmployeeInfoForm;
