import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../../../context/context';

import {
  createCategory,
  updateExistingCategory,
  removeCategory,
  updateExistingCategoryStatus,
  updateExistingCategoryOrder,
} from '../../../../../redux/categoriesSlice';

import { addNotification } from '../../../../../redux/tooltipSlice';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import { isAnArray, sortItems } from '../../../../utilities/utils';

import useFormValidation from '../../../../utilities/useFormValidation';
import { registrationSchema } from '../validation';

const useCategoryManager = (categories) => {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const initialState = {
    modalState: {
      isEditModalOpen: false,
      isEditMode: false,
    },
    currentCategoryId: null,
    category: {},
  };

  const [state, setState] = useState(initialState);
  const { errors, validate, resetErrors } = useFormValidation(registrationSchema);

  useEffect(() => {
    if (state.currentCategoryId) {
      const foundCategory = getCategory(categories, state.currentCategoryId);
      setState((prev) => ({ ...prev, category: foundCategory }));
    }
  }, [categories, state.currentCategoryId]);

  const handleAction = (message, status) => {
    dispatch(addNotification({ message, status }));
  };

  const getCategory = (categories, id) => {
    if (!id || !isAnArray(categories)) {
      handleAction('Invalid ID or category is not an array', 'failed');
      return;
    }

    const category = categories.find((item) => item.id === parseFloat(id));

    if (!category) {
      handleAction('No category found', 'failed');
      return;
    }

    return category;
  };

  // Modal state handlers

  const handleShowEditModal = (id) => {
    const category = getCategory(categories, id);

    category &&
      setState({
        ...initialState,
        modalState: {
          isEditModalOpen: true,
          isEditMode: true,
        },
        category,
      });
  };

  const handleShowAddModal = () => {
    setState({
      ...initialState,
      modalState: {
        isEditModalOpen: true,
      },
      category: {},
    });
  };

  const handleModalClose = () => {
    setState(initialState);
    resetErrors();
  };

  // Category state handlers
  const handleAddNewCategory = async (newCategory) => {
    const newCategoryDetails = {
      name: newCategory?.name ?? '',
      id: 999,
      active: true,
      location_id: locationId,
      item_ids: [],
      image: '',
    };

    if (validate(newCategoryDetails, 'new')) {
      try {
        await dispatch(createCategory({ newDetails: newCategoryDetails, token })).unwrap();
        handleModalClose();
      } catch {}
    }
  };

  const handleCopyCategoty = async (id) => {
    const duplicatedCategory = getCategory(categories, id);

    const newCategoryDetails = {
      name: duplicatedCategory.name ? duplicatedCategory.name + 1 : '',
      id: 999,
      active: duplicatedCategory?.active ?? false,
      location_id: locationId,
      item_ids: duplicatedCategory?.item_ids ?? [],
      image: duplicatedCategory?.image ?? '',
    };

    if (!token) {
      handleAction('The token is missing', 'failed');
      return;
    }
    try {
      await dispatch(createCategory({ newDetails: newCategoryDetails, token })).unwrap();
      handleModalClose();
    } catch {}
  };

  const handleUpdateCategory = async (updatedCategory) => {
    const updatedDetails = {
      name: updatedCategory.name,
      id: updatedCategory?.id ?? 999,
      active: updatedCategory?.active ?? false,
      location_id: locationId,
      item_ids: updatedCategory?.item_ids ?? [],
      image: updatedCategory?.image ?? '',
    };

    if (validate(updatedDetails, 'edit')) {
      try {
        await dispatch(updateExistingCategory({ id: updatedCategory?.id, updatedDetails, token })).unwrap();
        handleModalClose();
      } catch {}
    }
  };

  const handleSaveCategory = async () => {
    try {
      state.modalState.isEditMode ? handleUpdateCategory(state.category) : handleAddNewCategory(state.category);

      handleModalClose();
    } catch (error) {
      console.error('Failed to save category:', error);
    }
  };

  const handleDeleteCategory = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-category',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this category?',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeCategory({ id, token }));
                dispatch(hideModal('modal-delete-category'));
              },
            },
            {
              title: 'Cancel',
              button_variant: 'secondary',
              onAction: () => dispatch(hideModal('modal-delete-category')),
            },
          ],
        },
      }),
    );
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingCategoryStatus({ id, is_active: value, token }));
    },
    [dispatch, token],
  );

  const handleReorderCategories = useCallback(
    (item) => {
      const { sourceId, index } = item;

      const old_position = categories.find((reason) => reason.id === sourceId)?.sort;
      const new_position = sortItems(categories, 'sort')[index]?.sort;

      if (old_position !== new_position) {
        dispatch(updateExistingCategoryOrder({ id: sourceId, updatedDetails: { old_position, new_position }, token }));
      }
    },
    [categories, dispatch, token],
  );

  return {
    category: state.category,
    setCategory: setState,
    isEditModalOpen: state.modalState.isEditModalOpen,
    isEditMode: state.modalState.isEditMode,
    handleCopyCategoty,
    handleDeleteCategory,
    handleSaveCategory,
    handleReorderCategories,
    handleToggleActive,
    handleShowAddModal,
    handleShowEditModal,
    handleModalClose,
    errors,
  };
};

export default useCategoryManager;
