import React from 'react';
import { Link } from 'react-router-dom';
import { displayTwoDigitWithDelimiter, preetyDate } from '../components/helper';
import { TaxesHelper, TaxType } from '../components/TaxesHelper';
import ViewDate from '../components/view-date';

const DeletedTransactionDetails = () => {
  const { payment, currentLocation } = {
    payment: {
      id: 1,
      order_id: 1,
      created_at: '2021-07-01T00:00:00Z',
      payment_type: 'Cash',
      amount: 100,
      tips: 10,
      tax_amount: 5,
      service_charge_amount: 0,
      payment_refunds: [
        {
          id: 1,
          created_at: '2021-07-01T00:00:00Z',
          amount: 10,
          tax_refunded: 5,
          tips_refunded: 0,
          refund_response: 'Refunded',
        },
      ],
      employee: {
        full_name: 'John Doe',
      },
      card_number: '123456789',
      display_card_name: 'Visa',
    },
    currentLocation: { id: 1 },
  };

  const order = {
    id: 1,
    offline_id: '123456',
    order_item_taxes: [
      {
        id: 1,
        name: 'Tax 1',
        rate: 10,
        tax_type: 'PERCENTAGE',
        tax_total_amount: 5,
      },
      {
        id: 2,
        name: 'Tax 2',
        rate: 5,
        tax_type: 'DOLLAR',
        tax_total_amount: 5,
      },
    ],
  };

  const orderItemTaxes = order.order_item_taxes;
  const paymentRefundObjs = payment.payment_refunds;
  const teamMember = payment.employee;

  const getTaxType = (orderTax) => {
    return TaxesHelper.getTaxType(orderTax);
  };

  const getOrderID = (location, order) => {
    const orderNumberStartingFromOne = true;
    return orderNumberStartingFromOne ? order.id : order.offline_id;
  };

  return (
    <div className="content-wrapper pl-6">
      <div className="row">
        <div className="col-xl-10 col-lg-9 pl-3 pl-lg-0">
          <div className="content">
            <h2 className="admin-main-title text-darkgray1 fw-600 fs-20 border-bottom mb-2 pb-3">
              Transaction - Details
            </h2>
            <Link to="/reporting/deleted-transactions" className="back-area box-shadow-0 p-0 text-blue1 fs-14">
              <i className="fa fa-angle-left mr-1"></i> Back to Deleted Transactions
            </Link>
            <div className="row order-card">
              <div className="col-xl-9">
                <div className="border-bottom mb-3 mt-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="fs-18 pb-2">Taxes Rate & Fees</p>
                    {orderItemTaxes && orderItemTaxes.length > 0 && (
                      <p className="fs-16 pb-2">
                        Taxes and Fees Total: $
                        {displayTwoDigitWithDelimiter(orderItemTaxes.reduce((sum, t) => sum + t.tax_total_amount, 0))}
                      </p>
                    )}
                  </div>
                </div>
                <div className="admin-table table-responsive mb-4">
                  <table className="table table-bordered bg-white mb-0" style={{ minWidth: '700px' }}>
                    <thead className="thead-dark">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Tax Rates & Fees</th>
                        <th>Taxable Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItemTaxes && orderItemTaxes.length > 0 ? (
                        orderItemTaxes.map((orderTaxes) => {
                          const orderTax = orderTaxes;
                          return (
                            <tr key={orderTax.id}>
                              <td className="text-left">{orderTax.id}</td>
                              <td className="text-left">{orderTax.name}</td>
                              <td className="text-left">
                                {orderTax.tax_type === TaxType.PERCENTAGE ? (
                                  <>
                                    {displayTwoDigitWithDelimiter(orderTax.rate)} {getTaxType(orderTax)}
                                  </>
                                ) : (
                                  <>
                                    {getTaxType(orderTax)}
                                    {displayTwoDigitWithDelimiter(orderTax.rate)}
                                  </>
                                )}
                              </td>
                              <td className="text-left">
                                $
                                {displayTwoDigitWithDelimiter(
                                  orderItemTaxes.reduce((sum, t) => sum + t.tax_total_amount, 0),
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-left text-gray">
                            No Taxes applied.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="border-bottom mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="fs-18 pb-2">Refunds</p>
                    <p className="fs-16 pb-2">
                      Refunds Total: $
                      {displayTwoDigitWithDelimiter(paymentRefundObjs.reduce((sum, obj) => sum + obj.amount, 0))}
                    </p>
                  </div>
                </div>
                <div className="admin-table table-responsive mb-4">
                  <table className="table table-bordered bg-white mb-0" style={{ minWidth: '700px' }}>
                    <thead className="thead-dark">
                      <tr>
                        <th>Created</th>
                        <th>Refund Amount</th>
                        <th>Tax or Fee Refunded</th>
                        <th>Tip Amount</th>
                        <th>Refund Response</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentRefundObjs && paymentRefundObjs.length > 0 ? (
                        paymentRefundObjs.map((paymentObj) => (
                          <tr key={paymentObj.id}>
                            <td>{preetyDate(paymentObj.created_at)}</td>
                            <td>${displayTwoDigitWithDelimiter(paymentObj.amount)}</td>
                            <td>${displayTwoDigitWithDelimiter(paymentObj.tax_refunded)}</td>
                            <td>${displayTwoDigitWithDelimiter(paymentObj.tips_refunded)}</td>
                            <td>{paymentObj.refund_response}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-left text-center text-gray">
                            No Refunds
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="border-bottom mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="fs-18 pb-2">Wastage</p>
                    <p className="fs-16 pb-2">Wastage Total: $0.00</p>
                  </div>
                </div>
                <div className="admin-table table-responsive mb-4">
                  <table className="table table-bordered bg-white mb-0" style={{ minWidth: '700px' }}>
                    <thead className="thead-dark">
                      <tr>
                        <th>Created</th>
                        <th>Wastage Amount</th>
                        <th>Items</th>
                        <th>Wastage Reason</th>
                        <th>Employee</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="5" className="text-left text-center text-gray">
                          No Wastage
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-xl-3 mt-5">
                <div className="box-body admin-box border-box p-0 mb-3">
                  <div className="bg-light text-uppercase fs-16 p-2 fw-500 text-center">Payment Details</div>
                  <div className="p-3">
                    <p className="fs-14 mb-2">
                      <strong>Transaction ID:</strong>
                      <span>{payment.id}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Order ID:</strong>
                      <span>{getOrderID(currentLocation, order)}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Receipt ID:</strong>
                      <span>{order.offline_id}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Created:</strong>
                      <span>
                        <ViewDate date={payment.created_at} />
                      </span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Tender:</strong>
                      <span>{payment.payment_type}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Status:</strong>
                      <span>{order.payment_status}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Payment Amount:</strong>
                      <span>${displayTwoDigitWithDelimiter(payment.amount + payment.tips)}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Tax Amount:</strong>
                      <span>${displayTwoDigitWithDelimiter(payment.tax_amount)}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Tip Amount:</strong>
                      <span>${displayTwoDigitWithDelimiter(payment.tips)}</span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Service Charge Amount:</strong>
                      <span>${displayTwoDigitWithDelimiter(payment.service_charge_amount)}</span>
                    </p>
                    {teamMember && (
                      <p className="fs-14 mb-2">
                        <strong>Employee:</strong>
                        <span>{teamMember.full_name}</span>
                      </p>
                    )}
                    <p>
                      <Link to={order.short_receipt_url} className="fs-14 change-default text-blue1" target="_blank">
                        <i className="fas fa-file-alt mr-2"></i>View Receipt
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="box-body admin-box border-box p-0 mb-3">
                  <div className="bg-light text-uppercase fs-16 p-2 fw-500 text-center">
                    Card Transaction Information
                  </div>
                  <div className="p-3">
                    <p className="fs-14 mb-2">
                      <strong>Card Name:</strong>
                      <span>
                        {payment.display_card_name} - {payment.card_number.replace(/\*/g, '').slice(-4)}
                      </span>
                    </p>
                    <p className="fs-14 mb-2">
                      <strong>Type:</strong>
                      <span>{payment.display_card_name}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletedTransactionDetails;
