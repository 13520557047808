import clsx from 'clsx';
import styles from './button.module.scss';

const Button = ({ title, type, onClick, disabled, variant, style, className, prefixIcon, suffixIcon }) => {
  const renderIcon = (icon, styleClass) => {
    return icon && <div className={styles[styleClass]}>{icon}</div>;
  };

  return (
    <div className={clsx(styles.container, className)}>
      {renderIcon(prefixIcon, 'prefix_icon')}
      {renderIcon(suffixIcon, 'suffix_icon')}
      <button onClick={onClick} type={type} disabled={disabled} className={styles[`variant_${variant}`]} style={style}>
        {title}
      </button>
    </div>
  );
};
export default Button;
