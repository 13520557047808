import React from 'react';

const ClockInClockOut = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    clock_in_clock_out: [
      [
        {
          key: 'Employee Name',
          value: 'Employee 1',
        },
        {
          key: 'Clock In',
          value: '2021-07-01T00:00:00Z',
        },
        {
          key: 'Clock Out',
          value: '2021-07-01T00:00:00Z',
        },
        {
          key: 'Actual In Time',
          value: '2021-07-01T00:00:00Z',
        },
      ],
    ],
  };
  return (
    <div>
      <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        CLOCK IN - CLOCK OUT
      </h4>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th width="30%" colSpan="2">
                Employee Name
              </th>
              <th width="30%" colSpan="2">
                Clock In
              </th>
              <th width="30%" colSpan="2">
                Clock Out
              </th>
              <th width="10%">Actual In Time</th>
            </tr>
          </thead>
          <tbody className="eod_shift_report">
            {shiftReportData.clock_in_clock_out && shiftReportData.clock_in_clock_out.length > 0 ? (
              shiftReportData.clock_in_clock_out.map((data, index) => (
                <tr key={index}>
                  <td colSpan="2">{data[0].value}</td>
                  <td colSpan="2">{data[1].value}</td>
                  <td colSpan="2">{data[2].value}</td>
                  <td>{data[3].value}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClockInClockOut;
