import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import HeaderReport from '../components/HeaderReport/header-report';
import { NoRecords } from '../components/NoRecords';
import DateRangeFilter from '../components/date-range/date-range';
import ViewDate from '../components/view-date';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './batch-close-report.module.scss';

function BatchCloseReport() {
  const navigate = useNavigate();
  const reportTitle = 'Batch Close Report';

  const orderNumberStartingFromOne = true;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const items = [
    {
      created_at: '2021-01-01T10:00:00',
      transaction_count: 1,
      captured_transaction_count: 1,
      amount: 100,
      captured_amount: 100,
    },
    {
      created_at: '2021-01-02T10:00:00',
      transaction_count: 2,
      captured_transaction_count: 2,
      amount: 200,
      captured_amount: 200,
    },
  ];

  const handleOpenDetails = () => {
    navigate(`/reporting/detail_batch_close_report/${endDate}/${startDate}`);
  };

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const tableItem = (item) => {
    return (
      <tr>
        <td className="text-left">
          <ViewDate date={item.created_at} />
        </td>
        <td className="text-left">{item.transaction_count}</td>
        <td className="text-left">{item.captured_transaction_count}</td>
        <td>${item.amount}</td>
        <td>${item.captured_amount}</td>
        <td>
          <a className="mr-3 mr-lg-4 fs-14" onClick={handleOpenDetails}>
            Details
          </a>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={reportTitle} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
      </div>
      <div className="row">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Date</th>
              <th>Transaction Count</th>
              <th>Captured Transaction Count</th>
              <th>Amount</th>
              <th>Captured Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          {items && items.length > 0 ? items.map((item) => tableItem(item)) : <NoRecords />}
        </table>
      </div>
    </div>
  );
}

export default BatchCloseReport;
