import { api, handleApiResponse } from './base';

export const getEmployeesTimesheet = (token, start_date, end_date, team_role_id, employee_id) => {
  let url = `employees_timesheet?start_date=${start_date}&end_date=${end_date}`;

  if (team_role_id !== null && team_role_id !== undefined) {
    url += `&team_role_id=${team_role_id}`;
  }
  if (employee_id !== null && employee_id !== undefined) {
    url += `&employee_id=${employee_id}`;
  }

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getEmployeeTimesheetDetails = (token, start_date, end_date, employee_id) => {
  let url = `employees_timesheet/details?start_date=${start_date}&end_date=${end_date}`;

  if (employee_id !== null && employee_id !== undefined) {
    url += `&employee_id=${employee_id}`;
  }

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const updateEmployeeTimesheetDetails = (updatedDetails, token) => {
  return handleApiResponse(() =>
    api.put(`employees_timesheet/details`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );
};
