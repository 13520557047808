import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  fetchCategories,
  updateExistingCategory,
  removeCategory,
  updateExistingCategoryStatus,
} from '../../../../redux/categoriesSlice';

import { RxCross2 } from 'react-icons/rx';

import { useAppContext } from '../../../../context/context';

import Modal from '../../../../components/utilities/modal/modal';
import PageTitle from '../../../../components/utilities/page-title/page-title';
import Search from '../../../../components//utilities/search/search';
import Button from '../../../../components/utilities/button/button';
import Checkbox from '../../../../components/utilities/checkbox/checkbox';

import CategoriesList from './hidden-category-list';

import styles from './hidden-categories.module.scss';

function HiddenCategories() {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);

  const status = useSelector((state) => state.categories.status);
  const categories = useSelector((state) => state.categories.categories);

  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const [newCategoryName, setNewCategoryName] = useState('');
  const [editableCategoryId, setEditableCategoryId] = useState(null);
  const [editableCategoryName, setEditableCategoryName] = useState('');

  const [isEditCategoryModal, setIsEditCategoryModal] = useState(false);
  const [isAssignItemsModal, setIsAssignItemsModal] = useState(false);

  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermAssignModal, setSearchTermAssignModal] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('null');

  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  const [selectedModalItems, setSelectedModalItems] = useState([]);

  const [selectAll, setSelectAll] = useState({
    id: 0,
    name: 'Select All',
    status: false,
  });

  const [MockDataForMenuItems, setMockDataForMenuItems] = useState([
    { id: 1, name: 'Sell Card', status: true },
    { id: 2, name: 'Add Value', status: true },
    { id: 3, name: 'Balance Inquiry', status: false },
  ]);

  const [filteredAssgnModalItems, setFilteredAssgnModalItems] = useState(MockDataForMenuItems);

  const applyFilters = useCallback(() => {
    let filtered;

    if (categories.length > 0) {
      filtered = categories.filter((category) => !category.active);
    }

    if (searchTerm !== '') {
      filtered = filtered.filter((category) => category.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    setFilteredCategories(filtered);
  }, [categories, selectedStatus, searchTerm]);

  const applyAssignModalFilters = useCallback(() => {
    let filtered = MockDataForMenuItems;

    if (searchTermAssignModal !== '') {
      filtered = filtered.filter((item) => item.name.toLowerCase().includes(searchTermAssignModal.toLowerCase()));
    }

    setFilteredAssgnModalItems(filtered);
  }, [MockDataForMenuItems, searchTermAssignModal]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  useEffect(() => {
    setFilteredAssgnModalItems(MockDataForMenuItems);
  }, [MockDataForMenuItems]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories(token));
    }
  }, [dispatch, isInitialized, token]);

  useEffect(() => {
    applyFilters();
  }, [categories, selectedStatus, searchTerm, applyFilters]);

  useEffect(() => {
    applyAssignModalFilters();
  }, [MockDataForMenuItems, searchTermAssignModal, applyAssignModalFilters]);

  const handleSetResult = (term) => {
    setSearchTerm(term);
  };

  const handleSetAssignModalResult = (term) => {
    setSearchTermAssignModal(term);
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingCategoryStatus({ id, is_active: value, token }));
    },
    [dispatch, token],
  );

  const handleEditCategory = (id) => {
    if (!id || !Array.isArray(categories)) {
      console.error('Invalid ID or category is not an array.');
      return;
    }
    setEditableCategoryId(id);

    const editableCategory = categories.find((item) => item.id === id);

    if (!id) {
      console.warn('No category found.');
      return;
    }

    setEditableCategoryName(editableCategory.name ? editableCategory.name : '');
    setIsEditCategoryModal(true);
  };

  const handleDeleteCategory = (id) => {
    dispatch(removeCategory({ id, token }));
  };

  const handleDeleteAllSelected = () => {
    if (selectedCategoriesIds.length > 0) {
      selectedCategoriesIds.forEach((element) => {
        dispatch(removeCategory({ id: element, token }));
      });
    }
  };

  const handleClickAssignItemsLink = useCallback((id) => {
    setIsAssignItemsModal(true);
    setCurrentCategoryId(id);
  }, []);

  const handleSearchClean = () => {
    setSearchTerm('');
  };

  const handleSearchAssignModalClean = () => {
    setSearchTermAssignModal('');
  };

  const handleUpdateCategory = () => {
    if (!editableCategoryId || !Array.isArray(categories)) {
      console.error('Invalid ID or category is not an array.');
      return;
    }

    const editableCategory = categories.find((item) => item.id === editableCategoryId);

    if (!editableCategory) {
      console.warn('No category found.');
      return;
    }

    const updatedDetails = {
      name: editableCategoryName ? editableCategoryName : editableCategory.name,
      id: editableCategory.id ? editableCategory.id : 999,
      active: editableCategory.active ? editableCategory.active : false,
      location_id: editableCategory.location_id ? editableCategory.location_id : locationId,
      item_ids: editableCategory.item_ids ? editableCategory.item_ids : [],
      image: editableCategory.image ? editableCategory.image : '',
    };

    dispatch(updateExistingCategory({ id: editableCategoryId, updatedDetails, token }));
    setIsEditCategoryModal(false);
  };

  const handleCloseModal = useCallback((modalType) => {
    switch (modalType) {
      case 'edit_category':
        setIsEditCategoryModal(false);
        break;
      case 'assign_items':
        setIsAssignItemsModal(false);
        break;
      default:
        console.warn(`Unknown modal type: ${modalType}`);
    }
  }, []);

  const handleAssignModalAll = (id, isChecked) => {
    setSelectAll((prev) => {
      return { ...prev, status: isChecked };
    });

    if (isChecked) {
      const allItemIds = MockDataForMenuItems.map((item) => item.id);
      setSelectedModalItems(allItemIds);
    } else {
      setSelectedModalItems([]);
    }
  };

  const handleAssignModalCheckboxChange = (id, isChecked) => {
    setSelectedModalItems((prev) => {
      let updatedItems;

      if (isChecked) {
        updatedItems = [...prev, id];
      } else {
        updatedItems = prev.filter((item) => item !== id);
      }

      setSelectAll({
        ...selectAll,
        status: updatedItems.length === MockDataForMenuItems.length,
      });

      return updatedItems;
    });
  };

  return (
    <>
      <PageTitle name={'Hidden Categories'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.search}>
            <Search
              placeholder="Search Category Name"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <Button className={styles.delete_button} title={'Delete Selected'} onClick={handleDeleteAllSelected} />
        </div>
      </div>
      <CategoriesList
        categories={filteredCategories}
        onEdit={handleEditCategory}
        onDelete={handleDeleteCategory}
        onToggleActive={handleToggleActive}
        onAssignItemsClick={handleClickAssignItemsLink}
        selectedCategoriesIds={selectedCategoriesIds}
        setSelectedCategoriesIds={setSelectedCategoriesIds}
        isLoading={status === 'loading'}
      />
      <Modal
        isOpen={isEditCategoryModal}
        onClose={() => handleCloseModal('edit_category')}
        className={styles.edit_category_modal}
      >
        <div className={styles.modal_content}>
          <div className={styles.modal_header}>
            <h3 className={styles.modal_title}>Assign Modules</h3>
            <div className={styles.cross_icon} onClick={() => handleCloseModal('edit_category')}>
              <RxCross2 />
            </div>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.edit_category_name}>
              <label>
                Name<span>*</span>
              </label>
              <input
                type="text"
                required
                value={editableCategoryName}
                placeholder="New name"
                onChange={(e) => setEditableCategoryName(e.target.value)}
                style={{ color: '#959A9E' }}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <button type="button" className={styles.update_button} onClick={handleUpdateCategory}>
              Update
            </button>
            <button type="button" className={styles.cancel_button} onClick={() => setIsEditCategoryModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isAssignItemsModal}
        onClose={() => handleCloseModal('assign_items')}
        className={styles.assign_items_modal}
      >
        <div className={styles.modal_content}>
          <div className={styles.modal_header}>
            <h3 className={styles.modal_title}>Apply Items to Category</h3>
            <div className={styles.cross_icon} onClick={() => handleCloseModal('assign_items')}>
              <RxCross2 />
            </div>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.search}>
              <Search
                placeholder="Search Items"
                onSearchResults={handleSetAssignModalResult}
                onClear={handleSearchAssignModalClean}
                autoFilter={false}
              />
            </div>
            <div className={styles.menu_items}>
              <div className={styles.menu_select_all}>
                <Checkbox item={selectAll} handleOnChange={handleAssignModalAll} isChecked={selectAll.status} />
                <span className={styles.menu_item_name}>Select All</span>
              </div>
              <div className={styles.menu_list_of_items}>
                {filteredAssgnModalItems.map((item) => (
                  <div key={item.id} className={styles.menu_item}>
                    <Checkbox
                      item={item}
                      handleOnChange={handleAssignModalCheckboxChange}
                      isChecked={selectedModalItems.includes(item.id)}
                    />
                    <span className={styles.menu_item_name}>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <button type="button" className={styles.update_button} onClick={handleUpdateCategory}>
              Apply to Category
            </button>
            <button type="button" className={styles.cancel_button} onClick={() => setIsAssignItemsModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default HiddenCategories;
