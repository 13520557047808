import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchReportingEmails,
  createReportingEmail,
  updateExistingReportingEmail,
  removeReportingEmail,
} from '../../../../redux/reportingSlice';
import { addNotification } from '../../../../redux/tooltipSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';
import { fetchLocations, updateExistingDailyReportTime } from '../../../../redux/locationSlice';

import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../utilities/page-title/page-title';
import DatePicker from '../../../utilities/date-picker/date-picker';
import useFormValidation from '../../../utilities/useFormValidation';

import { parseTimeToISO, parseTimeToHours } from '../../../utilities/utils';

import Button from '../../../utilities/button/button';
import Input from '../../../utilities/input/input';
import { Subtitle } from '../../../utilities';
import EmailAddressesList from './reporting-list';
import { registrationSchema } from './validation';

import styles from './reporting.module.scss';

const DATE_FORMAT = 'p';

function BusinessReporting() {
  const status = useSelector((state) => state.business_reporting.status);
  const reporting_emails = useSelector((state) => state.business_reporting.reporting_emails);
  const locations = useSelector((state) => state.locations.locations);

  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const [newEmail, setNewEmail] = useState('');
  const [changedEmails, setChangedEmails] = useState({});
  const [selectedReportTime, setSelectedReportTime] = useState('');
  const [flag, setFlag] = useState(false);

  const { errors, validate } = useFormValidation(registrationSchema);

  useEffect(() => {
    if (token) {
      dispatch(fetchReportingEmails(token));
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const currentLocation = locations.find((location) => String(location.id) === String(locationId));

      if (currentLocation) {
        setSelectedReportTime(currentLocation.daily_report_sending_time || '');
      }
    }
  }, [locations, locationId]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSelectReportTime = (selectedValue) => {
    setSelectedReportTime(selectedValue);
    setFlag(true);
  };

  const handleAddNewReportingEmail = (selectedValue) => {
    if (!newEmail) {
      dispatch(
        addNotification({
          message: 'Email address is empty',
          status: 'warning',
        }),
      );
      return;
    }

    if (!validateEmail(newEmail)) {
      dispatch(
        addNotification({
          message: 'Invalid email address',
          status: 'warning',
        }),
      );
      return;
    }
    dispatch(createReportingEmail({ email: newEmail, token }));
    setNewEmail('');
  };

  const handleDeleteReportingEmail = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-reporting-email',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this email?',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeReportingEmail({ id, token }));
                dispatch(hideModal('modal-delete-reporting-email'));
              },
            },
            {
              title: 'Cancel',
              button_variant: 'secondary',
              onAction: () => dispatch(hideModal('modal-delete-reporting-email')),
            },
          ],
        },
      }),
    );
  };

  const handleEmailChange = (id, updatedEmail) => {
    setChangedEmails((prev) => ({
      ...prev,
      [id]: updatedEmail,
    }));
  };

  const handleUpdateReportingEmails = () => {
    Object.keys(changedEmails).forEach((id) => {
      const originalEmailObj = reporting_emails.find((email) => String(email.id) === String(id));

      if (originalEmailObj) {
        const originalEmail = originalEmailObj.email;
        const updatedEmail = changedEmails[id];

        if (originalEmail !== updatedEmail) {
          dispatch(updateExistingReportingEmail({ id, updatedEmail, token }));
          setChangedEmails({});
        } else {
          addNotification({
            message: 'Any reporting email wasn`t changed',
            status: 'failed',
          });
        }
      }
    });
  };

  const handleUpdateDailyReportTime = () => {
    if (flag) {
      dispatch(
        updateExistingDailyReportTime({
          updatedTime: selectedReportTime,
          token,
          locationId,
        }),
      );
      setFlag(false);
    }
  };

  const handleSave = () => {
    if (validate({ selectedReportTime })) {
      handleUpdateReportingEmails();
      handleUpdateDailyReportTime();
    }
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Business Reporting'} />
      </div>
      <div className={styles.content}>
        <div className={styles.reporting_time}>
          <Subtitle title="Send Report At What Time" />
          <div className={styles.selector_wrapper}>
            <DatePicker
              selected={parseTimeToISO(selectedReportTime, DATE_FORMAT)}
              onChange={(value) => handleSelectReportTime(parseTimeToHours(value, DATE_FORMAT))}
              label="Send Report Time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat={DATE_FORMAT}
              timeCaption="Time"
              wrapperClassName={styles.date_picker}
              errors={errors.selectedReportTime?._errors}
              required={true}
            />
          </div>
        </div>

        <div className={styles.reporting_email}>
          <Subtitle title="Add Email Address For Reporting" />
          <div className={styles.add_email_wrapper}>
            <Input
              type="email"
              label="Email Address"
              value={newEmail}
              placeholder="Add Email Address"
              onChange={(e) => setNewEmail(e.target.value)}
              className={styles.input_email}
            />
            <Button
              className={styles.add_button}
              title={'Add New Email Address'}
              onClick={handleAddNewReportingEmail}
            />
          </div>
          <EmailAddressesList
            emails={reporting_emails}
            onDelete={handleDeleteReportingEmail}
            isLoading={status === 'loading'}
            onEmailChange={handleEmailChange}
            className={styles.email_list}
          />
          <Button className={styles.save_button} title={'Save'} onClick={handleSave} />
        </div>
      </div>
    </div>
  );
}

export default BusinessReporting;
