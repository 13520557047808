import clsx from 'clsx';
import HeaderReport from '../components/HeaderReport/header-report';
import { NoRecords } from '../components/NoRecords';
import { ReportFilterPanel, DropdownTypes } from '../components/report-filter-panel';
import ViewDate from '../components/view-date';
import Widget from './widget';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './detail_batch_close_report.module.scss';

export default function DetailBatchCloseReport() {
  const reportTitle = 'Batch Close Report';

  const items = [
    {
      transaction_id: '123456',
      customer_transaction_id: '123',
      order_type: 'Order Type 1',
      amount: 100,
      tip: 10,
      total: 110,
      created_at: '2021-01-01T10:00:00',
      is_captured: true,
    },
    {
      transaction_id: '123457',
      customer_transaction_id: '124',
      order_type: 'Order Type 2',
      amount: 200,
      tip: 20,
      total: 220,
      created_at: '2021-01-02T10:00:00',
      is_captured: false,
    },
  ];

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const tableItem = (item) => {
    return (
      <tr>
        <td className="text-left">{item.transaction_id}</td>
        <td className="text-left">{item.customer_transaction_id}</td>
        <td className="text-left">{item.order_type}</td>
        <td>${item.amount}</td>
        <td>${item.tip}</td>
        <td>${item.total}</td>
        <td className="text-left">
          <ViewDate date={item.created_at} />
        </td>
        <td className="text-left">{item.is_captured ? 'Yes' : 'No'}</td>
      </tr>
    );
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={reportTitle} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel dropdown={[DropdownTypes.order_type_id, DropdownTypes.captured_id]} />
      </div>

      <Widget
        capturedPaymentsCount={2}
        totalPaymentsCount={2}
        totalCapturedAmount={330}
        totalAmountCollectedByCard={330}
      />

      <div className="row">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Transaction Id</th>
              <th>Customer Transaction Id(Magtek)</th>
              <th>Order Type</th>
              <th>Amount</th>
              <th>Tip</th>
              <th>Total</th>
              <th>Captured At</th>
              <th>Is Captured</th>
            </tr>
          </thead>
          {items && items.length > 0 ? items.map((item) => tableItem(item)) : <NoRecords colSpan={8} />}
        </table>
      </div>
    </div>
  );
}
