import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchTaxes,
  addTax,
  updateExistingTax,
  removeTax,
  updateExistingTaxStatus,
  reorderTaxes,
} from '../../../../redux/taxesSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { fetchMenuItems } from '../../../../redux/menuItemsSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';

import { sortItems } from '../../../utilities/utils';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import SalesTaxesList from './sales-taxes-list';
import EditSalesTaxesModal from './modals/sales-taxes-edit-modal';
import AssignItemsSalesTaxesModal from './modals/sales-taxes-assign-modal';

import clsx from 'clsx';
import styles from './sales-taxes.module.scss';

const SalesTaxes = () => {
  // Hooks
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Store
  const taxes = useSelector((state) => state.taxes.taxes);
  const taxesStatus = useSelector((state) => state.taxes.status);
  const menuItems = useSelector((state) => state.menu_items.menu_items);

  // Local state
  const [newTax, setNewTax] = useState(''); // Add new tax
  const [newTaxRate, setNewTaxRate] = useState(''); // Add new tax
  const [taxType, setTaxType] = useState('Percentage'); // Add new tax
  const [isAssignItemsModal, setIsAssignItemsModal] = useState(false); // Modal windows
  const [isEditSalesTaxesModal, setIsEditSalesTaxesModal] = useState(false); // Modal windows
  const [editableData, setEditableData] = useState({
    editableItem: {},
    editableName: '',
    editableRate: 0,
    editableType: '',
  });
  const [currentTaxId, setCurrentTaxId] = useState(null);
  const { errors, validate } = useFormValidation(registrationSchema); // Validation hook

  // Refs
  const inputRef = useRef(null);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchTaxes(token));
      dispatch(fetchMenuItems(token));
    }
  }, [dispatch, token]);

  // Handlers
  const handleAdd = useCallback(() => {
    const largestId = taxes.reduce((max, tax) => (tax.id > max ? tax.id : max), 0);
    const newDetails = {
      id: largestId + 1,
      is_active: true,
      location_id: locationId,
      name: newTax,
      rate: Number(newTaxRate),
      tax_type: taxType,
    };

    if (validate(newDetails)) {
      dispatch(addTax({ newDetails, token }));
      setNewTax('');
      setNewTaxRate('');
    }
  }, [taxes, locationId, newTax, newTaxRate, taxType, validate, dispatch, token]);

  const handleSave = useCallback(() => {
    dispatch(
      updateExistingTax({
        id: currentTaxId,
        updatedDetails: {
          name: editableData.editableName,
          rate: editableData.editableRate,
          tax_type: editableData.editableType,
        },
        token,
      }),
    );
    setIsEditSalesTaxesModal(false);
    setEditableData({
      editableItem: {},
      editableName: '',
      editableRate: 0,
      editableType: '',
    });
  }, [editableData, dispatch, currentTaxId, token]);

  const handleEdit = useCallback(
    (itemId) => {
      setCurrentTaxId(itemId);
      const Tax = taxes.find((tip) => tip.id === itemId);
      setEditableData({
        editableItem: Tax,
        editableName: Tax.name,
        editableRate: Tax.rate,
        editableType: Tax.tax_type,
      });
      setIsEditSalesTaxesModal(true);
    },
    [taxes],
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-tax',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this tax?',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeTax({ id, token }));
                  dispatch(hideModal('modal-delete-tax'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'secondary',
                onAction: () => dispatch(hideModal('modal-delete-tax')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingTaxStatus({ id, is_active: value, token }));
    },
    [dispatch, token],
  );

  const handleClickAssignItemsLink = useCallback((id) => {
    setCurrentTaxId(id);
    setIsAssignItemsModal(true);
  }, []);

  const handleUpdateTax = useCallback(
    (currentTaxId, selectedModalItems) => {
      setIsAssignItemsModal(false);

      const currentTax = taxes.find((tip) => tip.id === currentTaxId);

      dispatch(
        updateExistingTax({
          id: currentTaxId,
          updatedDetails: {
            name: currentTax.name,
            rate: currentTax.rate,
            tax_type: currentTax.tax_type,
            item_ids: selectedModalItems,
          },
          token,
        }),
      );
    },
    [dispatch, token, taxes],
  );

  const handleCloseModal = useCallback(() => {
    setIsAssignItemsModal(false);
  }, []);

  const handleDragWastage = useCallback(
    (item) => {
      const { sourceId, index } = item;

      const old_position = taxes.find((reason) => reason.id === sourceId)?.sort;
      const new_position = sortItems(taxes, 'sort')[index]?.sort;

      if (old_position !== new_position) {
        dispatch(
          reorderTaxes({
            id: sourceId,
            updatedDetails: { old_position, new_position },
            token,
          }),
        );
      }
    },
    [dispatch, taxes, token],
  );

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Taxes'} />
      </div>
      <div className={styles.content}>
        <div className={styles.add_tax}>
          <div className={styles.section}>
            <Input
              label={'Tax Name'}
              value={newTax}
              name="name"
              onChange={(e) => setNewTax(e.target.value)}
              placeholder={'Tax Name'}
              required={true}
              className={styles.tax_name_input}
              errors={errors.name?._errors}
            />
          </div>
          <div className={clsx(styles.section, styles.tax_rate_wrapper)}>
            <Input
              label={'Tax Rate'}
              type="number"
              name="rate"
              value={newTaxRate}
              onChange={(e) => setNewTaxRate(e.target.value)}
              placeholder={`0.00${taxType === 'Dollar' ? '$' : '%'}`}
              required={true}
              className={styles.tax_percent_input}
              ref={inputRef}
              errors={errors.rate?._errors}
            />
            <div
              className={styles.tax_type_buttons}
              style={{
                top: inputRef.current ? `${inputRef.current.offsetTop + inputRef.current.clientHeight / 2}px` : '0',
              }}
            >
              <button
                className={`${styles.tax_type_button} ${taxType === 'Percentage' ? styles.active : ''}`}
                onClick={() => setTaxType('Percentage')}
              >
                %
              </button>
              <button
                className={`${styles.tax_type_button} ${taxType === 'Dollar' ? styles.active : ''}`}
                onClick={() => setTaxType('Dollar')}
              >
                $
              </button>
            </div>
          </div>
          <Button className={styles.add_button} title={'Add Tax'} onClick={handleAdd} />
        </div>
        <SalesTaxesList
          items={taxes}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onDrag={handleDragWastage}
          onAssignToItemsClick={handleClickAssignItemsLink}
          onToggleActive={handleToggleActive}
          isLoading={taxesStatus === 'loading'}
        />
        <EditSalesTaxesModal
          isModalOpen={isEditSalesTaxesModal}
          setIsModalOpen={setIsEditSalesTaxesModal}
          editableData={editableData}
          setEditableData={setEditableData}
          onUpdate={handleSave}
        />

        <AssignItemsSalesTaxesModal
          isModalOpen={isAssignItemsModal}
          setIsModalOpen={setIsAssignItemsModal}
          onClose={handleCloseModal}
          onUpdate={handleUpdateTax}
          menuItems={menuItems}
          taxes={taxes}
          currentTaxId={currentTaxId}
        />
      </div>
    </div>
  );
};

export default SalesTaxes;
