import styles from './category-cards.module.scss';

function getRandomColor() {
  // Temporary function for generation mock color
  var letters = '0123456789ABCDEF';
  var color = '#';

  var a = '#62c855';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

const CategoryCard = ({ category, onClick }) => {
  return (
    <div className={styles.category_card} onClick={() => onClick(category.id)}>
      <div className={styles.head}>
        <p className={styles.category_name}>{category.name}</p>
        <p className={styles.items_counter}>{`${category.item_ids.length} items`}</p>
      </div>
      <div className={styles.color_block} style={{ backgroundColor: getRandomColor() }}></div>
    </div>
  );
};

const CategoryCards = ({ categories, onClick }) => {
  return (
    <div className={styles.category_cards}>
      {categories.map((category) => (
        <CategoryCard category={category} onClick={onClick} key={category.id} />
      ))}
    </div>
  );
};

export default CategoryCards;
