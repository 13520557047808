import { useNavigate } from 'react-router-dom';
import List from '../../../utilities/list/list';
import styles from './timesheet.module.scss';

const TimesheetList = ({ records, isLoading, fromDate, toDate }) => {
  // Hooks
  const navigate = useNavigate();

  // Local state
  const extended_records = records.map((item) => ({
    ...item,
    ...item.data_of_week,
  }));

  // Handlers
  const handleEmployeeClick = (itemId, itemName) => {
    const path =
      itemId !== undefined
        ? `/employee/timesheet/${itemId}/timesheet_details?from_date=${fromDate}&to_date=${toDate}&employee_name=${itemName}`
        : `/employee/timesheet`;

    navigate(path);
  };

  // Config
  const columnConfig = [
    {
      key: 'employee_name',
      header: 'Employee Name',
      render: (item) => (
        <span className={styles.employee_name} onClick={() => handleEmployeeClick(item.team_id, item.team_name)}>
          {item.team_name}
        </span>
      ),
    },
    { key: 'team_role_name', header: 'Role' },
    { key: 'total_hours', header: 'Total Hours' },
    { key: 'total_wage', header: 'Total Wage' },
    { key: 'mon', header: 'Mon' },
    { key: 'tue', header: 'Tue' },
    { key: 'wed', header: 'Wed' },
    { key: 'thu', header: 'Thu' },
    { key: 'fri', header: 'Fri' },
    { key: 'sat', header: 'Sat' },
    { key: 'sun', header: 'Sun' },
  ];

  return <List data={extended_records} columnConfig={columnConfig} isLoading={isLoading} />;
};

export default TimesheetList;
