import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getLoyaltyPrograms = (token) => {
  return handleApiResponse(() =>
    api.get('loyalty_programs', {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getLoyaltyProgram = (id, token) =>
  handleApiResponse(() =>
    api.get(`loyalty_programs/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addLoyaltyProgram = (newLoyaltyProgramDetails, token) =>
  handleApiResponse(() =>
    api.post('loyalty_programs', newLoyaltyProgramDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateLoyaltyProgram = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`loyalty_programs/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteLoyaltyProgram = (id, token) =>
  handleApiResponse(() =>
    api.delete(`loyalty_programs/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
