import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import { showModal, hideModal } from '../../../redux/modalSlice';
import { loginUser } from '../../../redux/authSlice';

import Modal from '../../utilities/modal/modal-template';
import Checkbox from '../../utilities/checkbox/checkbox';
import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';
import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';

import useFormValidation from '../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import { FaEyeSlash } from 'react-icons/fa6';
import { FaEye } from 'react-icons/fa';
import Logo from '../../../assets/img/crunch-logo-512px.png';
import styles from './authentication.module.scss';

const checkValueForEmpty = (value) => value === '' || value === undefined || value === null;

function Authentication() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Store
  const loginStatus = useSelector((state) => state.auth.status);

  // Local state
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const { errors, validate } = useFormValidation(registrationSchema, {
    multipleErrors: true,
  }); // errors
  const [currentEmailError, setCurrentEmailError] = useState([]); // errors
  const [currentPasswordError, setCurrentPasswordError] = useState([]); // errors

  // Effects
  useEffect(() => {
    if (errors.email) {
      const firstEmailError = errors.email._errors[0];
      setCurrentEmailError([firstEmailError]);
    } else {
      setCurrentEmailError([]);
    }

    if (errors.password) {
      const firstPasswordError = errors.password._errors[0];
      setCurrentPasswordError([firstPasswordError]);
    } else {
      setCurrentPasswordError([]);
    }
  }, [errors]);

  // Handlers
  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate({ email: formState.email, password: formState.password })) {
      const hashedPassword = CryptoJS.SHA256(formState.password).toString(CryptoJS.enc.Base64);

      try {
        await dispatch(loginUser({ userEmail: formState.email, password: hashedPassword })).unwrap();
        navigate('/dashboard');
      } catch (e) {
        console.log(e);
        setCurrentEmailError([]);
        setCurrentPasswordError([]);
        showModalIncorrectCredentials();
      }
    }
  };

  const showModalIncorrectCredentials = useCallback(() => {
    dispatch(
      showModal({
        modalId: 'modal-incorrect-credentials',
        data: {
          type: 'confirmation',
          title: 'Invalid Login Credentials',
          actions: [
            {
              title: 'Ok',
              onAction: () => {
                dispatch(hideModal('modal-incorrect-credentials'));
              },
            },
          ],
          className: styles.modal,
        },
      }),
    );
  }, [dispatch]);

  const handleCheckbox = (_, isChecked) => {
    handleFormChange({ target: { name: 'rememberMe', value: isChecked } });
  };

  return (
    <>
      {loginStatus === 'loading' && <BackdropLoading />}
      <div className={styles.wrapper}>
        <div className={styles.login}>
          <img className={styles.logo} src={Logo} draggable={false} alt="Crunch POS logo" />
          <form className={styles.form} onSubmit={handleSubmit} noValidate>
            <div className={styles.formGroup}>
              <Input
                value={formState.email}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleFormChange}
                activePlaceholder={true}
                placeholder="Email Address"
                errors={currentEmailError}
              />
            </div>

            <div className={styles.formGroup}>
              <Input
                value={formState.password}
                id="password"
                name="password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleFormChange}
                activePlaceholder={true}
                suffixIcon={!checkValueForEmpty(formState.password) ? showPassword ? <FaEye /> : <FaEyeSlash /> : null}
                suffixButtonCallback={() => setShowPassword((prev) => !prev)}
                placeholder="Password"
                errors={currentPasswordError}
              />
            </div>
            <div className={styles.remember_me}>
              <Checkbox isChecked={formState.rememberMe} handleOnChange={handleCheckbox} />
              <p>Remember me</p>
            </div>
            <Button
              title="Sign In"
              type="submit"
              className={styles.submit_button}
              style={{ width: '100%', fontSize: '1rem' }}
            />
          </form>
          <div className={styles.forgot_password}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className={styles.sign_up}>
            <p>New to Crunch POS?</p>
            <Link to="/new-registration">Sign Up</Link>
          </div>
        </div>
        <Modal />
      </div>
    </>
  );
}

export default Authentication;