import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import { fetchAllEmployee } from '../../../../redux/employeesSlice';

import Pagination from '../../../utilities/pagination/pagination';
import { PAGE_SIZE_OPTIONS } from '../components/helper';

import DeletedTransactionsList from './deleted-transactions-list';

function DeletedTransactionSection({ filterParams, onUpdateParams }) {
  const { token } = useAppContext();
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employees.employees);
  const employeesStatus = useSelector((state) => state.employees.status);
  const transactions = useSelector((state) => state.reports.deletedTransactionsReport);
  const transactionsStatus = useSelector((state) => state.reports.status);

  useEffect(() => {
    if (employeesStatus === 'idle') {
      dispatch(fetchAllEmployee({ token }));
    }
  }, [dispatch, employeesStatus, token]);

  const handleDeleteOrder = () => {
    console.log('Delete Order');
  };

  //----Pagination Controls---//
  const handleSelectPageCount = (value) => {
    onUpdateParams({ per_page: value });
  };

  const handleClickPrev = () => {
    if (filterParams.page > 1) {
      onUpdateParams({ page: filterParams.page - 1 });
    }
  };

  const handleClickNext = () => {
    onUpdateParams({ page: filterParams.page + 1 });
  };

  return (
    <>
      <div>
        <DeletedTransactionsList
          transactions={transactions}
          employees={employees}
          isLoading={transactionsStatus === 'loading'}
          onDelete={handleDeleteOrder}
        />
        <Pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          numberOfItems={transactions.length > 1 ? 400 : 0} // Temporary value. Need to get this value from the API
          currentPage={filterParams.page}
          currentPageSize={filterParams.per_page}
          onPageSizeChange={handleSelectPageCount}
          onPrev={handleClickPrev}
          onNext={handleClickNext}
        />
      </div>
    </>
  );
}

export default DeletedTransactionSection;
