import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getTerminalSettings = (token) => {
  return handleApiResponse(() =>
    api.get('terminals', {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getTerminalSetting = (id, token) =>
  handleApiResponse(() =>
    api.get(`terminals/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addTerminalSetting = (newTerminalSetting, token) =>
  handleApiResponse(() =>
    api.post('terminals', newTerminalSetting, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateTerminalSetting = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`terminals/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteTerminalSetting = (id, token) =>
  handleApiResponse(() =>
    api.delete(`terminals/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
