import React from 'react';

const TaxDetails = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    tax_details: [
      {
        key: 'Total Tax',
        value: 0,
      },
      {
        key: 'Refund Tax by Card',
        value: 0,
      },
      {
        key: 'Refund Tax by Cash',
        value: 0,
      },
    ],
  };

  const displayTwoDigitWithDelimiter = (value) => {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  return (
    <div>
      <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        Tax Details
      </h4>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th width="25%">Detail</th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%">Amount</th>
            </tr>
          </thead>
          <tbody className="eod_shift_report">
            {shiftReportData.tax_details && shiftReportData.tax_details.length > 0 ? (
              shiftReportData.tax_details.map((data, index) => {
                const klasss = data.key === 'Total Tax' ? 'fw-700' : '';
                const klass = data.key === 'Refund Tax by Card' || data.key === 'Refund Tax by Cash' ? 'text-red' : '';
                return (
                  <tr key={index}>
                    <td className={klasss}>{data.key}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className={`${klass} ${klasss}`}>${displayTwoDigitWithDelimiter(data.value)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan="4">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaxDetails;
