import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UpArrow from '../../../../assets/icons/UpArrow';

import clsx from 'clsx';
import styles from '../sidebar.module.scss';

export const MenuItem = ({ item, link, callback, activeStyle, isOpen }) => {
  const inventoriesCount = useSelector((state) => state.inventories.inventories);
  const Icon = item?.icon;
  const isItemHasSubitems = item?.subItems?.length > 0;

  const field = `${item.name} ${item?.itemCountName ? `(${inventoriesCount?.[item.itemCountName] ?? '-'})` : ''}`;

  return (
    <Link
      to={link}
      onClick={callback}
      className={clsx(styles.menu_item_link, {
        [styles.active]: activeStyle,
      })}
    >
      {Icon && <Icon className={styles.menu_item_icon} />}
      <span
        className={clsx(styles.menu_item_name, {
          [styles.collapsed]: !isOpen,
        })}
      >
        {field}
      </span>
      {isItemHasSubitems && (
        <UpArrow
          className={clsx(styles.menu_item_arrow, {
            [styles.collapsed]: !isOpen,
          })}
        />
      )}
    </Link>
  );
};

export default MenuItem;
