import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import {
  removeTerminalSetting,
  updateExistingTernialSetting,
  addNewTerminalSetting,
  setQueryDetails,
} from '../../../../../redux/terminalSettingsSlice';

import { useAppContext } from '../../../../../context/context';
import { convertValueByType } from '../../../../utilities/utils';

const useTerminalSettingsManagement = (settings) => {
  const [settingsState, setSettingsState] = useState(settings);
  const [initialsettingsState, setInitialsettingsState] = useState(settings);
  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    setSettingsState(settings);
    setInitialsettingsState(settings);
  }, [settings]);

  const handleSetSearch = (result = '') => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleAddSetting = useCallback(
    (newTerminalSetting) => {
      dispatch(addNewTerminalSetting({ newTerminalSetting: { name: newTerminalSetting }, token }));
    },
    [dispatch, token],
  );

  const handleToggleSettingActive = useCallback((id, value) => {
    setSettingsState((prev) => {
      return prev.map((setting) => {
        if (setting.id === parseFloat(id)) {
          return { ...setting, master_terminal: value };
        }

        return setting;
      });
    });
  }, []);

  const handleconfirmAndDeleteReason = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-terminal-setting',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this terminal setting?',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeTerminalSetting({ id, token }));
                  dispatch(hideModal('modal-delete-terminal-setting'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'secondary',
                onAction: () => dispatch(hideModal('modal-delete-terminal-setting')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleSaveChanges = useCallback(() => {
    const updatedWastages = settingsState.filter(
      (item, index) => JSON.stringify(initialsettingsState[index]) !== JSON.stringify(settingsState[index]),
    );

    Promise.allSettled(
      updatedWastages.map((item) =>
        dispatch(updateExistingTernialSetting({ id: item.id, updatedDetails: item, token })),
      ),
    );
  }, [dispatch, initialsettingsState, token, settingsState]);

  const handleUpdateSetting = useCallback((e, type) => {
    const { id, name, value } = e.target;
    setSettingsState((prev) => {
      return prev.map((setting) => {
        if (setting.id === parseFloat(id)) {
          return { ...setting, [name]: convertValueByType(value, type) };
        }

        return setting;
      });
    });
  }, []);

  const handleChangeList = useCallback((state) => {
    // setSettingsState(state);
  }, []);

  return {
    settingsState,
    initialsettingsState,
    handleAddSetting,
    handleToggleSettingActive,
    handleconfirmAndDeleteReason,
    handleUpdateSetting,
    handleChangeList,
    handleSetSearch,
    handleSaveChanges,
  };
};

export default useTerminalSettingsManagement;
