import { api, handleApiResponse } from './base';

export const getWorkingHours = (token) =>
  handleApiResponse(() =>
    api.get('working_hours', {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addWorkingHours = (newDayDetails, token) =>
  handleApiResponse(() =>
    api.post('working_hours', newDayDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateWorkingHours = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`working_hours/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteWorkingHours = (id, token) =>
  handleApiResponse(() =>
    api.delete(`working_hours/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
