import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getReportingEmails,
  addReportingEmail,
  updateReportingEmail,
  deleteReportingEmail,
} from '../api/business_reporting';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchReportingEmails = createAsyncThunk('business_reporting/fetchReportingEmails', async (token) => {
  const response = await getReportingEmails(token);
  return response.data;
});

export const createReportingEmail = createAsyncThunk(
  'business_reporting/createReportingEmail',
  async ({ email, token }, { dispatch }) => {
    try {
      const response = await addReportingEmail(email, token);
      dispatch(
        addNotification({
          message: 'Reporting email was created successfully',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Failed to create reporting email',
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingReportingEmail = createAsyncThunk(
  'business_reporting/updateExistingReportingEmail',
  async ({ id, updatedEmail, token }, { dispatch }) => {
    try {
      const response = await updateReportingEmail(id, updatedEmail, token);
      dispatch(
        addNotification({
          message: 'Reporting email was updated successfully',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Failed to update reporting email',
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeReportingEmail = createAsyncThunk(
  'business_reporting/removeReportingEmail',
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteReportingEmail(id, token);
      dispatch(
        addNotification({
          message: 'Reporting email was deleted successfully',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Failed to delete reporting email',
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  reporting_emails: [],
  status: 'idle',
  error: null,
};

const reportingEmailsSlice = createSlice({
  name: 'business_reporting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchReportingEmails.pending, setLoading)
      .addCase(fetchReportingEmails.fulfilled, (state, action) => {
        setSucceeded(state);
        state.reporting_emails = action.payload;
      })
      .addCase(fetchReportingEmails.rejected, setFailed)

      .addCase(createReportingEmail.pending, setLoading)
      .addCase(createReportingEmail.fulfilled, (state, action) => {
        setSucceeded(state);
        state.reporting_emails.push(action.payload);
      })
      .addCase(createReportingEmail.rejected, setFailed)

      .addCase(updateExistingReportingEmail.pending, setLoading)
      .addCase(updateExistingReportingEmail.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedEmail } = action.payload;
        const existingReportingEmail = state.reporting_emails.find((item) => item.id === id);

        if (existingReportingEmail) {
          Object.assign(existingReportingEmail, updatedEmail);
        }
      })
      .addCase(updateExistingReportingEmail.rejected, setFailed)

      .addCase(removeReportingEmail.pending, setLoading)
      .addCase(removeReportingEmail.fulfilled, (state, action) => {
        setSucceeded(state);
        state.reporting_emails = state.reporting_emails.filter((item) => item.id !== action.payload);
      })
      .addCase(removeReportingEmail.rejected, setFailed);
  },
});

export default reportingEmailsSlice.reducer;
