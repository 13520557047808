import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import HeaderReport from '../components/HeaderReport/header-report';
import NoRecords from '../components/NoRecords/no-records';
import DisplayTwoDigitWithDelimiter from '../components/display-two-digit-with-delimiter';
import DateRangeFilter from '../components/date-range/date-range';
import ViewDate from '../components/view-date';
import { fetchCancelOrdersReport } from '../../../../redux/reportsSetSlice';
import { formatDateTime, PAGE_SIZE_OPTIONS, DEFAULT_PAGINATION } from '../components/helper';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './canceledOrders.module.scss';
import { useAppContext } from '../../../../context/context';
import Pagination from '../../../utilities/pagination/pagination';
import usePagination from '../../business/order-types/usePagination';

function CanceledOrders() {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const cancelOrdersReport = useSelector((state) => state.reports.cancelOrdersReport);

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(cancelOrdersReport.orders, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

  useEffect(() => {
    dispatch(
      fetchCancelOrdersReport({
        token,
        startDate: formatDateTime(startDate),
        endDate: formatDateTime(endDate),
        page,
        perPage,
      }),
    );
  }, [startDate, endDate, page, perPage]);

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const openOrderDetails = (order) => {
    navigate(`/order_details/${order.id}?start_date=${startDate}&end_date=${endDate}`);
  };

  const tableItem = (order) => {
    return (
      <tr key={order.id}>
        <td className="text-left">
          <ViewDate date={order.created_at} />
        </td>
        <td className="text-left">
          <span className="fs-14 text-gray">{order.id}</span>
        </td>
        <td className="text-left"> {order.open_order_type} </td>
        <td>
          <DisplayTwoDigitWithDelimiter amount={order.total_amount + order.total_tips} />
        </td>
        <td>{order.employee ? order.employee.full_name : 'N/A'}</td>
        <td>{order.cancel_order_reason_id ? order.cancel_order_reason.reason : 'N/A'}</td>
        <td>
          <ViewDate date={order.cancel_date} />
        </td>
        <td>
          <a onClick={() => openOrderDetails(order)}>
            <span className="fs-16 fw-500 text-blue1">Details</span>
          </a>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Cancel Orders'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter
          setEndDate={setEndDate}
          endDate={endDate}
          onChange={(data) => {
            setStartDate(data.startDate);
            setEndDate(data.endDate);
          }}
        />
      </div>
      <div className="row">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Created</th>
              <th>ID</th>
              <th>Order Type</th>
              <th>Total Amount</th>
              <th>Cancelled By</th>
              <th>Cancelled Reason</th>
              <th>Cancelled At</th>
              <th>Details</th>
            </tr>
          </thead>
          {cancelOrdersReport.orders && cancelOrdersReport.orders.length > 0 ? (
            cancelOrdersReport.orders.map((order) => tableItem(order))
          ) : (
            <NoRecords colSpan={8} />
          )}
        </table>
      </div>
      <Pagination
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        numberOfItems={cancelOrdersReport.orders.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </div>
  );
}

export default CanceledOrders;
