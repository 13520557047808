import { api, handleApiResponse } from './base';

export const getLocations = (token) =>
  handleApiResponse(() =>
    api.get('locations', {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateDailyReportTime = (updatedTime, token) =>
  handleApiResponse(() =>
    api.put(
      `locations/daily_report_time?time=${updatedTime}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

export const updateBatchCloseTime = (updatedTime, token) =>
  handleApiResponse(() =>
    api.put(
      `locations/batch_close_time?time=${updatedTime}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

export const updateTimeZone = (updatedTimeZone, token) =>
  handleApiResponse(() =>
    api.put(
      `locations/time_zone?time_zone=${updatedTimeZone}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

export const setGiftCardPosition = (isGiftCardAtFirstPosition, token) =>
  handleApiResponse(() =>
    api.put(
      `locations/menu_settings?show_gift_card_at_first_position=${isGiftCardAtFirstPosition}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

export const setOtherSettings = (
  isPrinterQueueEnabled,
  isOrderNumberStartingFromOne,
  isOnlyShowPriceGreaterThanZero,
  token,
) =>
  handleApiResponse(() =>
    api.put(
      `locations/other_settings?printer_queue_enable=${isPrinterQueueEnabled}&order_number_starting_from_one=${isOrderNumberStartingFromOne}&only_show_price_greater_than_zero=${isOnlyShowPriceGreaterThanZero}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

export const setCustomerDisplaySettings = (
  isCustomerSignatureRequired,
  isShowCashBifurcation,
  isShowTipScreenBeforePayment,
  token,
) =>
  handleApiResponse(() =>
    api.put(
      `locations/customer_display_settings?customer_sign_required_on_cd=${isCustomerSignatureRequired}&show_cash_credit_price_on_cd=${isShowCashBifurcation}&show_tip_screen_before_payment=${isShowTipScreenBeforePayment}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
