import React, { useState, useEffect } from 'react';

import List from '../../../utilities/list/list';
import Input from '../../../utilities/input/input';

import { IoTrash } from 'react-icons/io5';
import styles from './reporting-list.module.scss';

const EmailAddressesList = ({ emails, onDelete, isLoading, onEmailChange, className }) => {
  const [updatedEmails, setUpdatedEmails] = useState({});

  useEffect(() => {
    setUpdatedEmails(emails.reduce((acc, email) => ({ ...acc, [email.id]: email.email }), {}));
  }, [emails]);

  const handleUpdateEmailAddress = (id, newEmail) => {
    setUpdatedEmails((prevState) => ({
      ...prevState,
      [id]: newEmail,
    }));

    onEmailChange(id, newEmail);
  };

  const renderInput = (item) => (
    <Input
      id={item.id}
      type="text"
      value={updatedEmails[item.id] || ''}
      placeholder="Add Email Address"
      onChange={(e) => handleUpdateEmailAddress(item.id, e.target.value)}
      className={styles.edit_email_input}
    />
  );

  const columnConfig = [
    {
      key: 'email',
      header: 'Email Addresses',
      render: renderInput,
    },
  ];

  const rowActions = [
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: 'Delete category',
      className: styles.row_actions_button,
    },
  ];

  return (
    <List
      data={emails}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      className={className}
    />
  );
};

export default EmailAddressesList;
