import Switch from '../../../utilities/switch/switch';
import List from '../../../utilities/list/list';

import styles from './empolyees-list.module.scss';

const EmployeeList = ({ employees, roles = [], onToggleActive, onOpen, isLoading }) => {
  const renderFullName = (item) => (
    <span className={styles.full_name} onClick={() => onOpen(item.id)}>
      {item.full_name}
    </span>
  );
  const renderTeamRole = (item) => roles.find((role) => role.value === item.team_role_id)?.label ?? item.team_role_id;
  const renderSwitcher = (item) => (
    <Switch id={item.id} roleName={'User'} isActive={item.is_active} onSwitch={onToggleActive} />
  );

  const columnConfig = [
    {
      key: 'full_name',
      header: 'Full Name',
      render: renderFullName,
    },
    {
      key: 'team_role_id',
      header: 'Role',
      render: renderTeamRole,
    },
    {
      key: 'is_active',
      header: 'Status',
      render: renderSwitcher,
    },
  ];

  return <List data={employees} columnConfig={columnConfig} isLoading={isLoading} />;
};

export default EmployeeList;
