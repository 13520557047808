import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { IoTrash } from 'react-icons/io5';

import List from '../../../utilities/list/list';

import styles from './orders.module.scss';

const DATE_FORMAT = "MMMM d, yyyy 'at' hh:mm a";

const OrdersList = ({ orders, isLoading, onDelete }) => {
  const renderId = (item) => <span>{`ID: ${item.offline_id}`}</span>;
  const renderDate = (item) => <span>{format(item.created_at, DATE_FORMAT)}</span>;

  const renderDetails = (item) => (
    <span>
      <Link to={`/reporting/order-details/${item.id}`}>Details</Link>
    </span>
  );

  const columnConfig = [
    {
      key: 'id',
      header: 'ID',
      render: renderId,
    },
    {
      key: 'date',
      header: 'Date',
      render: renderDate,
    },
    { key: 'customer_name', header: 'Customer' },
    { key: 'employee_name', header: 'Employee' },
    { key: 'total_tax_amount', header: 'Total' },
    { key: 'order_status', header: 'Status' },
    { key: 'details', header: 'Details', render: renderDetails, cellClassName: styles.details },
  ];

  const rowActions = [
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete order', className: styles.row_actions_button },
  ];

  return <List data={orders} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default OrdersList;
