import { api, handleApiResponse } from './base';

export const getTeamRoles = (token) =>
  handleApiResponse(() =>
    api.get('team_roles', {
      headers: {
        Authorization: token,
      },
    }),
  );

export const addTeamRole = (newDetails, token) =>
  handleApiResponse(() =>
    api.post('team_roles', newDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateTeamRole = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`team_roles/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteTeamRole = (id, token) =>
  handleApiResponse(() =>
    api.delete(`team_roles/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const getModules = (token) =>
  handleApiResponse(() =>
    api.get('team_roles/modules', {
      headers: {
        Authorization: token,
      },
    }),
  );

export const getEmployees = (token) =>
  handleApiResponse(() =>
    api.get('employees', {
      headers: {
        Authorization: token,
      },
    }),
  );
