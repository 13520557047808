import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../../context/context';

import { updateExistingMenuItem, fetchMenuItem } from '../../../../../redux/menuItemsSlice';
import { addNotification } from '../../../../../redux/tooltipSlice';

import { fetchTaxes } from '../../../../../redux/taxesSlice';
import { fetchOptions } from '../../../../../redux/optionSetsSlice';
import { fetchModifiers } from '../../../../../redux/modifierSetsSlice';

import useFormValidation from '../../../../utilities/useFormValidation';

import PageTitle from '../../../../utilities/page-title/page-title';
import Input from '../../../../utilities/input/input';
import Label from '../../../../utilities/label/label';
import Textarea from '../../../../utilities/textarea/textarea';
import Selector from '../../../../utilities/selector/selector';
import Checkbox from '../../../../utilities/checkbox/checkbox';
import Button from '../../../../utilities/button/button';
import BackdropLoading from '../../../../utilities/backdrop-loading/backdrop-loading';

import AddTaxesModal from '../modal_windows/add-taxes-modal';
import AddOptionsModal from '../modal_windows/add-options-modal';
import AddVariationModal from '../modal_windows/add-variations-modal';
import OptionsList from '../lists/options-list';
import ModifiersList from '../lists/modifiers-list';

import { FaPlus } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

import { registrationSchema } from '../validation';
import { convertValueByType } from '../../../../utilities/utils';

import clsx from 'clsx';
import styles from './edit-item.module.scss';

const EditItem = () => {
  // Hooks
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  // Store
  const status = useSelector((state) => state.menu_items.status);
  const item = useSelector((state) => state.menu_items.menu_item);
  const categories = useSelector((state) => state.categories.categories);
  const taxes = useSelector((state) => state.taxes.taxes);
  const options = useSelector((state) => state.optionSets.optionSets);
  const modifierSets = useSelector((state) => state.modifierSets.modifierSets);
  const statusLoading = status === 'loading';

  // Local state
  const [currentItem, setCurrentItem] = useState({});
  const [newItem, setNewItem] = useState({}); // Request body for POST request
  const [businessLogoContent, setBusinessLogoContent] = useState(''); // logo icon
  const [businessLogoFileName, setBusinessLogoFileName] = useState(''); // logo icon
  const [description, setDescription] = useState(''); // Description textarea

  // Modals global state
  const [isAddTaxesModal, setIsAddTaxesModal] = useState(false);
  const [isOptionsModal, setIsOptionsModal] = useState(false);
  //const [isVariationModal, setIsVariationModal] = useState(false);

  // Modals taxes state
  const [selectedTaxesIds, setSelectedTaxesIds] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);

  // Modals options state
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsIds, setSelectedOptionsIds] = useState([]);
  const [selectedOptionSetsIds, setSelectedOptionsSetsIds] = useState([]);
  const [checkedOptionsIds, setCheckedOptionsIds] = useState([]);

  // Modals variations state
  //const [selectedVariations, setSelectedVariations] = useState([]);
  //const [checkedVariationsIds, setCheckedVariationsIds] = useState([]);
  //const [checkedVariations, setCheckedVariations] = useState([]);
  //const [isAllVariationsChecked, setIsAllVariationsChecked] = useState(false);
  //const [editedVariation, setEditedVariation] = useState(null);

  // Modals modifiers state
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [selectedModifiersIds, setSelectedModifiersIds] = useState([]);

  // Other state
  const [flag, setFlag] = useState(false);

  // Validation hook
  const { errors, validate } = useFormValidation(registrationSchema);

  // Complex local state
  const categorySelectorOptions = useMemo(() => {
    const categoriesOptions = categories.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return categoriesOptions;
  }, [categories]);

  const modifiersSelectorOptions = useMemo(() => {
    const modifiersOptions = modifierSets.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return modifiersOptions;
  }, [modifierSets]);

  // Effect for dispatch data
  useEffect(() => {
    if (token) {
      dispatch(fetchTaxes(token));
      //dispatch(fetchOptions({ token }));
      dispatch(fetchModifiers({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMenuItem({ id, token }));
    }
  }, [id, token, dispatch]);

  useEffect(() => {
    if (item) {
      setCurrentItem(item);
    }
  }, [item]);

  useEffect(() => {
    if (currentItem) {
      setNewItem({ ...currentItem });
      setDescription(currentItem.desc && currentItem.desc);
      setSelectedTaxesIds(currentItem.tax_ids);
      setSelectedModifiersIds(currentItem.modifier_set_ids);
      setSelectedOptionsIds(
        currentItem.variations_attributes && currentItem.variations_attributes.length > 0
          ? currentItem.variations_attributes[0].option_ids
          : [],
      );
      setSelectedOptionsSetsIds(
        currentItem.variations_attributes && currentItem.variations_attributes.length > 0
          ? currentItem.variations_attributes[0].option_set_ids
          : [],
      );
      setBusinessLogoContent(currentItem.image);
      setBusinessLogoFileName(currentItem.image);

      //setDescription(currentItem.desc);
      /* setNewItem((prev) => ({
        ...prev,
        category_id: Number(value),
      })); */
    }
  }, [currentItem]);

  // Effect for taxes
  useEffect(() => {
    if (selectedTaxesIds) {
      const currentTaxes = taxes.filter((tax) => selectedTaxesIds.includes(tax.id));
      setSelectedTaxes(currentTaxes);
    }
  }, [selectedTaxesIds, taxes]);

  // Effect for options
  /*   useEffect(() => {
    if (selectedOptionsIds && selectedOptionsIds.length !== 0) {
      const currentOptions = options.filter((option) => selectedOptionsIds.includes(option.id));
      setSelectedOptions(currentOptions);
    }
  }, [selectedOptionsIds, options]); */

  // Effect for variations
  /*   useEffect(() => {
    if (selectedVariations && selectedVariations.length > 0) {
      setFlag(true);
    }
  }, [selectedVariations]); */

  /*   useEffect(() => {
    setIsAllVariationsChecked(
      selectedVariations.length > 0 && checkedVariationsIds.length === selectedVariations.length,
    );
  }, [checkedVariationsIds, selectedVariations]); */

  /*   useEffect(() => {
    const checkedVariations = selectedVariations.filter((obj) => checkedVariationsIds.includes(obj.id));
    setCheckedVariations(checkedVariations);
  }, [checkedVariationsIds, selectedVariations]); */

  // Effect for modifiers
  useEffect(() => {
    if (selectedModifiersIds && selectedModifiersIds.length !== 0) {
      const currentModifiers = modifierSets.filter((modifier) => selectedModifiersIds.includes(modifier.id));
      setSelectedModifiers(currentModifiers);
    }
  }, [selectedModifiersIds, modifierSets]);

  useEffect(() => {
    //console.log("options", options);
    //console.log("selectedOptionsIds", selectedOptionsIds);

    if (selectedOptionsIds && selectedOptionsIds.length > 0) {
      //const currentOptions = options.filter((option) => selectedOptionsIds.includes(option.id));
      const currentOptions = options
        // Filter to get only selected option sets
        .filter((set) => selectedOptionSetsIds.includes(set.id))
        // Extract options from each set
        .flatMap((set) => set.options)
        // Filter to get only the selected options
        .filter((option) => selectedOptionsIds.includes(option.id));

      //console.log("options", options);
      //console.log("selectedOptionsIds", selectedOptionsIds);
      //console.log("selectedOptionSetsIds", selectedOptionSetsIds);
      //console.log("currentOptions", currentOptions);

      setSelectedOptions(currentOptions);
    }
  }, [options, selectedOptionsIds, selectedOptionSetsIds]);

  // Handler add logo image
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        setBusinessLogoContent(base64String);
        setBusinessLogoFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoClick = () => {
    document.getElementById('logoInput').click();
  };

  // Handler category
  const handleSelectCategory = (value) => {
    setNewItem((prev) => ({
      ...prev,
      category_id: Number(value),
    }));
  };

  // Handler description
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setNewItem((prev) => ({
      ...prev,
      desc: event.target.value,
    }));
  };

  // Handlers taxes
  const handleAddTaxes = () => {
    //const selectedTaxesObjects = taxes.filter((tax) => selectedTaxesIds.includes(tax.id));
    /* const result = {
      selected_taxes: selectedTaxesObjects,
    }; */

    /* setNewItem((prev) => ({
      ...prev,
      //taxes_data: result,
    })); */
    setIsAddTaxesModal(false);
  };

  // Handlers options
  const handleAddOptions = (optionSets) => {
    setSelectedOptions(optionSets);

    const allSelectedOptions = optionSets.flatMap((item) => item.properties.options.map((option) => option));
    setSelectedOptions(allSelectedOptions);

    const allIds = optionSets.flatMap((item) => item.properties.options.map((option) => option.id));
    const allValues = optionSets.map((item) => item.value);

    setSelectedOptionsIds(allIds);
    setSelectedOptionsSetsIds(allValues);
    setCheckedOptionsIds(allIds);

    /*     const transformedSelectedOptions = optionSets
      .map((item) =>
        item.properties.options.map((option) => ({
          id: option.id,
          name: option.name,
          price: 0,
          quantity: 0,
          sku: '',
          option_ids: allIds,
          option_set_ids: allValues,
        })),
      )
      .flat(); */

    //setSelectedVariations((prevArray) => [...prevArray, ...transformedSelectedOptions]);
  };

  // Handlers variations
  /*   const handleAddVariation = (variation) => {
    if (selectedVariations && selectedVariations.length === 0 && !variation.hasOwnProperty('id')) {
      variation.id = 0;
    } else {
      const biggestId = Math.max(...selectedVariations.map((variation) => variation.id));
      variation.id = biggestId + 1;
    }

    if (!variation.hasOwnProperty('quantity')) {
      variation.quantity = 0;
    } else {
      variation.quantity = Number(variation.quantity);
    }

    variation.price = Number(variation.price);
    setSelectedVariations((prevVariations) => [...prevVariations, variation]);
    setCheckedVariationsIds((prevCheckedIds) => {
      if (!prevCheckedIds.includes(variation.id)) {
        return [...prevCheckedIds, variation.id];
      }
      return prevCheckedIds;
    });
    setEditedVariation(null);
  }; */

  /*   const handleEditVariation = (id) => {
    const variationToEdit = selectedVariations.find((variation) => variation.id === Number(id));
    if (variationToEdit) {
      setEditedVariation(variationToEdit);
    }
    setIsVariationModal(true);
  }; */

  /*   const handleEditVariationData = useCallback((updatedVariation) => {
    setSelectedVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === updatedVariation.id ? { ...variation, ...updatedVariation } : variation,
      ),
    );
    setEditedVariation(null);
  }, []); */

  /*   const handleDeleteVariation = useCallback(
    (id) => {
      setSelectedVariations((prevVariations) => prevVariations.filter((variation) => variation.id !== id));
    },
    [setSelectedVariations],
  ); */

  const handleDeleteOption = useCallback(
    (id) => {
      setSelectedOptions((prevOptions) => prevOptions.filter((option) => option.id !== id));
    },
    [setSelectedOptions],
  );

  // Handlers modals
  const handleClickAddTaxes = useCallback(() => {
    setIsAddTaxesModal(true);
  }, []);

  const handleClickAddOptions = useCallback(() => {
    setIsOptionsModal(true);
  }, []);

  /*   const handleClickAddVariation = useCallback(() => {
    setIsVariationModal(true);
  }, []); */

  // Handlers modifiers
  const handleSelectModifierSet = (value) => {
    const numericValue = Number(value);

    setSelectedModifiersIds((prev) => {
      if (!prev.includes(numericValue)) {
        return [...prev, numericValue];
      }
      return prev;
    });
  };

  /*   const handleEditModifier = useCallback((type, value, id) => {
    // TODO: I do not understand what to do with this values
  }, []); */

  const handleDeleteModifier = useCallback(
    (value) => {
      setSelectedModifiers((prevModifier) => prevModifier.filter((modifier) => modifier.id !== Number(value)));
      setSelectedModifiersIds((prevIds) => prevIds.filter((id) => id !== value));
    },
    [setSelectedModifiers, setSelectedModifiersIds],
  );

  // Handlers glabal
  /*   const handleAction = (message, status) => {
    dispatch(addNotification({ message, status }));
  }; */

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;

    setNewItem((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleSaveChanges = async () => {
    const createdItem = {
      ...newItem,
      active: true,
      image: businessLogoContent,
      tax_ids: selectedTaxesIds,
      variations_attributes: [
        {
          //id: 1,
          //is_active: true,
          //is_custom: true,
          //name: "string",
          option_ids: checkedOptionsIds,
          option_set_ids: selectedOptionSetsIds,
          //price: 0,
          //price_type: "Variable",
          //sku: "string",
          //stock_qty: "string",
          //destroy: true,
          //is_deleted: true,
          //is_checked: true,
          //order_variation_id: 0
        },
      ],
      modifier_set_ids: selectedModifiersIds,
    };

    if (validate(createdItem)) {
      try {
        await dispatch(updateExistingMenuItem({ id: currentItem.id, updatedDetails: createdItem, token })).unwrap();
        navigate('/menu/items');
      } catch {
        //handleAction("Failed to create new Item", "failed");
      }
    }
  };

  const handleCancel = () => {
    navigate('/menu/items');
  };

  //console.log("businessLogoContent", businessLogoContent);
  //console.log("businessLogoFileName", businessLogoFileName);
  //console.log("description", description);
  //console.log("selectedTaxesIds", selectedTaxesIds);
  //console.log("selectedTaxes", selectedTaxes);
  //console.log("selectedOptions", selectedOptions);
  //console.log("selectedOptionsIds", selectedOptionsIds);
  //console.log("selectedOptionSetsIds", selectedOptionSetsIds);
  //console.log("checkedOptionsIds", checkedOptionsIds);
  //console.log("selectedModifiers", selectedModifiers);
  //console.log("selectedModifiersIds", selectedModifiersIds);

  return (
    <section className={styles.add_item_page}>
      <div className={styles.content}>
        <PageTitle name={'Create Item'} />
        <div className={styles.form_container}>
          <div className={styles.settings}>
            <div className={styles.fields}>
              {statusLoading ? <BackdropLoading /> : null}
              <div className={clsx(styles.name_fields, styles.block)}>
                <div className={styles.field}>
                  <Input
                    label={'Item name'}
                    value={newItem.name}
                    onChange={(e) => handleInputChange(e, 'name')}
                    placeholder={'Item Name'}
                    required={true}
                    name={'name'}
                    errors={errors.name?._errors}
                  />
                </div>
                <div className={styles.field}>
                  <Label text="Select Category" className={styles.category_label} />
                  <Selector
                    selectedItem={currentItem.category_id}
                    icon={IoIosArrowDown}
                    items={categorySelectorOptions}
                    //placeholder={currentItem.category_name}
                    onSelectItem={handleSelectCategory}
                  />
                </div>
                <div className={styles.full_field}>
                  <Textarea
                    label="Description For Online Ordering"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>

                <div className={styles.full_field}>
                  <div className={styles.item_parameters}>
                    <Input
                      value={newItem.price}
                      onChange={(e) => handleInputChange(e, 'number')}
                      placeholder={'Price'}
                      name={'price'}
                      className={styles.input_variations}
                    />
                    <Input
                      value={newItem.sku}
                      onChange={(e) => handleInputChange(e, 'string')}
                      placeholder={'SKU'}
                      name={'sku'}
                      className={styles.input_variations}
                    />
                    <Input
                      value={newItem.quantity}
                      onChange={(e) => handleInputChange(e, 'number')}
                      placeholder={'Quantity'}
                      name={'quantity'}
                      className={clsx(styles.input_variations, styles.last_variation)}
                    />
                  </div>
                </div>

                <div className={styles.full_field}>
                  <Label text="Taxes" />
                  {selectedTaxes &&
                    selectedTaxes.map((tax) => {
                      return (
                        <div key={tax.id} className={styles.tax_item}>
                          {tax.name}
                        </div>
                      );
                    })}
                  <Button
                    className={clsx(styles.button, styles.add_button, styles.add_taxes)}
                    title={'Select Taxes'}
                    onClick={handleClickAddTaxes}
                  />
                </div>

                <div className={styles.full_field}>
                  <PageTitle name={'Options'} className={styles.subtitle} />
                  <p className={styles.options_description}>
                    Add a custom set of options to an item to create variations. For example, a size option set can
                    create variations small, medium and large
                  </p>
                  <Button
                    className={clsx(styles.button, styles.add_button)}
                    title={'Add Options'}
                    onClick={handleClickAddOptions}
                  />

                  {selectedOptions && selectedOptions.length > 0 && (
                    <OptionsList
                      items={selectedOptions}
                      //onEdit={handleEditModifier}
                      onDelete={handleDeleteOption}
                      checkedOptionsIds={checkedOptionsIds}
                      setCheckedOptionsIds={setCheckedOptionsIds}
                    />
                  )}
                </div>
                {/* 
                <div className={styles.full_field}>
                  <PageTitle name={'Variations'} className={styles.subtitle} />
                  {!flag ? (
                    <div className={styles.item_parameters}>
                      <Input
                        value={newItem.price}
                        onChange={(e) => handleInputChange(e, 'number')}
                        placeholder={'Price'}
                        name={'price'}
                        className={styles.input_variations}
                      />
                      <Input
                        value={newItem.sku}
                        onChange={(e) => handleInputChange(e, 'string')}
                        placeholder={'SKU'}
                        name={'sku'}
                        className={styles.input_variations}
                      />
                      <Input
                        value={newItem.quantity}
                        onChange={(e) => handleInputChange(e, 'number')}
                        placeholder={'Quantity'}
                        name={'quantity'}
                        className={clsx(styles.input_variations, styles.last_variation)}
                      />
                    </div>
                  ) : (
                    <div className={styles.variations}>
                      {selectedVariations && selectedVariations.length > 0 && (
                        <div className={styles.variation_length}>
                          <Checkbox
                            item={{ id: 'all-variations' }}
                            handleOnChange={() => {
                              const newCheckedState = !isAllVariationsChecked;
                              setIsAllVariationsChecked(newCheckedState);
                              setCheckedVariationsIds(newCheckedState ? selectedVariations.map((v) => v.id) : []);
                            }}
                            isChecked={isAllVariationsChecked}
                          />
                          <span className={styles.variation_length_span}>{selectedVariations.length} Variations</span>
                        </div>
                      )}

                      <VariationsList
                        items={selectedVariations}
                        onEdit={handleEditVariation}
                        onDelete={handleDeleteVariation}
                        checkedVariationsIds={checkedVariationsIds}
                        setCheckedVariationsIds={setCheckedVariationsIds}
                      />
                    </div>
                  )}

                  <Button
                    className={clsx(styles.button, styles.add_button)}
                    title={'Add Variation'}
                    onClick={handleClickAddVariation}
                  />
                </div> */}

                <div className={styles.full_field}>
                  <PageTitle name={'Modifier Sets'} className={styles.subtitle} />

                  <Selector
                    icon={IoIosArrowDown}
                    items={modifiersSelectorOptions}
                    placeholder={'Select Modifier Set'}
                    onSelectItem={handleSelectModifierSet}
                    className={styles.selector_modifiers}
                  />

                  <div className={styles.selected_modifiers_list}>
                    {selectedModifiers && (
                      <ModifiersList
                        items={selectedModifiers}
                        //onEdit={handleEditModifier}
                        onDelete={handleDeleteModifier}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.buttons}>
              <Button className={styles.button} disabled={statusLoading} title={'Save'} onClick={handleSaveChanges} />
              <Button
                className={styles.button}
                variant={'secondary'}
                disabled={statusLoading}
                title={'Cancel'}
                onClick={handleCancel}
              />
            </div>
          </div>
          <div className={styles.add_logo}>
            <div className={styles.logo_container} onClick={handleLogoClick}>
              {businessLogoContent ? (
                <img src={businessLogoContent} alt="Logo Preview" className={styles.logo_image} />
              ) : (
                <FaPlus className={styles.plus_icon} />
              )}
            </div>
            <div className={styles.logo_footer} onClick={handleLogoClick}>
              <p>Add Item image</p>
            </div>
            <input
              type="file"
              id="logoInput"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <AddTaxesModal
          modal={isAddTaxesModal}
          setModalVisibility={setIsAddTaxesModal}
          items={taxes}
          selectedTaxesIds={selectedTaxesIds}
          setSelectedTaxesIds={setSelectedTaxesIds}
          onUpdate={handleAddTaxes}
        />
        <AddOptionsModal
          modal={isOptionsModal}
          setModalVisibility={setIsOptionsModal}
          items={taxes}
          selectedTaxesIds={selectedTaxesIds}
          setSelectedTaxesIds={setSelectedTaxesIds}
          onAdd={handleAddOptions}
        />
        {/*         <AddVariationModal
          modal={isVariationModal}
          setModalVisibility={setIsVariationModal}
          item={editedVariation}
          onAdd={handleAddVariation}
          onEdit={handleEditVariationData}
        /> */}
      </div>
    </section>
  );
};

export default EditItem;
