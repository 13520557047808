import { parse, isBefore, isAfter } from 'date-fns';
import { DATE_FORMAT } from './constants';

export const isTimeRangeOverlapping = (start1, end1, start2, end2) => {
  const { range1Start, range1End, range2Start, range2End } = defineFormatRanges(start1, end1, start2, end2);
  return isBefore(range2Start, range1End) && isAfter(range2End, range1Start);
};

export const isWrongRange = (start1, end1, start2, end2) => {
  const { range1Start, range1End, range2Start, range2End } = defineFormatRanges(start1, end1, start2, end2);
  return isAfter(range1Start, range1End) || isAfter(range2Start, range2End);
};

const defineFormatRanges = (start1, end1, start2, end2) => {
  const format = DATE_FORMAT;
  const range1Start = parse(start1, format, new Date());
  const range1End = parse(end1, format, new Date());
  const range2Start = parse(start2, format, new Date());
  const range2End = parse(end2, format, new Date());

  return { range1Start, range1End, range2Start, range2End };
};

export const convertStringToBoolean = (value) => {
  return value === 'true';
};
