import React from 'react';

import Switch from '../../../utilities/switch/switch';
import Checkbox from '../../../utilities/checkbox/checkbox';
import List from '../../../utilities/list/list';

import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import { LiaCopySolid } from 'react-icons/lia';
import styles from './items-list.module.scss';

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  return `${formattedDate}, ${formattedTime}`;
};

const MenuItemsList = ({
  items,
  categories,
  onCopy,
  onEdit,
  onDelete,
  onToggleActive,
  selectedItemsIds,
  setSelectedItemsIds,
  isLoading,
}) => {
  const handleCheckboxChange = (id, value) => {
    setSelectedItemsIds((prev) => {
      if (value) {
        return [...prev, id];
      } else {
        return prev.filter((item) => item !== id);
      }
    });
  };

  const renderItemName = (item) => {
    const itemId = item ? item.id : 1;
    return (
      <span className={styles.item_name} onClick={() => onEdit(itemId)}>
        {item ? item.name : '-'}
      </span>
    );
  };

  const renderPrice = (item) => {
    return <span>{item ? `$${item.price}` : '-'}</span>;
  };

  const renderCategoryName = (item) => {
    const category = categories.find((category) => category.id === item.category_id);
    return <span>{category ? category.name : '-'}</span>;
  };

  const renderModifierGroup = (item) => {
    // modifier_and_option_sets is a {} and we should work with it like with object
    // temporary solution
    return (
      <div>
        <div style={{ whiteSpace: 'nowrap' }}>Group 1</div>
        <div>Group 2</div>
      </div>
    );
  };

  const renderTaxesNames = (item) => {
    // taxes_data is a {} and we should work with it like with object
    // temporary solution
    return (
      <div>
        <div style={{ whiteSpace: 'nowrap' }}>Tax 1</div>
        <div>Tax 2</div>
      </div>
    );
  };

  const renderCheckbox = (item) => (
    <Checkbox item={item} handleOnChange={handleCheckboxChange} isChecked={selectedItemsIds.includes(item.id)} />
  );

  const renderSwitcherPOS = (item) => (
    <Switch
      id={item.id}
      isActive={item.hide_status === 'UnHide'}
      onSwitch={(id, status) => onToggleActive(id, status, 'pos')}
    />
  );

  /*   const renderSwitcherWebsite = (item) => (
    <Switch
      id={item.id}
      isActive={item.web_site_hide_status === "UnHide"}
      onSwitch={(id, status) => onToggleActive(id, status, "website")}
    />
  ); */

  const renderImagePreview = (item) => (
    <div className={styles.image_wrapper}>
      <img src={item.image} alt={item.name + ' image'} />
    </div>
  );

  const renderCreatedAt = (item) => {
    return <span>{formatDate(item.created_at)}</span>;
  };

  const renderUpdatedAt = (item) => {
    return <span>{formatDate(item.updated_at)}</span>;
  };

  const columnConfig = [
    {
      key: 'sort',
      header: '',
      render: renderCheckbox,
    },
    {
      key: 'name',
      header: 'Item Name',
      render: renderItemName,
    },
    {
      key: 'price',
      header: 'Price',
      render: renderPrice,
    },
    {
      key: 'quantity',
      header: 'Stock Qty',
    },
    {
      key: 'taxes_data',
      header: 'Tax',
      render: renderTaxesNames,
    },
    {
      key: 'category_id',
      header: 'Categories',
      render: renderCategoryName,
    },
    {
      key: 'modifier_and_option_sets',
      header: 'Modifier Group',
      render: renderModifierGroup,
    },
    {
      key: 'hide_status',
      header: 'UnHide on POS',
      render: renderSwitcherPOS,
    },
    {
      key: 'image',
      header: 'Image',
      render: renderImagePreview,
    },
    {
      key: 'created_at',
      header: 'Created At',
      render: renderCreatedAt,
    },
    {
      key: 'updated_at',
      header: 'Updated At',
      render: renderUpdatedAt,
    },
  ];

  const rowActions = [
    /*     {
      icon: <LiaCopySolid />,
      onClick: onCopy,
      label: "Copy item",
    }, */
    {
      icon: <MdModeEditOutline />,
      onClick: onEdit,
      label: 'Edit item',
    },
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: 'Delete item',
    },
  ];

  return (
    <List
      data={items}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      noDataMessage={'No Records found.'}
    />
  );
};

export default MenuItemsList;
