import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from '../../../redux/tooltipSlice';

import clsx from 'clsx';
import styles from './tooltip.module.scss';

const Tooltip = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    notifications.forEach((notification) => {
      setTimeout(() => {
        const tooltipElement = document.getElementById(`tooltip-${notification.id}`);
        if (tooltipElement) {
          tooltipElement.classList.add(styles['fade-out']);
        }

        setTimeout(() => {
          dispatch(removeNotification(notification.id));
        }, 3000);
      }, 3000);
    });
  }, [notifications, dispatch]);

  return (
    <div className={styles.tooltipContainer}>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          id={`tooltip-${notification.id}`}
          className={`${styles.tooltip} ${styles[`tooltip-${notification.status}`]}`}
        >
          <span>{notification.message}</span>
          <button className={styles.closeBtn} onClick={() => dispatch(removeNotification(notification.id))}>
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Tooltip);
