import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';
import moment from 'moment-timezone';

import { fetchBusinessInformation, updateExistingBusinessInformation } from '../../../../redux/informationSlice';
import { changeLocationName } from '../../../../redux/authSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Selector from '../../../utilities/selector/selector';
import Button from '../../../utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { GoogleAutocomplete } from '../../../utilities';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';
import { convertValueByType } from '../../../utilities/utils';

import { IoIosArrowDown } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa';

import styles from './information.module.scss';

const timezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).format('Z');
  return {
    label: `(GMT${offset}) ${timezone}`,
    value: `(GMT${offset}) ${timezone}`,
  };
});

function Information() {
  // Hooks
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Store
  const business_information_status = useSelector((state) => state.business_information.status);
  const business_information = useSelector((state) => state.business_information.business_information);

  // Local state
  const [businessInformation, setBusinessInformation] = useState({}); // Name, Phone, Email and Website
  const [timeZone, setTimeZone] = useState(''); // TimeZone
  const [address, setAddress] = useState(''); // Address
  const [businessLogoContent, setBusinessLogoContent] = useState(''); // Logo in Base64
  const [businessLogoFileName, setBusinessLogoFileName] = useState(''); // Logo format

  // Validation hook
  const { errors, validate } = useFormValidation(registrationSchema);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchBusinessInformation(token, locationId));
    }
  }, [dispatch, token, locationId]);

  useEffect(() => {
    if (business_information && business_information.length > 0) {
      const restaurant_information = business_information.find((item) => item.business_id === parseInt(locationId));

      if (restaurant_information) {
        const { business_address = {}, business_details = {}, logo = {} } = restaurant_information;

        setBusinessInformation({
          name: business_details.name || '',
          phone: business_details.phone_number || '',
          email: business_details.email || '',
          website: business_details.website || '',
        });
        setTimeZone(business_details.timezone ? business_details.timezone : '');
        setAddress(business_address.country ? business_address.country : '');
        setBusinessLogoContent(logo.content ? logo.content : '');
        setBusinessLogoFileName(logo.file_name ? logo.file_name : '');
      }
    }
  }, [business_information, locationId]);

  // Handlers
  const handleFileChange = (event) => {
    // Logo Select
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        setBusinessLogoContent(base64String);
        setBusinessLogoFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoClick = () => {
    // Logo view changed
    document.getElementById('logoInput').click();
  };

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setBusinessInformation((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleUpdate = async (e) => {
    // Update business details
    e.preventDefault();

    const businessData = {
      business_id: locationId,
      business_details: {
        name: businessInformation.name,
        phone_number: businessInformation.phone,
        email: businessInformation.email,
        website: businessInformation.website,
        timezone: timeZone,
      },
      business_address: {
        country: address,
      },
      logo: {
        file_name: businessLogoFileName,
        content: businessLogoContent,
      },
    };

    if (validate(businessInformation)) {
      try {
        await dispatch(
          updateExistingBusinessInformation({
            id: locationId,
            updatedDetails: businessData,
            token,
          }),
        ).unwrap();
        if (businessData.business_details.name) {
          dispatch(changeLocationName(businessData.business_details.name));
        }
      } catch {
        console.log('An error occurred while sending data to the server');
      }
    }
  };

  return (
    <>
      <div className={styles.main_container}>
        {business_information_status === 'loading' && <BackdropLoading />}
        <div className={styles.head_container}>
          <PageTitle name={'Business Information'} />
        </div>
        <div className={styles.info_container}>
          <form className={styles.form}>
            <div className={styles.main_info}>
              <h3 className={styles.subtitle}>Restaurant Details</h3>
              <div className={styles.section} style={{ marginBottom: '24px' }}>
                <Input
                  value={businessInformation.name}
                  onChange={handleInputChange}
                  label="Restaurant Name"
                  name={'name'}
                  placeholder="Your restaurant name"
                  required
                  errors={errors.name?._errors}
                />
              </div>
              <div className={styles.section} style={{ marginBottom: '24px' }}>
                <Input
                  value={businessInformation.phone}
                  onChange={handleInputChange}
                  label="Restaurant Phone Number"
                  name={'phone'}
                  placeholder="Your restaurant phone number"
                  required
                  errors={errors.phone?._errors}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: '24px' }}>
                <Input
                  value={businessInformation.email}
                  label="Restaurant Email Address"
                  onChange={handleInputChange}
                  name={'email'}
                  placeholder="Email address"
                  required
                  errors={errors.email?._errors}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: '24px' }}>
                <Input
                  value={businessInformation.website}
                  label="Restaurant Website"
                  onChange={handleInputChange}
                  name={'website'}
                  placeholder="Restaurant website URL"
                  required
                  errors={errors.website?._errors}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: '24px' }}>
                <Selector
                  placeholder={timeZone}
                  icon={IoIosArrowDown}
                  label="Time Zone"
                  items={timezones}
                  selectedItem={timeZone}
                  onSelectItem={setTimeZone}
                  className={styles.selector}
                  style={{ color: '#959a9e' }}
                />
              </div>
              <div className={styles.section}>
                <GoogleAutocomplete value={address} onChange={setAddress} label="Restaurant Address" />
              </div>
              <Button className={styles.submitButton} title={'UPDATE'} onClick={handleUpdate} />
            </div>

            <div className={styles.section}>
              <div className={styles.add_logo}>
                <div className={styles.logo_container} onClick={handleLogoClick}>
                  {businessLogoContent ? (
                    <img src={businessLogoContent} alt="Logo Preview" className={styles.logo_image} />
                  ) : (
                    <FaPlus className={styles.plus_icon} />
                  )}
                </div>
                <div className={styles.logo_footer} onClick={handleLogoClick}>
                  <p>Add Logo</p>
                </div>
                <input
                  type="file"
                  id="logoInput"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Information;
