import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getDashboardData } from '../api/dashboard';

// Thunks
export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async ({ start_date, end_date, token }) => {
    const response = await getDashboardData({ start_date, end_date, token });
    return response.data;
  },
);

const initialState = {
  dashboard_data: [],
  status: 'idle',
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchDashboardData.pending, setLoading)
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        setSucceeded(state);
        state.dashboard_data = action.payload;
      })
      .addCase(fetchDashboardData.rejected, setFailed);
  },
});

export default dashboardSlice.reducer;
