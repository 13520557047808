import React from 'react';

import Modal from '../../../../utilities/modal/modal';
import Button from '../../../../utilities/button/button';
import Input from '../../../../utilities/input/input';
import PageTitle from '../../../../utilities/page-title/page-title';

import { convertValueByType } from '../../../../utilities/utils';

import styles from './modals.module.scss';

function EditCategoryModal({
  category,
  setCategory,
  isModalOpen,
  closeModal,
  isEditMode,
  isLoading,
  saveChanges,
  errors,
}) {
  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    console.log(name, value);
    setCategory((prev) => ({
      ...prev,
      category: {
        ...prev.category,
        [name]: convertValueByType(value, type),
      },
    }));
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} className={styles.modal}>
      <section className={styles.category_edit_container}>
        <div className={styles.head}>
          <PageTitle name={isEditMode ? 'Edit Category' : 'New Category'} className={styles.title} />
          <p className={styles.description}>Create a new category</p>
        </div>
        <div className={styles.form_container}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <Input
                type="text"
                name="name"
                label="Category name"
                value={category?.name}
                onChange={handleInputChange}
                placeholder="Category name"
                required={true}
                errors={errors.edit?.name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                type="color"
                name="color"
                label="Category color"
                value={category?.color}
                onChange={handleInputChange}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              />
            </div>
          </div>

          <div className={styles.action_buttons}>
            <Button
              className={styles.button}
              variant={'secondary'}
              disabled={isLoading}
              title={'Cancel'}
              onClick={closeModal}
            />
            <Button
              className={styles.button}
              disabled={isLoading}
              title={isEditMode ? 'Save Changes' : 'Add category'}
              onClick={saveChanges}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default EditCategoryModal;
