import List from '../../../utilities/list/list';
import styles from './timesheet-details-list.module.scss';
import { MdModeEditOutline } from 'react-icons/md';

const TimesheetDetailsList = ({ employeeRecords, onEdit, isLoading }) => {
  if (!employeeRecords) return null;

  const columnConfig = [
    { key: '', header: '' },
    { key: 'date', header: 'Clock In Date' },
    { key: 'clock_in_time', header: 'Clock In Time' },
    { key: 'clock_out_date', header: 'Clock Out Date' },
    { key: 'clock_out_time', header: 'Clock Out Time' },
    { key: 'hourly_wages', header: 'Hourly Wage' },
    { key: 'total_in_time', header: 'Working Hours' },
    { key: 'total_wage', header: 'Total Wage' },
  ];

  const rowActions = [
    {
      icon: <MdModeEditOutline />,
      onClick: onEdit,
      label: 'Edit employee',
      className: styles.row_actions_button,
    },
  ];

  return <List data={employeeRecords} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default TimesheetDetailsList;
