import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import { IoFilter } from 'react-icons/io5';
import { preetyDate } from './helper';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function DynamicFilters() {
  const getStartOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const getEndOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  const [enableEmployee, setEnableEmployee] = useState(true);
  const [enableOrderType, setEnableOrderType] = useState(true);
  const [enableTerminal, setEnableTerminal] = useState(true);
  const [startDate, setStartDate] = useState(getStartOfDay(new Date()));
  const [endDate, setEndDate] = useState(getEndOfDay(new Date()));

  const orderTypes = [
    { id: 1, name: 'Order Type 1' },
    { id: 2, name: 'Order Type 2' },
  ];

  const employees = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Doe' },
  ];

  const terminals = [
    { id: 1, name: 'Terminal 1' },
    { id: 2, name: 'Terminal 2' },
  ];

  const handleInputChange = (e) => {
    console.log(e.target.value);
  };

  const selectorTemplate = (label, items, name, handleInputChange) => {
    return (
      <div className="form-group mb-3 down-arrow drop-down px-0">
        <label className="text-capitalize mb-0 text-gray fs-12 px-3" htmlFor="date_filter">
          {label}
        </label>
        <select
          name={name}
          id={name}
          onChange={handleInputChange}
          className="form-control border-0 h-auto filter-date date_filter font-700"
        >
          <option value="">All {label}</option>
          {items.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleNextClick = () => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    newStartDate.setDate(newStartDate.getDate() + 1);
    newEndDate.setDate(newEndDate.getDate() + 1);
    setStartDate(getStartOfDay(newStartDate));
    setEndDate(getEndOfDay(newEndDate));
  };

  const handlePrevClick = () => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    newStartDate.setDate(newStartDate.getDate() - 1);
    newEndDate.setDate(newEndDate.getDate() - 1);
    setStartDate(getStartOfDay(newStartDate));
    setEndDate(getEndOfDay(newEndDate));
  };

  return (
    <div className="row">
      <div className="col-2">
        <div className="form-group mb-3 down-arrow drop-down px-0">
          <label className="text-capitalize mb-0 text-gray fs-12 px-3" htmlFor="date_filter">
            Date Range
          </label>
          <select name="date_filter" id="date_filter" className="form-control border-0 h-auto filter-date date_filter">
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="This Week">This Week</option>
            <option value="Last Week">Last Week</option>
            <option value="Last Month">Last Month</option>
            <option value="Last Year">Last Year</option>
            <option value="Custom Date Range">Custom Date Range</option>
            <option hidden="" value="Custom Date Range ">
              Custom Date Range
            </option>
          </select>
        </div>
      </div>
      <div className="col-2">
        {enableEmployee && selectorTemplate('Employee', employees, 'employee_filter', handleInputChange)}
      </div>
      <div className="col-2">
        {enableOrderType && selectorTemplate('Order Types', orderTypes, 'order_type_filter', handleInputChange)}
      </div>
      <div className="col-2">
        {enableTerminal && selectorTemplate('Terminals', terminals, 'terminal_filter', handleInputChange)}
      </div>
      <div className="col-3"></div>
      <div className="col-1 p-2">
        <DropdownButton
          title={<IoFilter size={20} />}
          className="mt-2"
          style={{ zIndex: 999, position: 'fixed' }}
          autoClose="outside"
        >
          <Dropdown.Item onClick={() => setEnableEmployee(!enableEmployee)}>
            <Form.Check type={'checkbox'} checked={enableEmployee} label={'Employee'} />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setEnableOrderType(!enableOrderType)}>
            <Form.Check type={'checkbox'} checked={enableOrderType} label={'Order Type'} />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setEnableTerminal(!enableTerminal)}>
            <Form.Check type={'checkbox'} checked={enableTerminal} label={'Terminals'} />
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="col-12">
        <div className="card border-1 p-3">
          <div className="form-row">
            <button className="btn font-weight-bold" onClick={handlePrevClick}>
              {'<'}
            </button>
            <h5 className="font-weight-bold pt-2">
              {preetyDate(startDate)} - {preetyDate(endDate)}
            </h5>
            <button className="btn font-weight-bold" onClick={handleNextClick}>
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
