import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPrinterQueues } from '../../../../redux/printerQueuesSlice';

import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Button from '../../../../components/utilities/button/button';

import PrinterQueuesList from './printer-queues-list';

import styles from './printer-queues.module.scss';

const PrinterQueues = () => {
  const printersQueuesStatus = useSelector((state) => state.printerQueues.status);
  const printersQueues = useSelector((state) => state.printerQueues.printerQueues);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    if (token) {
      dispatch(fetchPrinterQueues({ token }));
    }
  }, [dispatch, token]);

  const handleClearPrinterQueue = () => {
    console.log('Clear...');
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <div className={styles.title_and_button}>
          <PageTitle name="Printer Queue" />
          <div>
            <Button title={'Clear Printer Queue'} onClick={handleClearPrinterQueue} />
          </div>
        </div>
      </div>
      <div className={styles.list_container}>
        <PrinterQueuesList queue={printersQueues} isLoading={printersQueuesStatus === 'loading'} />
      </div>
    </div>
  );
};

export default PrinterQueues;
