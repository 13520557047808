import { ReactComponent as NoEmployees } from '../../../assets/icons/no_employees.svg';
import Spinner from '../spinner/spinner';

import styles from './no-items.module.scss';

function NoItems({ title, description, icon: Icon, isLoading }) {
  return (
    <div className={styles.no_items}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Icon />
          <h5>{title}</h5>
          <h5>{description}</h5>
        </>
      )}
    </div>
  );
}

export default NoItems;
