import { api } from './base';
// import { api } from './mocked/end-of-day-report/mockedAxiosApi';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getDayReportSettings = (token) => {
  return handleApiResponse(() =>
    api.get('shift_report_configuration', {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const updateDayReportSettings = (updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`shift_report_configuration`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );
