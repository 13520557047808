import React from 'react';
import { displayTwoDigitWithDelimiter } from '../../components/helper';

const PaymentDetails = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    payment_details: [
      [
        {
          key: 'Amount Collected',
          value: 0,
        },
        {
          key: 'Total Refund',
          value: 0,
        },
      ],
    ],
  };

  return (
    <div>
      <h3 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        Payment Details
      </h3>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th width="25%">Detail</th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%">Refund</th>
              <th width="25%">Amount</th>
            </tr>
          </thead>
          <tbody className="eod_shift_report">
            {shiftReportData.payment_details && shiftReportData.payment_details.length > 0 ? (
              shiftReportData.payment_details.map((data, index) => {
                const klasss = data[0].key === 'Amount Collected' ? 'fw-700' : '';
                const klass = data[0].key === 'Total Refund' ? 'text-red' : '';
                return (
                  <tr key={index}>
                    <td className={klasss}>{data[0].key}</td>
                    <td></td>
                    <td></td>
                    <td className={`text-red ${klasss}`}>
                      {data[1].value ? `$${displayTwoDigitWithDelimiter(data[1].value)}` : data[1].value}
                    </td>
                    <td className={`${klass} ${klasss}`}>${displayTwoDigitWithDelimiter(data[0].value)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan="4">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentDetails;
