import React from 'react';
import styles from './refunds.module.scss';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import DateRangeFilter from '../components/date-range/date-range';
import ReportTable from './report-table';

function Refunds() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const fakeData = [
    {
      order: {
        created_at: new Date(),
        order_type: 'Online',
        employee: { full_name: 'John Doe' },
        customer: { full_name: 'Jane Smith' },
        short_receipt_url: '#',
      },
      payment: {
        id: '12345',
        payment_type: 'Credit Card',
        amount: 100.0,
        Card: true,
      },
      amount: 50.0,
      tax_refunded: 5.0,
      tips_refunded: 2.0,
      reason_for_refund: 'Product defect',
    },
    {
      order: {
        created_at: new Date(),
        order_type: 'Online',
        employee: { full_name: 'John Doe' },
        customer: { full_name: 'Jane Smith' },
        short_receipt_url: '#',
      },
      payment: {
        id: '12345',
        payment_type: 'Credit Card',
        amount: 100.0,
        Card: true,
      },
      amount: 50.0,
      tax_refunded: 5.0,
      tips_refunded: 2.0,
      reason_for_refund: 'Product defect',
    },
  ];

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Refunds'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
      </div>
      <div className="row">
        <ReportTable refundRecords={fakeData} />
      </div>
    </div>
  );
}

export default Refunds;
