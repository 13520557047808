import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../../../context/context';

import Modal from '../../../../utilities/modal/modal';

import Input from '../../../../utilities/input/input';
import Button from '../../../../utilities/button/button';
import PageTitle from '../../../../utilities/page-title/page-title';

import useFormValidation from '../../../../utilities/useFormValidation';
import { registrationSchema } from '../validation';

import { updateExistingDiscount } from '../../../../../redux/discountsSlice';

import styles from './discounts-edit-modal.module.scss';

function EditSalesTaxesModal({
  isModalOpen,
  setIsModalOpen,
  editableItem,
  setEditableItem,
  editableName,
  editableRate,
  setEditableName,
  setEditableRate,
}) {
  const validatedData = useMemo(
    () => ({
      name: editableName,
      percentage: editableRate,
    }),
    [editableName, editableRate],
  );

  const dispatch = useDispatch();

  const { token } = useAppContext();
  const { id, discount_type, location_id } = editableItem;
  const { errors, setErrors, validate } = useFormValidation(registrationSchema(discount_type));

  const handleSave = () => {
    if (validate(validatedData)) {
      dispatch(
        updateExistingDiscount({
          id: id,
          updatedDetails: {
            id: id,
            location_id: location_id,
            name: editableName,
            percentage: Number(editableRate),
            discount_type: discount_type,
          },
          token,
        }),
      );
      setIsModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditableItem({});
    setErrors({});
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} className={styles.edit_discount_modal}>
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <PageTitle name="Edit Discounts" className={styles.title} />
        </div>
        <div className={styles.modal_body}>
          <div className={styles.edit_discount_wrapper}>
            <Input
              label={'Discount Name'}
              value={editableName}
              onChange={(e) => setEditableName(e.target.value)}
              placeholder={'Discount Name'}
              required={true}
              className={styles.discount_name_input}
              errors={errors.name?._errors}
            />
          </div>
          <div className={styles.edit_discount_wrapper}>
            <Input
              label={'Discount Rate'}
              type="number"
              value={editableRate}
              onChange={(e) => setEditableRate(Number(e.target.value))}
              placeholder={`0.00${editableItem.discount_type === 'Dollar' ? '$' : '%'}`}
              required={true}
              className={styles.discount_percent_input}
              errors={errors.percentage?._errors}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <Button title="Update" onClick={handleSave} />
          <Button title="Cancel" onClick={handleCloseModal} />
        </div>
      </div>
    </Modal>
  );
}

export default EditSalesTaxesModal;
