import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getBusinessInformation, updateBusinessInformation } from '../api/information.js';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchBusinessInformation = createAsyncThunk('information/fetchBusinessInformation', async (token) => {
  const response = await getBusinessInformation(token);
  return response.data;
});

export const updateExistingBusinessInformation = createAsyncThunk(
  'information/updateBusinessInformation',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateBusinessInformation(id, updatedDetails, token);
      dispatch(addNotification({ message: 'Business information updated successfully', status: 'succeeded' }));
      return { id, updatedDetails };
    } catch (error) {
      dispatch(addNotification({ message: 'Failed to update business information', status: 'failed' }));
      throw error;
    }
  },
);

const initialState = {
  business_information: [],
  status: 'idle',
  error: null,
};

const businessInformationSlice = createSlice({
  name: 'business_information',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchBusinessInformation.pending, setLoading)
      .addCase(fetchBusinessInformation.fulfilled, (state, action) => {
        setSucceeded(state);
        state.business_information = action.payload;
      })
      .addCase(fetchBusinessInformation.rejected, setFailed)

      .addCase(updateExistingBusinessInformation.pending, setLoading)
      .addCase(updateExistingBusinessInformation.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingBusinessDetails = state.business_information.find((item) => item.business_id === id);
        if (existingBusinessDetails) {
          Object.assign(existingBusinessDetails, updatedDetails);
        }
      })
      .addCase(updateExistingBusinessInformation.rejected, setFailed);
  },
});

export default businessInformationSlice.reducer;
