import React, { useState } from 'react';

import { IoIosArrowDown } from 'react-icons/io';

import Button from '../../../../utilities/button/button';
import Modal from '../../../../utilities/modal/modal';
import Selector from '../../../../utilities/selector/selector';
import DatePicker from '../../../../utilities/date-picker/date-picker';

import useFormValidation from '../../../../utilities/useFormValidation';

import { convertStringToBoolean } from '../utils';
import { parseTimeToISO, parseTimeToHours } from '../../../../utilities/utils';
import { DATE_FORMAT, statusValues, daysOfTheWeek } from '../constants';
import { registrationSchema } from '../validation';

import styles from './add-new-hours.module.scss';

const AddNewHours = ({ isOpen, closeModal, createNewHours }) => {
  const initialState = {
    week_day: daysOfTheWeek[0].value,
    is_open: statusValues[0].value,
  };

  const [newHours, setNewHours] = useState(initialState);

  const { errors, validate } = useFormValidation(registrationSchema);

  const handleSetState = (value, field) => {
    setNewHours((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const handleDateChange = (value, fieldName) => {
    handleSetState(parseTimeToHours(value, DATE_FORMAT), fieldName);
  };

  const handleCreateHours = () => {
    if (validate(newHours)) {
      createNewHours(newHours);
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setNewHours(initialState);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <div className={styles.modal_content}>
        <h3 className={styles.add_title_modal}>Add day</h3>
        <div className={styles.wrapper_modal}>
          <div className={styles.row}>
            <div className={styles.field}>
              <Selector
                label="Day Name:"
                icon={IoIosArrowDown}
                items={daysOfTheWeek}
                selectedItem={newHours?.week_day}
                onSelectItem={(value) => handleSetState(value, 'week_day')}
                initialPlaceholderVisibility={false}
              />
            </div>
            <div className={styles.field}>
              <Selector
                label="Status:"
                icon={IoIosArrowDown}
                items={statusValues}
                selectedItem={newHours?.is_open}
                onSelectItem={(value) => handleSetState(convertStringToBoolean(value), 'is_open')}
                initialPlaceholderVisibility={false}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.field}>
              <DatePicker
                label="Opening Time:"
                selected={parseTimeToISO(newHours?.opening_time, DATE_FORMAT)}
                onChange={(value) => handleDateChange(value, 'opening_time')}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={DATE_FORMAT}
                wrapperClassName={styles.date_picker}
                placeholderText="09:00"
                timeCaption="Time"
                errors={errors.opening_time?._errors}
                required={true}
              />
            </div>
            <div className={styles.field}>
              <DatePicker
                label="Closing Time:"
                selected={parseTimeToISO(newHours?.closing_time, DATE_FORMAT)}
                onChange={(value) => handleDateChange(value, 'closing_time')}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={DATE_FORMAT}
                wrapperClassName={styles.date_picker}
                placeholderText="20:00"
                timeCaption="Time"
                errors={errors.closing_time?._errors}
                required={true}
              />
            </div>
          </div>
        </div>
        <Button title="Add" onClick={handleCreateHours} className={styles.add_button} />
      </div>
    </Modal>
  );
};

export default AddNewHours;
