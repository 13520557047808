import { api } from './base';
// import { api } from './mocked/kitchen-receipt-settings/mockedAxiosApi';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getReceiptSettings = (token) => {
  return handleApiResponse(() =>
    api.get('kitchen_receipts', {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getReceiptSetting = (id, token) => {
  return handleApiResponse(() =>
    api.get(`kitchen_receipts/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const addReceiptSettings = (newReceiptSetting, token) =>
  handleApiResponse(() =>
    api.post(`kitchen_receipts`, newReceiptSetting, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateReceiptSettings = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`kitchen_receipts/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteReceiptSettings = (id, token) =>
  handleApiResponse(() =>
    api.delete(`loyalty_programs/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );
