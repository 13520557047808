import React from 'react';
import ReactTooltip from 'react-tooltip';
import { displayTwoDigitWithDelimiter } from '../../components/helper';
import icInfo from './../../../../../assets/svg/ic_info.svg';

const SalesSummary = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    sales_summary: [
      {
        key: 'Gross Sales',
        value: 0.0,
      },
      {
        key: 'Discounts',
        value: 0.0,
      },
      {
        key: 'Refunds',
        value: 0.0,
      },
      {
        key: 'Net Sales',
        value: 0.0,
      },
      {
        key: 'Taxes & Fees',
        value: 0.0,
      },
      {
        key: 'Refunded Tax & Fees',
        value: 0.0,
      },
      {
        key: 'Tips',
        value: 0.0,
      },
      {
        key: 'Tips Refunded',
        value: 0.0,
      },
      {
        key: 'Service Charges',
        value: 0.0,
      },
      {
        key: 'Service Charges Refunded',
        value: 0.0,
      },
      {
        key: 'Loyalty Rewards',
        value: 0.0,
      },
      {
        key: 'Cash Discount',
        value: 0.0,
      },
      {
        key: 'Amount Collected',
        value: 0.0,
      },
      {
        key: 'Net Sales + Taxes',
        value: 0.0,
      },
      {
        key: 'Total Tips & Service Charge',
        value: 0.0,
      },
      {
        key: 'Net Tips & Service Charge',
        value: 0.0,
      },
    ],
  };
  return (
    <>
      <h3 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        Sales Summary
      </h3>
      <div className="admin-table table-striped-main bg-white table-responsive">
        <table className="table table-striped table-striped-reverse">
          <tbody>
            <tr>
              <td className="text-left fs-14 fw-600">
                Gross Sales
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of item sales, including extras such as modifiers. This is the gross subtotal before discounts and refunds. Does not include tips, taxes, service charges."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[0].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Discounts
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of discounts applied to orders and/or individual items. Does not include refunded discounts."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right text-red fs-14">
                -$
                {displayTwoDigitWithDelimiter(shiftReportData.sales_summary[1].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Refunds
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of refunds, including manual refunds, applied against orders and/or individual items. Does not include voided payments."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right text-red fs-14">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[2].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14 fw-600">
                Net Sales
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Amount of item sales remaining after all discounts, refunds and loyalty rewards have been deducted. Does not include tips, taxes, service charges."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[3].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Taxes & Fees
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of taxes and flat fees collected on all items revenue."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[4].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Refunded Tax & Fees
                <img src={icInfo} className="ml-1" alt="" data-tip="Total Tax & Fees Refunded." data-place="right" />
                <ReactTooltip />
              </td>
              <td className="text-right text-red fs-14">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[5].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14 fw-600">
                Tips
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of tips collected. Does not include service charges or other fees. Also referred to as 'Net Tips'."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[6].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Tips Refunded
                <img src={icInfo} className="ml-1" alt="" data-tip="Total tips Refunded." data-place="right" />
                <ReactTooltip />
              </td>
              <td className="text-right text-red fs-14">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[7].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Service Charges
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of service charges. Does not include tips."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[8].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">
                Service Charges Refunded
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="Total amount of service charges that were refunded."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right text-red fs-14">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[9].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14">Loyalty Rewards</td>
              <td className="text-right text-red fs-14">
                -$
                {displayTwoDigitWithDelimiter(shiftReportData.sales_summary[10].value)}
              </td>
            </tr>
            {shiftReportData.sales_summary[11].value > 0.0 &&
              shiftReportData.sales_summary[11].key === 'Cash Discount' && (
                <tr>
                  <td className="text-left fs-14">Cash Discount</td>
                  <td className="text-right text-red fs-14">
                    -$
                    {displayTwoDigitWithDelimiter(shiftReportData.sales_summary[11].value)}
                  </td>
                </tr>
              )}
            {shiftReportData.sales_summary[11].value > 0.0 &&
              shiftReportData.sales_summary[11].key === 'Sur Charge' && (
                <tr>
                  <td className="text-left fs-14">Sur Charge</td>
                  <td className="text-right text-red fs-14">
                    ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[11].value)}
                  </td>
                </tr>
              )}
            <tr>
              <td className="text-left fs-14 fw-600">
                Amount Collected
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="The final sale amount collected including taxes, tips, Sur Charge, Cash Discount and service charges."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[12].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14 fw-600">
                Net Sales + Taxes
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="The total amount of net sales and collected tax."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[13].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14 fw-600">
                Total Tips & Service Charge
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="The amount of tip and service charges."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[14].value)}
              </td>
            </tr>
            <tr>
              <td className="text-left fs-14 fw-600">
                Net Tips & Service Charge
                <img
                  src={icInfo}
                  className="ml-1"
                  alt=""
                  data-tip="The amount of collected tip and service charges. this not include refunded tips."
                  data-place="right"
                />
                <ReactTooltip />
              </td>
              <td className="text-right fs-14 fw-600">
                ${displayTwoDigitWithDelimiter(shiftReportData.sales_summary[15].value)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalesSummary;
