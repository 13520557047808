import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DatePicker from '../../../../utilities/date-picker/date-picker';
import Button from '../../../../utilities/button/button';

import { FaRegCalendarAlt } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';

import clsx from 'clsx';
import styles from './date-range.module.scss';

const DATE_FORMAT = 'MM/dd/yyyy';

const DateRangeFilter = ({ startDate, endDate, onUpdateParams, showPreset = false }) => {
  const navigate = useNavigate();

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const handleOpenReportSetting = () => {
    navigate('/report_setting_account_settings');
  };

  const handleUpdateDate = () => {
    onUpdateParams({ start_date: localStartDate, end_date: localEndDate });
  };
  const presets = ['Today', 'Yesterday', 'This Week', 'Last Week', 'Last Month', 'Last Year', 'Custom Date Range'];
  const [preset, setPreset] = useState('Custom Date Range');

  const handlePresetChange = (event) => {
    const selectedPreset = event.target.value;
    setPreset(selectedPreset);

    const now = new Date();
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (selectedPreset) {
      case 'Today':
        newStartDate = new Date(now.setHours(0, 0, 0, 0));
        newEndDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case 'Yesterday':
        newStartDate = new Date(now.setDate(now.getDate() - 1));
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case 'This Week':
        newStartDate = new Date(now.setDate(now.getDate() - now.getDay()));
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date(now.setDate(now.getDate() + (6 - now.getDay())));
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'Last Week':
        newStartDate = new Date(now.setDate(now.getDate() - now.getDay() - 7));
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date(now.setDate(now.getDate() + (6 - now.getDay())));
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'Last Month':
        newStartDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        newEndDate = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'Last Year':
        newStartDate = new Date(now.getFullYear() - 1, 0, 1);
        newEndDate = new Date(now.getFullYear() - 1, 11, 31);
        break;
      default:
        break;
    }

    setLocalStartDate(newStartDate);
    setLocalEndDate(newEndDate);
    onUpdateParams({ start_date: newStartDate, end_date: newEndDate });
  };

  return (
    <div className={styles.date_range}>
      <div className={styles.head}>
        <h4 className={styles.title}>Date Range</h4>
        <div className={styles.date_settings} onClick={handleOpenReportSetting}>
          <div className={styles.settings_icon}>
            <IoMdSettings />
          </div>
          <span className={styles.text}>Change default reporting time</span>
        </div>
      </div>
      {showPreset && (
        <ul className={styles.presets}>
          {presets.map((presetOption) => (
            <li
              key={presetOption}
              className={clsx(styles.tab_button, preset === presetOption ? styles.active : '')}
              onClick={() => handlePresetChange({ target: { value: presetOption } })}
            >
              {presetOption}
            </li>
          ))}
        </ul>
      )}
      <div className={styles.date_pickers}>
        <div className={styles.fields}>
          <div className={styles.field}>
            <DatePicker
              selected={localStartDate}
              onChange={(date) => setLocalStartDate(date)}
              label="From Date"
              prefixIcon={<FaRegCalendarAlt size={24} />}
              dateFormat={DATE_FORMAT}
            />
          </div>
          <div className={styles.field}>
            <DatePicker
              selected={localEndDate}
              onChange={(date) => setLocalEndDate(date)}
              label="To Date"
              prefixIcon={<FaRegCalendarAlt size={24} />}
              dateFormat={DATE_FORMAT}
            />
          </div>
        </div>
        <div className={styles.update_button}>
          <Button title={'Go Reports'} onClick={handleUpdateDate} style={{ margin: 0 }} />
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
