import React, { useState } from 'react';

import infoIcon from '../../../../assets/img/ic_info.svg';
import styles from './metric.module.scss';

function Metric({ item }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const formattedValue = `$${item.value.toFixed(2)}`;

  return (
    <div className={styles.metric}>
      <img src={item.icon} alt={`${item.name} icon`} className={styles.icon} />
      <div className={styles.value}>{formattedValue}</div>
      <div className={styles.title_wrapper}>
        <div className={styles.title}>{item.name}</div>
        {item.info && (
          <div
            className={styles.info_icon}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <img src={infoIcon} alt="info_icon" />
            {showTooltip && <div className={styles.info_tooltip}>{item.info}</div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default Metric;
