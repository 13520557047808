import React from 'react';
import { displayTwoDigitWithDelimiter } from '../components/helper';

export default function ReportTable({ taxesRecords, title }) {
  return (
    <>
      <h3 className="text-darkgray1 fw-500 fs-15 border-bottom mb-4 pb-2 ">{title}</h3>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th width="25%">Tax or Fee</th>
              <th width="25%">Taxable Amount</th>
              <th width="25%">Tax Rate or Fee</th>
              <th width="25%">Tax or Fee Collected</th>
            </tr>
          </thead>
          <tbody className="order_item_taxes_list">
            {taxesRecords.length > 0 ? (
              taxesRecords.map((itemTax, index) => (
                <tr key={index}>
                  <td>{itemTax[0]}</td>
                  <td>${displayTwoDigitWithDelimiter(itemTax[1][3])}</td>
                  <td>
                    {itemTax[1][2] === 'Percentage' ? (
                      <>{displayTwoDigitWithDelimiter(itemTax[1][1])}%</>
                    ) : (
                      <>${displayTwoDigitWithDelimiter(itemTax[1][1])}</>
                    )}
                  </td>
                  <td>${displayTwoDigitWithDelimiter(itemTax[1][0])}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="4">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
