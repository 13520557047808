import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal, hideAllModals } from '../../../redux/modalSlice';

import Button from '../button/button';

import { RxCross2 } from 'react-icons/rx';
import clsx from 'clsx';
import styles from './modal-template.module.scss';

const Modal = () => {
  const modals = useSelector((state) => state.modal.openModals);
  const modalRefs = useRef({});

  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = useRef(location);

  useEffect(() => {
    if (previousLocation.current !== location) {
      dispatch(hideAllModals());
    }
    previousLocation.current = location;
  }, [location, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(modalRefs.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target) && !modals[key]?.data.isMandatory) {
          dispatch(hideModal(key));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, modals]);

  return (
    <>
      {Object.keys(modals).length > 0 &&
        Object.entries(modals).map(([key, value]) => {
          return createPortal(
            <div key={key} className={clsx(styles.modal, styles[value.data.className])} style={value.data.style}>
              <div ref={(el) => (modalRefs.current[key] = el)} className={styles.modal_content}>
                {value.data.title && <div className={styles.modal_title}>{value.data.title}</div>}
                {value.data.type === 'functional' && value.data.children ? (
                  value.data.children
                ) : (
                  <>
                    {value.data.showCross && (
                      <div className={styles.modal_cross_icon}>
                        <RxCross2 onClick={() => dispatch(hideModal(key))} />
                      </div>
                    )}
                    {value.data.message && <div className={styles.modal_message}>{value.data.message}</div>}
                    <div className={styles.modal_actions}>
                      {value.data.actions.map((action, index) => (
                        <Button
                          key={index}
                          title={action.title}
                          onClick={action.onAction}
                          variant={action.button_variant}
                          style={{ marginBottom: 0 }}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>,
            document.getElementById('root'),
          );
        })}
    </>
  );
};

export default Modal;
