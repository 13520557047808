import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCategories } from '../../../../redux/categoriesSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import { useAppContext } from '../../../../context/context';
import useFormValidation from '../../../utilities/useFormValidation';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Selector from '../../../../components//utilities/selector/selector';
import Search from '../../../../components//utilities/search/search';
import Button from '../../../../components/utilities/button/button';
import Input from '../../../../components/utilities/input/input';

import CategoriesList from './category-list';
import CategoryCards from './components/category-cards';
import EditCategoryModal from './modals/edit-category-modal';
import AssignItemsCategoryModal from './modals/assign-items-modal';

import Plus from '../../../../assets/icons/plus.svg';

import { registrationSchema } from './validation';

import styles from './categories.module.scss';
import useCategoryManager from './hooks/useCategoryManager';

const SELECTOR_OPTIONS = [
  { value: 'null', label: 'All' },
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
];

function Categories() {
  const status = useSelector((state) => state.categories.status);
  const categories = useSelector((state) => state.categories.categories);

  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const {
    category,
    setCategory,
    isEditMode,
    isEditModalOpen,
    handleCopyCategoty,
    handleDeleteCategory,
    handleReorderCategories,
    handleToggleActive,
    handleSaveCategory,
    handleShowAddModal,
    handleShowEditModal,
    handleModalClose,
  } = useCategoryManager(categories);

  const [editableCategoryId, setEditableCategoryId] = useState(null);

  const [isAssignItemsModal, setIsAssignItemsModal] = useState(false);

  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('null');

  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  const { errors, validate } = useFormValidation(registrationSchema);

  const applyFilters = useCallback(() => {
    let filtered = [...categories].sort((a, b) => a.sort - b.sort);

    if (selectedStatus !== 'null') {
      filtered = filtered.filter((category) => {
        const isActive = selectedStatus === 'true';
        return category.active === isActive;
      });
    }

    if (searchTerm !== '') {
      filtered = filtered.filter((category) => category.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    setFilteredCategories(filtered);
  }, [categories, selectedStatus, searchTerm]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    applyFilters();
  }, [categories, selectedStatus, searchTerm, applyFilters]);

  const handleSetResult = (term) => {
    setSearchTerm(term);
  };

  const handleFilterByStatus = (selectedValue) => {
    setSelectedStatus(selectedValue);
  };

  // const handleDeleteAllSelected = () => {
  //   if (selectedCategoriesIds.length > 0) {
  //     dispatch(
  //       showModal({
  //         modalId: 'modal-delete-selected-categories',
  //         data: {
  //           type: 'confirmation',
  //           title: 'Delete Confirmation',
  //           message: 'Are you sure you want to delete selected categories?',
  //           actions: [
  //             {
  //               title: 'Delete',
  //               onAction: () => {
  //                 selectedCategoriesIds.forEach((element) => {
  //                   dispatch(removeCategory({ id: element, token }));
  //                 });
  //                 dispatch(hideModal('modal-delete-selected-categories'));
  //               },
  //             },
  //             {
  //               title: 'Cancel',
  //               button_variant: 'secondary',
  //               onAction: () => dispatch(hideModal('modal-delete-selected-categories')),
  //             },
  //           ],
  //         },
  //       }),
  //     );
  //   }
  // };

  const handleClickAssignItemsLink = useCallback((id) => {
    setIsAssignItemsModal(true);
    setCurrentCategoryId(id);
    setEditableCategoryId(id);
  }, []);

  const handleSearchClean = () => {
    setSearchTerm('');
  };
  console.log(category);
  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <div className={styles.title_and_button}>
          <PageTitle name={'Categories'} />
          <div>
            <Button
              className={styles.add_button}
              title={'Add New Category'}
              onClick={handleShowAddModal}
              prefixIcon={<img src={Plus} alt="Plus_icon" />}
            />
          </div>
        </div>
        <div className={styles.list_controller}>
          {/* <div className={styles.role_selector}>
              <Selector
                icon={IoIosArrowDown}
                items={SELECTOR_OPTIONS}
                selectedItem={selectedStatus}
                onSelectItem={handleFilterByStatus}
              />
            </div> */}
          <div className={styles.search}>
            <Search
              placeholder="Search..."
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>

          {/* <div className={styles.buttons}>
            <div className={styles.new_category_name}>
              <Input
                type="text"
                name={'name'}
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                placeholder={'Add New Category Name'}
                required={true}
                errors={errors.new?.name?._errors}
              ></Input>
            </div>
           
            <Button className={styles.delete_button} title={'Delete Selected'} onClick={handleDeleteAllSelected} />
          </div> */}
        </div>
      </div>
      <div className={styles.list_container}>
        {/* <CategoriesList
          categories={filteredCategories}
          onCopy={handleCopyCategoty}
          onEdit={handleShowEditModal}
          onDelete={handleDeleteCategory}
          onToggleActive={handleToggleActive}
          onAssignItemsClick={handleClickAssignItemsLink}
          onDrag={handleReorderCategories}
          selectedCategoriesIds={selectedCategoriesIds}
          setSelectedCategoriesIds={setSelectedCategoriesIds}
          isLoading={status === 'loading'}
          isFailed={status === 'failed'}
        /> */}
        <CategoryCards categories={categories} onClick={(id) => console.log('category - ', id)} />
      </div>
      <EditCategoryModal
        category={category}
        setCategory={setCategory}
        saveChanges={handleSaveCategory}
        isModalOpen={isEditModalOpen}
        isEditMode={isEditMode}
        closeModal={handleModalClose}
        errors={errors}
      />
      {/* <AssignItemsCategoryModal
        categories={filteredCategories}
        isAssignItemsModal={isAssignItemsModal}
        setIsAssignItemsModal={setIsAssignItemsModal}
        handleCloseModal={handleModalClose}
        handleUpdateCategory={handleUpdateCategory}
      /> */}
    </div>
  );
}

export default Categories;
