import { useState, useEffect } from 'react';

import Button from '../../../../utilities/button/button';
import Input from '../../../../utilities/input/input';

import { BsCurrencyDollar } from 'react-icons/bs';

import styles from './total-range.module.scss';

function TotalRangeFilter({ rangeFrom = 0, rangeTo = 0, onUpdate }) {
  const [amount_from, setAmount_from] = useState(0);
  const [amount_to, setAmount_to] = useState(0);

  const handleUpdate = () => {
    onUpdate({ amount_from, amount_to });
  };

  useEffect(() => {
    setAmount_from(rangeFrom);
    setAmount_to(rangeTo);
  }, [rangeFrom, rangeTo]);

  return (
    <div className={styles.total_range_filter}>
      <div className={styles.description}>Show transactions with total between:</div>
      <div className={styles.inputs}>
        <div className={styles.input}>
          <Input
            value={amount_from}
            onChange={(e) => setAmount_from(parseInt(e.target.value))}
            type="number"
            prefixIcon={<BsCurrencyDollar style={{ width: '1.2rem' }} />}
          />
        </div>
        <span className={styles.text_separator}>and</span>
        <div className={styles.input}>
          <Input
            value={amount_to}
            onChange={(e) => setAmount_to(parseInt(e.target.value))}
            type="number"
            prefixIcon={<BsCurrencyDollar style={{ width: '1.2rem' }} />}
          />
        </div>
      </div>
      <div className="">
        <Button title={'Update'} onClick={handleUpdate} style={{ margin: 0 }} />
      </div>
    </div>
  );
}

export default TotalRangeFilter;
