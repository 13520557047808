import React from 'react';
import { displayTwoDigitWithDelimiter } from '../../components/helper';

const TipDetails = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    tip_details: [
      [
        {
          key: 'Total Tips',
          value: 0,
        },
        {
          key: 'Total Refund',
          value: 0,
        },
      ],
      [
        {
          key: 'Cash',
          value: 0,
        },
        {
          key: 'Cash Refund',
          value: 0,
        },
      ],
      [
        {
          key: 'Credit Card',
          value: 0,
        },
        {
          key: 'Credit Card Refund',
          value: 0,
        },
      ],
      [
        {
          key: 'Gift Card',
          value: 0,
        },
        {
          key: 'Gift Card Refund',
          value: 0,
        },
      ],
    ],
  };

  return (
    <div>
      <h3 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        Tip Details
      </h3>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th width="25%">Detail</th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%">Refund</th>
              <th width="25%">Amount</th>
            </tr>
          </thead>
          <tbody className="eod_shift_report">
            {shiftReportData.tip_details && shiftReportData.tip_details.length > 0 ? (
              shiftReportData.tip_details.map((data, index) => {
                const klasss = data[0].key === 'Total Tips' ? 'fw-700' : '';
                return (
                  <tr key={index}>
                    <td className={klasss}>{data[0].key}</td>
                    <td></td>
                    <td></td>
                    <td className={`text-red ${klasss}`}>
                      {data[1].value ? `$${displayTwoDigitWithDelimiter(data[1].value)}` : data[1].value}
                    </td>
                    <td className={klasss}>${displayTwoDigitWithDelimiter(data[0].value)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan="4">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TipDetails;
