import React from 'react';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import styles from './switch.module.scss';

const Switch = ({ id, value = true, roleName, isActive, defaultChecked, onSwitch, className, labelClassName }) => {
  const handleOnSwitch = (e) => {
    onSwitch(id, e.target.checked);
  };

  return (
    <label className={clsx(styles.switch, labelClassName)}>
      <input
        id={id ?? nanoid()}
        type="checkbox"
        className={className}
        value={value}
        checked={isActive}
        defaultChecked={defaultChecked}
        onChange={handleOnSwitch}
      />
      <span className={clsx(styles.slider, styles.round)}></span>
    </label>
  );
};

export default Switch;
