import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllItems } from '../api/items';

import { addNotification } from './tooltipSlice';

export const fetchAllItems = createAsyncThunk('reports/fetchSalesReport', async ({ token }, { dispatch }) => {
  try {
    const response = await getAllItems({ token });
    return response.data;
  } catch (error) {
    dispatch(addNotification({ message: 'Failed to fetch items', status: 'failed' }));
    throw error;
  }
});

const initialState = {
  items: [],
  status: 'idle',
  error: null,
};

const itemsSetSlice = createSlice({
  name: 'itemsSet',
  initialState,
  reducers: {
    clearItems(state) {
      state.items = [];
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };
    builder
      .addCase(fetchAllItems.pending, setLoading)
      .addCase(fetchAllItems.fulfilled, (state, action) => {
        setSucceeded(state);
        state.items = action.payload;
      })
      .addCase(fetchAllItems.rejected, setFailed);
  },
});
export default itemsSetSlice.reducer;
