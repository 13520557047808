import React from 'react';

const ServiceChargeDetails = ({ shiftReportData }) => {
  shiftReportData = shiftReportData || {
    service_charge_details: [
      {
        key: 'Total Service Charge',
        value: 0,
      },
      {
        key: 'Refund SC by Card',
        value: 0,
      },
      {
        key: 'Refund SC by Cash',
        value: 0,
      },
    ],
  };

  const displayTwoDigitWithDelimiter = (value) => {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  return (
    <div>
      <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
        SERVICE CHARGE DETAILS
      </h4>
      <div className="admin-table bg-white table-responsive mt-4">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th width="25%">Detail</th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%"></th>
              <th width="25%">Amount</th>
            </tr>
          </thead>
          <tbody className="eod_shift_report">
            {shiftReportData.service_charge_details && shiftReportData.service_charge_details.length > 0 ? (
              shiftReportData.service_charge_details.map((data, index) => {
                const klasss = data.key === 'Total Service Charge' ? 'fw-700' : '';
                const refundClass =
                  data.key === 'Refund SC by Card' || data.key === 'Refund SC by Cash' ? 'text-red' : '';
                return (
                  <tr key={index}>
                    <td className={klasss}>{data.key}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className={`${klasss} ${refundClass}`}>${displayTwoDigitWithDelimiter(data.value)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan="4">
                  No Records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceChargeDetails;
