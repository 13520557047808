import { navConfig } from '../../../assets/scripts/pages';
import Logout from '../../../assets/icons/Logout';

import { useNavigationLogic } from './hooks/useNavigationLogic';
import { MenuItem } from './components/menu-item';
import { SubMenu } from './components/sub-menu';

import clsx from 'clsx';
import styles from './sidebar.module.scss';

const Sidebar = ({ onLogout, activeParent, setActiveParent, isMobile, isSidebarOpen, setIsSidebarOpen }) => {
  const { lastUrlSegment, activeParentConfig, handleParentClick, handleLinkClick } = useNavigationLogic({
    activeParent,
    setActiveParent,
    setIsSidebarOpen,
    isMobile,
  });

  return (
    <aside
      className={clsx(styles.sidebar_container, {
        [styles.expanded]: isSidebarOpen,
        [styles.collapsed]: !isSidebarOpen,
        [styles.hidden]: isMobile,
      })}
    >
      <div className={styles.sidebar}>
        <nav>
          <ul>
            {navConfig.map((elem) =>
              elem.isParent ? (
                <ParentMenuItem
                  key={elem.link}
                  elem={elem}
                  activeParentConfig={activeParentConfig}
                  lastUrlSegment={lastUrlSegment}
                  isSidebarOpen={isSidebarOpen}
                  handleParentClick={handleParentClick}
                  handleLinkClick={handleLinkClick}
                />
              ) : (
                <RegularMenuItem
                  key={elem.link}
                  elem={elem}
                  lastUrlSegment={lastUrlSegment}
                  isSidebarOpen={isSidebarOpen}
                  handleLinkClick={handleLinkClick}
                />
              ),
            )}
          </ul>
        </nav>
        <LogoutButton onLogout={onLogout} isSidebarOpen={isSidebarOpen} />
      </div>
    </aside>
  );
};

const ParentMenuItem = ({
  elem,
  activeParentConfig,
  lastUrlSegment,
  isSidebarOpen,
  handleParentClick,
  handleLinkClick,
}) => {
  const parentLink = elem.link;
  const firstChildLink = elem.subItems[0].link || elem.subItems[1].link;
  const fullLink = `${parentLink}${firstChildLink}`;
  const isParentActive = activeParentConfig?.link === parentLink;

  return (
    <li className={clsx(styles.menu_item, styles.parent, { [styles.active]: isParentActive })}>
      <MenuItem
        item={elem}
        link={fullLink}
        callback={() => handleParentClick(parentLink, fullLink)}
        activeStyle={isParentActive}
        isOpen={isSidebarOpen}
      />
      {isSidebarOpen && parentLink === activeParentConfig?.link && (
        <SubMenu
          items={elem.subItems}
          parentLink={elem.link}
          lastUrlSegment={lastUrlSegment}
          handleLinkClick={handleLinkClick}
          isSidebarOpen={isSidebarOpen}
        />
      )}
    </li>
  );
};

const RegularMenuItem = ({ elem, lastUrlSegment, isSidebarOpen, handleLinkClick }) => {
  const isActive = lastUrlSegment === elem.link;

  return (
    <li className={clsx(styles.menu_item, { [styles.active]: isActive })}>
      <MenuItem item={elem} link={elem.link} callback={() => handleLinkClick(elem.link, true)} isOpen={isSidebarOpen} />
    </li>
  );
};

const LogoutButton = ({ onLogout, isSidebarOpen }) => (
  <div className={styles.logout}>
    <MenuItem item={{ icon: Logout, name: 'Logout' }} callback={onLogout} isOpen={isSidebarOpen} />
  </div>
);

export default Sidebar;
