import React, { useEffect, useState } from 'react';
import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import { useAppContext } from '../../../../context/context';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import usePagination from '../../business/order-types/usePagination';
import { DEFAULT_PAGINATION, formatDateTime, PAGE_SIZE_OPTIONS } from '../components/helper';
import { fetchOrderItemsReport } from '../../../../redux/reportsSetSlice';
import { DropdownTypes, ReportFilterPanel } from '../components/report-filter-panel';
import { DateRangeFilter } from '../components/date-range';
import ReportTable from './ReportTable';

import styles from './order-items.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from '../../../utilities/pagination/pagination';

function OrderItems() {
  const { token } = useAppContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [itemId, setItemId] = useState('');
  const [modifierId, setModifierId] = useState('');
  const [taxId, setTaxId] = useState('');
  // Available values : Today, Yesterday, This Week, Last Week, Last Month, Last Year, Custom Date Range
  const [dateFilter, setDateFilter] = useState('');

  const orderItemsReport = useSelector((state) => state.reports.orderItemsReport.order_items);
  const invoice_items = useSelector((state) => state.reports.orderItemsReport.invoice_items);
  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(orderItemsReport, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

  useEffect(() => {
    dispatch(
      fetchOrderItemsReport({
        token,
        dateFilter,
        startDate: formatDateTime(startDate),
        endDate: formatDateTime(endDate),
        search,
        employeeId,
        categoryId,
        itemId,
        modifierId,
        taxId,
        page,
        perPage,
      }),
    );
  }, [startDate, endDate, page, perPage, search, employeeId, categoryId, itemId, modifierId, taxId, dateFilter]);

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const fakeOrderItems = [
    {
      id: 1,
      created_at: new Date(),
      item_name: 'Item 1',
      quantity: 2,
      total_price: 100,
      category: { name: 'Category 1' },
      discount_amount: 10,
      order_item_taxes: [{ tax_total_amount: 5 }],
      order_id: 123,
      order: { offline_id: 456, short_receipt_url: 'http://example.com/receipt' },
    },
  ];

  const fakeInvoiceItems = [
    {
      id: 2,
      created_at: new Date(),
      item_name: 'Invoice Item 1',
      total_price: 200,
      category: { name: 'Category 2' },
      discount_amount: 20,
      ordered_item_taxes: [{ tax_total_amount: 10 }],
      invoice: { id: 789 },
    },
  ];

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Order Items'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel
          searchShow={false}
          dropdowns={[
            DropdownTypes.employee,
            DropdownTypes.item_id,
            DropdownTypes.category_id,
            DropdownTypes.tax_id,
            DropdownTypes.modifier_id,
          ]}
          searchTitle={'Search Items by ID or Name.'}
          onUpdateParams={(params) => {
            if (params.hasOwnProperty('employee_id')) setEmployeeId(params.employee_id);
            if (params.hasOwnProperty('category_id')) setCategoryId(params.category_id);
            if (params.hasOwnProperty('item_id')) setItemId(params.item_id);
            if (params.hasOwnProperty('modifier_id')) setModifierId(params.modifier_id);
            if (params.hasOwnProperty('tax_id')) setTaxId(params.tax_id);
          }}
        />
      </div>
      <div className={clsx('mb-4', styles.boxBody, styles.borderBox)}>
        <DateRangeFilter
          endDate={endDate}
          startDate={startDate}
          showPreset={true}
          onChange={(data) => {
            setStartDate(data.startDate);
            setEndDate(data.endDate);
          }}
        />
      </div>
      <div className="bg-white table-responsive mt-4">
        <ReportTable orderItems={orderItemsReport} invoiceItems={invoice_items} />
        <Pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          numberOfItems={orderItemsReport?.length}
          currentPage={page}
          currentPageSize={perPage}
          onPageSizeChange={handleSelectPageCount}
          inputPaginationControl={{
            isActive: true,
            isPrevActive,
            isNextActive,
          }}
          onPrev={handleClickPrev}
          onNext={handleClickNext}
        />
      </div>
    </div>
  );
}

export default OrderItems;
