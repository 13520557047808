import React from 'react';

import HeaderReport from '../components/HeaderReport/header-report';
import clsx from 'clsx';
import DynamicFilters from '../components/dynamic-filters';
import ReportTable from './report-table';
import styles from './sales-report.module.scss';

function SalesReport() {
  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const fakeData = {
    gross_sale: 10000,
    discount: 500,
    refund: 200,
    wastage: 100,
    net_sales: 9200,
    total_tax: 800,
    tax_refund: 50,
    total_tips: 300,
    tips_refund: 20,
    total_service_charge: 150,
    service_charge_refund: 10,
    loyalty_rewards: 50,
    total_cash_discount: 30,
    total_surcharge: 40,
    total_amount_collected: 10400,
    tax_summary: [
      ['Tax1', [100, 10, 'Percentage', 1000]],
      ['Tax2', [200, 20, 'Flat', 2000]],
    ],
    discount_summary: [
      ['Discount1', 100],
      ['Discount2', 200],
    ],
    cash_before_tipouts: 5000,
    cash_service_charge: 100,
    card_service_charge: 50,
    credit_tips: 150,
    total_cash: 5200,
    payment_data: [
      [
        { value: 'Cash' },
        { value: 10 },
        { value: 1000 },
        { value: 100 },
        { value: 50 },
        { value: '5%' },
        { value: 20 },
        { value: 1180 },
      ],
      [
        { value: 'Card' },
        { value: 20 },
        { value: 2000 },
        { value: 200 },
        { value: 100 },
        { value: '10%' },
        { value: 40 },
        { value: 2340 },
      ],
      [
        { value: 'Total' },
        { value: 30 },
        { value: 3000 },
        { value: 300 },
        { value: 150 },
        { value: '15%' },
        { value: 60 },
        { value: 3510 },
      ],
    ],
    total_guests: 100,
    avg_per_guest: 50,
    total_payments: 30,
    avg_per_payment: 100,
    total_orders: 25,
    avg_per_order: 120,
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Sales Report'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx('mb-4', styles.boxBody)}>
        <DynamicFilters />
      </div>
      <ReportTable salesReport={fakeData} />
    </div>
  );
}

export default SalesReport;
