import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { fetchOption, updateExistingOption } from '../../../../redux/optionSetsSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';
import DynamicFormFields from '../../../utilities/dynamic-form-fields/dynamic-form-fields ';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { registrationSchema } from './validation';
import useFormValidation from '../../../utilities/useFormValidation';

import { isObjectEmpty, isArrayEmpty } from '../../../utilities/utils';

import OptionForm from './forms/option-form';

import clsx from 'clsx';
import styles from './options.module.scss';

const getOptionSet = (options, id) => {
  return options.find((option) => option.id === parseFloat(id));
};

const EditOptionSet = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const optionSetsTypesStatus = useSelector((state) => state.optionSets.status);
  const optionSets = useSelector((state) => state.optionSets.optionSets);
  const currentOptionSet = useSelector((state) => state.optionSets.currentOptionSet);
  const [option, setOption] = useState(getOptionSet(optionSets, id) || currentOptionSet);

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = optionSetsTypesStatus === 'loading';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (optionSetsTypesStatus === 'idle') {
          await dispatch(fetchOption({ id, token })).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, id, optionSetsTypesStatus]);

  useEffect(() => {
    if (isObjectEmpty(option) && !isObjectEmpty(currentOptionSet)) {
      setOption(currentOptionSet);
    }
  }, [currentOptionSet, option]);

  const handleInputChange = useCallback(
    (e, type) => {
      const { name, value } = e.target;
      setOption((prev) => {
        if (isArrayEmpty(value)) {
          const { [name]: _, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setOption],
  );

  const memoizedOnChange = useCallback(
    (value) => handleInputChange({ target: { value, name: 'options' } }),
    [handleInputChange],
  );

  const handleCancel = () => {
    navigate('/menu/options');
  };

  const handleSaveChanges = async () => {
    if (validate(option)) {
      try {
        await dispatch(updateExistingOption({ id, updatedDetails: option, token })).unwrap();
        navigate('/menu/options');
      } catch {}
    }
  };

  if (!option) {
    return null;
  }

  return (
    <section className={styles.option_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Option Set'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Option Set Name'}
                  value={option.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                  errors={errors.name?._errors}
                />
              </div>
              <div className={clsx(styles.field, styles.block)}>
                <DynamicFormFields
                  value={option.options}
                  fieldItem={OptionForm}
                  label={'Options'}
                  fieldProps={{ style: { margin: '0 auto' } }}
                  addButtonTitle={'Add option'}
                  onChange={memoizedOnChange}
                  bottomLine={true}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Save Option Set'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'secondary'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditOptionSet;
