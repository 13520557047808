import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchInventoriesCount } from '../../../../redux/inventoriesSlice';
import { useAppContext } from '../../../../context/context';
import { navConfig } from '../../../../assets/scripts/pages';

export const useNavigationLogic = ({ activeParent, setActiveParent, setIsSidebarOpen, isMobile }) => {
  const dispatch = useDispatch();
  const { token } = useAppContext();
  const location = useLocation();

  const inventoriesCountStatus = useSelector((state) => state.inventories.status);

  const pathname = location.pathname;
  const lastUrlSegment = pathname.substring(pathname.lastIndexOf('/'));

  const activeParentConfig = navConfig.find((item) => item.link === `/${activeParent}`);

  useEffect(() => {
    const pathSegments = pathname.split('/').filter(Boolean);
    const newActiveParent = pathSegments[0];
    const isParent = navConfig.some((item) => item.isParent && item.link === `/${newActiveParent}`);

    if (activeParent === null && pathSegments.length > 1 && isParent) {
      setActiveParent(newActiveParent);
    }

    if (inventoriesCountStatus === 'idle') {
      dispatch(fetchInventoriesCount(token));
    }
  }, [pathname, activeParent, setActiveParent, dispatch, token, inventoriesCountStatus]);

  const handleParentClick = (parent, firstSubitem) => {
    const newActiveParent = parent.slice(1);
    setActiveParent(newActiveParent);
    setIsSidebarOpen(true);
  };

  const handleLinkClick = (elementLink, clearActiveParent = false) => {
    if (clearActiveParent) {
      setActiveParent(null);
    }

    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  return {
    pathname,
    lastUrlSegment,
    activeParentConfig,
    handleParentClick,
    handleLinkClick,
  };
};
